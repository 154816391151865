/* ----------------------------------------
// Table of Contents:
// - Common
//   - animations
//   - base
//   - .document
//   - grid
//   - header
//   - .site
// - Components
//   - .alert
//   - .arrow
//   - .breadcrumb
//   - .btn
//   - .button-toggle
//   - .card
//   - .card-table
//   - .container
//   - .decor
//   - .filters-button
//   - .form
//   - .form-control
//   - .image
//   - .input-check
//   - .input-check-color
//   - .input-number
//   - .input-radio
//   - .input-radio-color
//   - .input-radio-label
//   - .layout-switcher
//   - .modal
//   - .nouislider
//   - .pagination
//   - .rating
//   - .section-header
//   - .select2
//   - .share-links
//   - .shop-features
//   - .sidebar
//   - .social-links
//   - .status-badge
//   - .tag-badge
//   - .tags
//   - .teammate
//   - .timer
//   - .tooltip
//   - .typography
//   - .vehicle-form
//   - .vehicles-list
//   - .view-options
//   - .widget
// - Filters
//   - .filter
//   - .filter-categories
//   - .filter-color
//   - .filter-list
//   - .filter-price
//   - .filter-rating
//   - .filter-vehicle
// - Footer
//   - .footer-contacts
//   - .footer-links
//   - .footer-newsletter
//   - .site-footer
// - Pages
//   - .about
//   - .contacts
//   - .faq
//   - .not-found
// - Blocks
//   - .block-banners
//   - .block-brands
//   - .block-categories
//   - .block-features
//   - .block-finder
//   - .block-header
//   - .block-map
//   - .block-posts-carousel
//   - .block-products-carousel
//   - .block-products-columns
//   - .block-reviews
//   - .block-sale
//   - .block-slideshow
//   - .block-space
//   - .block-split
//   - .block-teammates
//   - .block-zone
// - Widgets
//   - .widget-about-us
//   - .widget-categories
//   - .widget-categories-list
//   - .widget-comments
//   - .widget-filters
//   - .widget-newsletter
//   - .widget-posts
//   - .widget-products
//   - .widget-search
//   - .widget-tags
// - Shop
//   - .analogs-table
//   - .applied-filters
//   - .cart
//   - .cart-table
//   - .categories-list
//   - .category-card
//   - .checkout
//   - .compare
//   - .compare-table
//   - .order-header
//   - .order-list
//   - .order-success
//   - .payment-methods
//   - .product
//   - .product-card
//   - .product-form
//   - .product-gallery
//   - .product-tabs
//   - .products-list
//   - .products-view
//   - .quickview
//   - .review
//   - .reviews-list
//   - .reviews-view
//   - .spec
//   - .vehicle-picker-modal
//   - .wishlist
// - Blog
//   - .blog-view
//   - .comment
//   - .comments-list
//   - .comments-view
//   - .post
//   - .post-card
//   - .post-header
//   - .post-navigation
//   - .post-view
//   - .posts-list
//   - .posts-view
// - Account
//   - .account-nav
//   - .address-card
//   - .addresses-list
//   - .dashboard
//   - .profile-card
// ---------------------------------------- */
/* ----------------------------------------
// Common
// ---------------------------------------- */
/*
// animations
*/
@-webkit-keyframes loader-animation {
  from {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes loader-animation {
  from {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

/*
  // base
  */
html {
  height: 100%;
}

body {
  height: 100%;
  background: #fafafa;
  color: #262626;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  overflow-y: scroll;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

[dir=ltr] body {
  text-align: left;
  direction: ltr;
}

[dir=rtl] body {
  text-align: right;
  direction: rtl;
}

a {
  color: #cb1818;
}

a:hover {
  color: #cb1818;
  text-decoration: none;
}

label {
  margin-bottom: .25rem;
  font-size: 15px;
}

h1 {
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-size: 32px;
  font-weight: 500;
}

h3 {
  font-size: 28px;
  font-weight: 500;
}

h4 {
  font-size: 24px;
  font-weight: 500;
}

h5 {
  font-size: 20px;
  font-weight: 500;
}

h6 {
  font-size: 16px;
  font-weight: 500;
}

/*
  // .document
  */
.document {
  max-width: 760px;
  margin: 0 auto;
}

.document__header {
  text-align: center;
  padding: 44px 0 52px;
}

.document__title {
  font-weight: 700;
  letter-spacing: .01em;
  margin: 0;
}

.document__subtitle {
  font-size: 15px;
  color: #999;
  margin-top: 8px;
  margin-bottom: -4px;
}

.document__content {
  padding: 50px;
}

.document__signature {
  margin-top: 2.5rem;
}

@media (max-width: 1199.98px) {
  .document__header {
    padding: 44px 0 48px;
  }

  .document__subtitle {
    margin-top: 4px;
  }
}

@media (max-width: 991.98px) {
  .document__content {
    padding: 40px;
  }
}

@media (max-width: 767.98px) {
  .document__header {
    padding: 36px 0 40px;
  }

  .document__title {
    font-size: 36px;
  }

  .document__content {
    padding: 28px;
  }
}

@media (max-width: 575.98px) {
  .document__title {
    font-size: 32px;
  }

  .document__subtitle {
    font-size: 14px;
  }

  .document__content {
    padding: 24px;
  }

  .document__content .typography {
    font-size: 15px;
  }

  .document__signature {
    margin-top: 24px;
  }
}

/*
  // grid
  */
.col-1of5 {
  padding: 0 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
}

.col-2of5 {
  padding: 0 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  width: 100%;
}

.col-3of5 {
  padding: 0 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
  position: relative;
  width: 100%;
}

.col-4of5 {
  padding: 0 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%;
  position: relative;
  width: 100%;
}

.col-5of5 {
  padding: 0 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
}

/*
  // header
  */
@media (min-width: 1200px) {

  .mobile-header,
  .mobile-menu {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .header {
    display: none;
  }
}

/*
  // .site
  */
.site {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;

}

.site__header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.site__body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;

}

.site__footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.container {
  max-width: 1450px;
  margin: auto;
}

@media only screen and (max-width: 1280px) {
  .container {
    max-width: 1140px;
    margin: auto;
  }
}

/* ----------------------------------------
  // Components
  // ---------------------------------------- */
/*
  // .alert
  */
.alert {
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 20px;
  padding: .75rem 1rem;
}

.alert a {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  color: currentColor;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.5px;
  padding: 0 3px;
}

.alert a:hover {
  background: rgba(255, 255, 255, 0.85);
}

.alert a:active {
  background: rgba(255, 255, 255, 0.5);
}

.alert hr {
  border-color: currentColor;
  opacity: .12;
}

.alert-primary {
  background: #b2d9ff;
  color: #36414d;
}

.alert-secondary {
  background: #d9d9d9;
  color: #4d4d4d;
}

.alert-info {
  background: #b8e2e5;
  color: #2d3e40;
}

.alert-success {
  background: #bde5a1;
  color: #34402d;
}

.alert-danger {
  background: #ffb8b8;
  color: #473333;
}

.alert-warning {
  background: #f2e291;
  color: #4d472e;
}

.alert-lg {
  font-size: 16px;
  padding: 1.125rem 1.375rem;
}

[dir=ltr] .alert-dismissible {
  padding-right: 3rem;
}

[dir=rtl] .alert-dismissible {
  padding-left: 3rem;
}

.alert-dismissible .close {
  opacity: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
  -webkit-transition: all .2s;
  transition: all .2s;
  fill: rgba(0, 0, 0, 0.3);
  background: transparent;
}

[dir=ltr] .alert-dismissible .close {
  right: 0;
  border-top-right-radius: 1.5px;
  border-bottom-right-radius: 1.5px;
}

[dir=rtl] .alert-dismissible .close {
  left: 0;
  border-top-left-radius: 1.5px;
  border-bottom-left-radius: 1.5px;
}

.alert-dismissible .close:focus {
  outline: none;
}

.alert-dismissible .close:not(:disabled):not(.disabled):focus,
.alert-dismissible .close:not(:disabled):not(.disabled):hover {
  background: rgba(0, 0, 0, 0.08);
  fill: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

/*
  // .arrow
  */
.arrow__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  background: transparent;
  pointer-events: none;
  -webkit-transition: color .15s;
  transition: color .15s;
  color: #fff;
}

.arrow__button:before {
  background: #e52727;
}

.arrow__button svg {
  fill: currentColor;
  display: block;
}

[dir=ltr] .arrow__button svg {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

[dir=rtl] .arrow__button svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.arrow__button:focus {
  outline: none;
}

.arrow__button:before {
  position: absolute;
  display: block;
  content: '';
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  z-index: -1;
  border-radius: 2px;
  pointer-events: auto;
  -webkit-transition: background .15s;
  transition: background .15s;
}

.arrow__button:hover {
  color: #fff;
}

.arrow__button:hover:before {
  background: #333;
}

.arrow__button:active {
  color: #fff;
}

.arrow__button:active:before {
  background: #474747;
}

.arrow__button:active,
.arrow__button:active:before {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.arrow__button:after {
  position: absolute;
  display: block;
  content: '';
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-transition: background .2s;
  transition: background .2s;
  opacity: .01;
}

[dir=ltr] .arrow__button:after {
  left: -2px;
}

[dir=rtl] .arrow__button:after {
  right: -2px;
}

.arrow__button:hover:after {
  background: rgba(0, 0, 0, 0.01);
}

.arrow__button:active:after {
  background: rgba(255, 255, 255, 0.01);
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

[dir=ltr] .arrow--next svg {
  margin-right: -1px;
}

[dir=rtl] .arrow--next svg {
  margin-left: -1px;
}

[dir=ltr] .arrow--prev svg {
  margin-left: -1px;
}

[dir=rtl] .arrow--prev svg {
  margin-right: -1px;
}

.arrow__button {
  height: 23px;
  padding: 0 14.37131px;
}

.arrow__button:before {
  width: calc(100% - 8.37131px);
}

[dir=ltr] .arrow__button:before {
  left: 4.18566px;
}

[dir=rtl] .arrow__button:before {
  right: 4.18566px;
}

[dir=ltr] .arrow__button:before {
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

[dir=rtl] .arrow__button:before {
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

/*
  // .breadcrumb
  */
.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  color: #6c757d;
}

.breadcrumb:after {
  content: '';
  clear: both;
}

.breadcrumb__list {
  display: block;
  list-style: none;
  padding: 0;
  margin: -4px 0 0;
  width: 100%;
}

.breadcrumb__item {
  margin-top: 4px;
}

[dir=ltr] .breadcrumb__item {
  margin-right: -7.12435px;
  float: left;
}

[dir=rtl] .breadcrumb__item {
  margin-left: -7.12435px;
  float: right;
}

.breadcrumb__item-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 0;
  height: 21px;
  padding: 0 15.12435px;
  pointer-events: none;
}

.breadcrumb__item-link,
.breadcrumb__item-link:hover {
  color: inherit;
}

.breadcrumb__item-link:before,
.breadcrumb__item-link:after {
  pointer-events: auto;
  position: absolute;
  background-color: #ebebeb;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 3px;
  -webkit-transition: background .1s;
  transition: background .1s;
}

.breadcrumb__item-link:before {
  content: '';
}

[dir=ltr] .breadcrumb__item-link:before {
  left: 6.06217px;
  right: 6.06217px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

[dir=rtl] .breadcrumb__item-link:before {
  right: 6.06217px;
  left: 6.06217px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

[dir=ltr] .breadcrumb__item--first .breadcrumb__item-link {
  padding-left: 7px;
}

[dir=rtl] .breadcrumb__item--first .breadcrumb__item-link {
  padding-right: 7px;
}

.breadcrumb__item--first .breadcrumb__item-link:after {
  content: '';
}

[dir=ltr] .breadcrumb__item--first .breadcrumb__item-link:after {
  left: 0;
  right: 12.12435px;
}

[dir=rtl] .breadcrumb__item--first .breadcrumb__item-link:after {
  right: 0;
  left: 12.12435px;
}

.breadcrumb__item--parent .breadcrumb__item-link:hover:before,
.breadcrumb__item--parent .breadcrumb__item-link:hover:after {
  background-color: #e5e5e5;
}

.breadcrumb__item--parent .breadcrumb__item-link:active:before,
.breadcrumb__item--parent .breadcrumb__item-link:active:after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  background-color: #e0e0e0;
}

[dir=ltr] .breadcrumb__item--last {
  margin-right: 0;
}

[dir=rtl] .breadcrumb__item--last {
  margin-left: 0;
}

.breadcrumb__title-safe-area {
  display: block;
  height: 21px;
  width: calc(50% + var(--block-header-title-width, 0px) / 2 + 64px);
  margin-top: 4px;
}

[dir=ltr] .breadcrumb__title-safe-area {
  float: left;
}

[dir=rtl] .breadcrumb__title-safe-area {
  float: right;
}

@media (max-width: 1199.98px) {
  .breadcrumb__title-safe-area {
    display: none;
  }
}

.breadcrumb__spaceship-safe-area {
  display: block;
  height: 21px;
  width: calc(50% + 480px / 2 + 20px);
  margin-top: 4px;
}

[dir=ltr] .breadcrumb__spaceship-safe-area {
  float: right;
}

[dir=rtl] .breadcrumb__spaceship-safe-area {
  float: left;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .breadcrumb__spaceship-safe-area {
    width: calc(50% + 440px / 2 + 20px);
  }
}

@media (max-width: 1199.98px) {
  .breadcrumb__spaceship-safe-area {
    display: none;
  }
}

/*
  // .btn
  */
.btn {
  border: none;
  border-radius: 2px;
  fill: currentColor;
  font-weight: 500;
}

.btn.disabled,
.btn:disabled {
  cursor: default;
}

.btn-icon {
  padding: 0;
}

.btn-icon svg {
  display: block;
  margin: auto;
}

.btn-loading {
  position: relative;
}

.btn-loading:after {
  /* nl size (default) */
  border-style: solid;
  -webkit-animation-name: loader-animation;
  animation-name: loader-animation;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: '';
  border-width: 2px;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.btn-loading.btn-xl:after {
  /* xl size */
  left: calc(50% - 21px);
  top: calc(50% - 21px);
  width: 42px;
  height: 42px;
  border-radius: 21px;
}

.btn-loading.btn-lg:after {
  /* lg size */
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.btn-loading.btn-sm:after {
  /* sm size */
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.btn-loading.btn-xs:after {
  /* xs size */
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.btn:not(.btn-icon) {
  font-size: 16px;
  line-height: 19px;
  padding: calc(17px / 2) 16px;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
}

.btn-sm:not(.btn-icon) {
  font-size: 14px;
  line-height: 17px;
  padding: calc(13px / 2) 12px;
}

.btn-sm.btn-icon {
  height: 30px;
  width: 30px;
}

.btn-xs:not(.btn-icon) {
  font-size: 13px;
  line-height: 15px;
  padding: calc(9px / 2) 9px;
}

.btn-xs.btn-icon {
  height: 24px;
  width: 24px;
}

.btn-lg:not(.btn-icon) {
  font-size: 18px;
  line-height: 22px;
  padding: calc(20px / 2) 24px;
}

.btn-lg.btn-icon {
  height: 42px;
  width: 42px;
}

.btn-xl:not(.btn-icon) {
  font-size: 20px;
  line-height: 26px;
  padding: calc(34px / 2) 40px;
}

.btn-xl.btn-icon {
  height: 60px;
  width: 60px;
}

.btn-primary:hover {
  border-color: #333;
  background: #333;
  color: #fff;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #e52727;
  background: #e52727;
  color: #fff;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #333;
  background: #333;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #4d4d4d;
  background: #4d4d4d;
  color: #fff;
}

.btn-primary.btn-loading:not(:disabled):not(.disabled),
.btn-primary.btn-loading:not(:disabled):not(.disabled):active,
.btn-primary.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #e52727;
  background: #e52727;
  cursor: default;
}

.btn-primary.btn-loading {
  /* hide button content */
}

.btn-primary.btn-loading,
.btn-primary.btn-loading:hover,
.btn-primary.btn-loading.focus,
.btn-primary.btn-loading:focus,
.btn-primary.btn-loading:not(:disabled):not(.disabled).active,
.btn-primary.btn-loading:not(:disabled):not(.disabled):active,
.btn-primary.btn-loading.disabled,
.btn-primary.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}

.btn-primary.btn-loading:after {
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
}

.btn-secondary:hover {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  border-color: #d6d6d6;
  background: #d6d6d6;
  color: #333;
}

.btn-secondary.btn-loading:not(:disabled):not(.disabled),
.btn-secondary.btn-loading:not(:disabled):not(.disabled):active,
.btn-secondary.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #f0f0f0;
  background: #f0f0f0;
  cursor: default;
}

.btn-secondary.btn-loading {
  /* hide button content */
}

.btn-secondary.btn-loading,
.btn-secondary.btn-loading:hover,
.btn-secondary.btn-loading.focus,
.btn-secondary.btn-loading:focus,
.btn-secondary.btn-loading:not(:disabled):not(.disabled).active,
.btn-secondary.btn-loading:not(:disabled):not(.disabled):active,
.btn-secondary.btn-loading.disabled,
.btn-secondary.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}

.btn-secondary.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-light:hover {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
}

.btn-light,
.btn-light.disabled,
.btn-light:disabled {
  border-color: transparent;
  background: transparent;
  color: #333;
}

.btn-light.focus,
.btn-light:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus {
  border-color: #e1e1e1;
  background: #e1e1e1;
  color: #333;
}

.btn-light.btn-loading:not(:disabled):not(.disabled),
.btn-light.btn-loading:not(:disabled):not(.disabled):active,
.btn-light.btn-loading:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background: transparent;
  cursor: default;
}

.btn-light.btn-loading {
  /* hide button content */
}

.btn-light.btn-loading,
.btn-light.btn-loading:hover,
.btn-light.btn-loading.focus,
.btn-light.btn-loading:focus,
.btn-light.btn-loading:not(:disabled):not(.disabled).active,
.btn-light.btn-loading:not(:disabled):not(.disabled):active,
.btn-light.btn-loading.disabled,
.btn-light.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}

.btn-light.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-muted:hover {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
}

.btn-muted,
.btn-muted.disabled,
.btn-muted:disabled {
  border-color: #fff;
  background: #fff;
  color: #ccc;
}

.btn-muted.focus,
.btn-muted:focus,
.btn-muted:not(:disabled):not(.disabled).active:focus,
.btn-muted:not(:disabled):not(.disabled):active:focus {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-muted:not(:disabled):not(.disabled):active,
.btn-muted:not(:disabled):not(.disabled).active,
.btn-muted:not(:disabled):not(.disabled).active:focus,
.btn-muted:not(:disabled):not(.disabled):active:focus {
  border-color: #ebebeb;
  background: #ebebeb;
  color: #4d4d4d;
}

.btn-muted.btn-loading:not(:disabled):not(.disabled),
.btn-muted.btn-loading:not(:disabled):not(.disabled):active,
.btn-muted.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #fff;
  background: #fff;
  cursor: default;
}

.btn-muted.btn-loading {
  /* hide button content */
}

.btn-muted.btn-loading,
.btn-muted.btn-loading:hover,
.btn-muted.btn-loading.focus,
.btn-muted.btn-loading:focus,
.btn-muted.btn-loading:not(:disabled):not(.disabled).active,
.btn-muted.btn-loading:not(:disabled):not(.disabled):active,
.btn-muted.btn-loading.disabled,
.btn-muted.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}

.btn-muted.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

/*
  // .button-toggle
  */
.button-toggle__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.button-toggle__item {
  margin: 0;
}

.button-toggle__button {
  margin: 0;
  background: #f0f0f0;
  color: #333;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  padding: 5px 9px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: background .12s,
    color .12s;
  transition: background .12s,
    color .12s;
}

.button-toggle__button:hover {
  background: #e5e5e5;
  color: #333;
}

.button-toggle__button:active {
  background: #d6d6d6;
  color: #333;
}

[dir=ltr] .button-toggle__item:first-child .button-toggle__button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

[dir=rtl] .button-toggle__item:first-child .button-toggle__button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=ltr] .button-toggle__item:last-child .button-toggle__button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=rtl] .button-toggle__item:last-child .button-toggle__button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.button-toggle__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.button-toggle__input:checked~.button-toggle__button {
  background: #e52727;
  color: #fff;
}

/*
  // .card
  */
.card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  border: none;
  border-radius: 0;
}

.card-body--padding--2 {
  padding: 2rem;
}

@media (max-width: 575.98px) {
  .card-body--padding--2 {
    padding: 1.5rem;
  }
}

.card-divider {
  height: 1px;
  background: #ebebeb;
}

.card-header {
  border-radius: 0;
  background: transparent;
  padding: 1.25rem 2rem;
  border: none;
}

.card-header>h5 {
  margin-bottom: 0;
}

.card-footer {
  border-radius: 0;
  background: transparent;
  padding: 1.25rem 2rem;
  border: none;
}

.card-title {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 2rem;
  margin-top: -4px;
}

.card-title--lg {
  font-size: 28px;
}

/*
  // .card-table
  */
.card-table {
  font-size: 15px;
}

.card-table a:hover {
  text-decoration: underline;
}

.card-table table {
  width: 100%;
  min-width: 500px;
}

[dir=ltr] .card-table table {
  text-align: left;
}

[dir=rtl] .card-table table {
  text-align: right;
}

.card-table thead th {
  border-bottom: 1px solid #ebebeb;
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px 12px;
}

.card-table tbody th,
.card-table tbody td,
.card-table tfoot th,
.card-table tfoot td {
  padding: 12px 12px;
}

.card-table tfoot th,
.card-table tfoot td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.card-table tbody+tbody tr th,
.card-table tbody+tbody tr td,
.card-table tbody+tfoot tr th,
.card-table tbody+tfoot tr td,
.card-table tbody tr+tr th,
.card-table tbody tr+tr td {
  border-top: 1px solid #ebebeb;
}

.card-table tbody tr:hover>*,
.card-table tfoot tr:hover>* {
  background-color: #f7f7f7;
}

[dir=ltr] .card-table th:first-child,
[dir=ltr] .card-table td:first-child {
  padding-left: 2rem;
}

[dir=ltr] .card-table th:last-child,
[dir=ltr] .card-table td:last-child {
  padding-right: 2rem;
}

[dir=rtl] .card-table th:first-child,
[dir=rtl] .card-table td:first-child {
  padding-right: 2rem;
}

[dir=rtl] .card-table th:last-child,
[dir=rtl] .card-table td:last-child {
  padding-left: 2rem;
}

.card-table .card-table__body--merge-rows th,
.card-table .card-table__body--merge-rows td {
  padding-top: 3px;
  padding-bottom: 3px;
}

.card-table .card-table__body--merge-rows tr:first-child th,
.card-table .card-table__body--merge-rows tr:first-child td {
  padding-top: 12px;
}

.card-table .card-table__body--merge-rows tr:last-child th,
.card-table .card-table__body--merge-rows tr:last-child td {
  padding-bottom: 12px;
}

.card-table .card-table__body--merge-rows tr+tr th,
.card-table .card-table__body--merge-rows tr+tr td {
  border-top: none;
}

@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
  .card-table {
    font-size: 14px;
  }

  [dir=ltr] .card-table th:first-child,
  [dir=ltr] .card-table td:first-child {
    padding-left: 1.5rem;
  }

  [dir=ltr] .card-table th:last-child,
  [dir=ltr] .card-table td:last-child {
    padding-right: 1.5rem;
  }

  [dir=rtl] .card-table th:first-child,
  [dir=rtl] .card-table td:first-child {
    padding-right: 1.5rem;
  }

  [dir=rtl] .card-table th:last-child,
  [dir=rtl] .card-table td:last-child {
    padding-left: 1.5rem;
  }
}

@media (max-width: breakpoint(sm-end)) {
  .card-table {
    font-size: 14px;
  }

  [dir=ltr] .card-table th:first-child,
  [dir=ltr] .card-table td:first-child {
    padding-left: 1.375rem;
  }

  [dir=ltr] .card-table th:last-child,
  [dir=ltr] .card-table td:last-child {
    padding-right: 1.375rem;
  }

  [dir=rtl] .card-table th:first-child,
  [dir=rtl] .card-table td:first-child {
    padding-right: 1.375rem;
  }

  [dir=rtl] .card-table th:last-child,
  [dir=rtl] .card-table td:last-child {
    padding-left: 1.375rem;
  }
}

/*
  // .container
  */
@media (min-width: 576px) {
  .container--max--sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container--max--md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container--max--lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container--max--xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container--max--xxl {
    max-width: 1380px;
  }
}

.container .container {
  padding: 0;
}

/*
  // .decor
  */
.decor {
  display: block;
}

.decor--type--center .decor__body {
  overflow: hidden;
  position: relative;
}

.decor--type--center .decor__start,
.decor--type--center .decor__end {
  position: absolute;
}

.decor--type--center .decor__start {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.decor--type--center .decor__end {
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.decor--type--center .decor__center {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.decor--type--center .decor__body {
  height: 31px;
  margin: 0 -10px -10px;
}

.decor--type--center .decor__start,
.decor--type--center .decor__end {
  height: 21px;
  width: calc(50% - 22.12435px);
}

.decor--type--center .decor__start {
  left: 10px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

.decor--type--center .decor__end {
  right: 10px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

.decor--type--center .decor__center {
  height: 21px;
  width: 26.2487px;
}

.decor--type--bottom {
  width: 100%;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 31px;
}

.decor--type--bottom .decor__start,
.decor--type--bottom .decor__end {
  position: absolute;
  width: calc((100% - 1350px - 160px) / 2);
  bottom: 0;
}

.decor--type--bottom .decor__start {
  left: 0;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
}

.decor--type--bottom .decor__end {
  right: 0;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.decor--type--bottom .decor__start,
.decor--type--bottom .decor__end {
  height: 21px;
}

.decor--type--bottom .decor__start {
  border-top-right-radius: 2px;
}

.decor--type--bottom .decor__end {
  border-top-left-radius: 2px;
}

.decor--type--bottom .decor__start {
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

.decor--type--bottom .decor__end {
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

/*
  // .filters-button
  */
.filters-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f0f0f0;
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  font-family: inherit;
  -webkit-transition: background-color .12s;
  transition: background-color .12s;
  padding-top: 7px;
  padding-bottom: 7px;
}

[dir=ltr] .filters-button {
  padding-left: 9px;
  padding-right: 7px;
}

[dir=rtl] .filters-button {
  padding-right: 9px;
  padding-left: 7px;
}

.filters-button:focus {
  outline: none;
}

.filters-button:hover {
  background: #ebebeb;
}

.filters-button:active {
  background: #e0e0e0;
}

.filters-button__icon {
  fill: #404040;
}

[dir=ltr] .filters-button__icon {
  margin-right: 7px;
}

[dir=rtl] .filters-button__icon {
  margin-left: 7px;
}

.filters-button__icon svg {
  display: block;
}

.filters-button__title {
  padding-top: 1px;
}

.filters-button__counter {
  background: #e52727;
  color: #fff;
  padding: 3px 4px 2px;
  font-size: 11px;
  border-radius: 1.5px;
}

[dir=ltr] .filters-button__counter {
  margin-left: 6px;
}

[dir=rtl] .filters-button__counter {
  margin-right: 6px;
}

/*
  // .form
  */
[dir=ltr] .form-check {
  padding-left: 1.5rem;
}

[dir=rtl] .form-check {
  padding-right: 1.5rem;
}

.form-check-input {
  position: absolute;
  margin-top: .1875rem;
}

[dir=ltr] .form-check-input {
  margin-left: -1.5rem;
}

[dir=rtl] .form-check-input {
  margin-right: -1.5rem;
}

/*
  // .form-control
  */
.form-control {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: border-color .15s ease-in-out,
    background .15s ease-in-out,
    -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,
    background .15s ease-in-out,
    -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out,
    background .15s ease-in-out;
  transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out,
    background .15s ease-in-out,
    -webkit-box-shadow .15s ease-in-out;
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
  height: 36px;
  padding: calc(15px / 2) 10px;
  font-size: 16px;
  line-height: 19px;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.form-control::-moz-placeholder {
  color: #6c757d;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
}

.form-control::placeholder {
  color: #6c757d;
}

.form-control[readonly] {
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.form-control[readonly]::-webkit-input-placeholder {
  color: #6c757d;
}

.form-control[readonly]::-moz-placeholder {
  color: #6c757d;
}

.form-control[readonly]:-ms-input-placeholder {
  color: #6c757d;
}

.form-control[readonly]::-ms-input-placeholder {
  color: #6c757d;
}

.form-control[readonly]::placeholder {
  color: #6c757d;
}

.form-control:hover {
  border-color: #d9d9d9;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  border-color: #d9d9d9;
}

.form-control:disabled {
  opacity: .7;
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.form-control:disabled::-webkit-input-placeholder {
  color: #6c757d;
}

.form-control:disabled::-moz-placeholder {
  color: #6c757d;
}

.form-control:disabled:-ms-input-placeholder {
  color: #6c757d;
}

.form-control:disabled::-ms-input-placeholder {
  color: #6c757d;
}

.form-control:disabled::placeholder {
  color: #6c757d;
}

.was-validated .form-control:invalid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-invalid:focus,
.form-control.is-valid:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.form-control.is-valid {
  background-image: none;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  color: #262626;
  background-color: #e2f2da;
  border-color: #e2f2da;
}

.was-validated .form-control:valid::-webkit-input-placeholder,
.form-control.is-valid::-webkit-input-placeholder {
  color: #6c757d;
}

.was-validated .form-control:valid::-moz-placeholder,
.form-control.is-valid::-moz-placeholder {
  color: #6c757d;
}

.was-validated .form-control:valid:-ms-input-placeholder,
.form-control.is-valid:-ms-input-placeholder {
  color: #6c757d;
}

.was-validated .form-control:valid::-ms-input-placeholder,
.form-control.is-valid::-ms-input-placeholder {
  color: #6c757d;
}

.was-validated .form-control:valid::placeholder,
.form-control.is-valid::placeholder {
  color: #6c757d;
}

.was-validated .form-control:valid:hover,
.form-control.is-valid:hover {
  border-color: #c3d9b8;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  background-color: #fff;
  border-color: #88cc66;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  color: #262626;
  background-color: #f2dada;
  border-color: #f2dada;
}

.was-validated .form-control:invalid::-webkit-input-placeholder,
.form-control.is-invalid::-webkit-input-placeholder {
  color: #6c757d;
}

.was-validated .form-control:invalid::-moz-placeholder,
.form-control.is-invalid::-moz-placeholder {
  color: #6c757d;
}

.was-validated .form-control:invalid:-ms-input-placeholder,
.form-control.is-invalid:-ms-input-placeholder {
  color: #6c757d;
}

.was-validated .form-control:invalid::-ms-input-placeholder,
.form-control.is-invalid::-ms-input-placeholder {
  color: #6c757d;
}

.was-validated .form-control:invalid::placeholder,
.form-control.is-invalid::placeholder {
  color: #6c757d;
}

.was-validated .form-control:invalid:hover,
.form-control.is-invalid:hover {
  border-color: #d9a3a3;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  background-color: #fff;
  border-color: #cc6666;
}

.form-control-lg {
  height: 42px;
  padding: calc(19px / 2) 12px;
  font-size: 18px;
  line-height: 21px;
}

.form-control-sm {
  height: 30px;
  padding: calc(11px / 2) 8px;
  font-size: 14px;
  line-height: 17px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:not(:disabled) {
  cursor: pointer;
}

select.form-control,
.was-validated select.form-control:invalid,
.was-validated select.form-control:valid,
select.form-control.is-invalid,
select.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.55)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
}

select.form-control::-ms-expand {
  display: none;
}

[dir=ltr] select.form-control {
  padding-right: 27px;
  background-position: right 10px center;
}

[dir=rtl] select.form-control {
  padding-left: 27px;
  background-position: left 10px center;
}

[dir=ltr] select.form-control-lg {
  padding-right: 31px;
  background-position: right 12px center;
}

[dir=rtl] select.form-control-lg {
  padding-left: 31px;
  background-position: left 12px center;
}

[dir=ltr] select.form-control-sm {
  padding-right: 23px;
  background-position: right 8px center;
}

[dir=rtl] select.form-control-sm {
  padding-left: 23px;
  background-position: left 8px center;
}

.valid-feedback {
  color: #388020;
}

.invalid-feedback {
  color: #b32d2d;
}

/*
  // .image
  */
.image--type--product .image__body,
.image--type--category .image__body {
  display: block;
  position: relative;
  width: 100%;
}

.image--type--product .image__tag,
.image--type--category .image__tag {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

/*
  // .input-check
  */
.image--type--product .image__body {
  padding-bottom: 100%;
}

.image--type--category .image__body {
  padding-bottom: 100%;
}


.input-check {
  display: inline-block;
}

.input-check__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-check__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-check__box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 1.2px;
  background: #fff;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  -webkit-transition: background 0.15s, -webkit-box-shadow 0.15s;
  transition: background 0.15s, -webkit-box-shadow 0.15s;
  transition: background 0.15s, box-shadow 0.15s;
  transition: background 0.15s, box-shadow 0.15s, -webkit-box-shadow 0.15s;
}

.input-check__icon {
  pointer-events: none;
  position: absolute;
  left: 4px;
  top: 5px;
  fill: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: fill 0.15s, visibility 0s 0.15s, -webkit-transform 0.15s;
  transition: fill 0.15s, visibility 0s 0.15s, -webkit-transform 0.15s;
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s, -webkit-transform 0.15s;
}

.input-check__icon svg {
  display: block;
}

.input-check__input:checked~.input-check__box {
  background: #e52727;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
}

.input-check__input:checked~.input-check__icon {
  fill: #fff;
  -webkit-transition-delay: 0s, 0s, 0s;
  transition-delay: 0s, 0s, 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.input-check__input:disabled~.input-check__box {
  background: #f2f2f2;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.input-check__input:disabled~.input-check__icon {
  fill: rgba(0, 0, 0, 0.2);
}

/*
  // .input-check-color
  */
.input-check-color {
  display: inline-block;
}

.input-check-color__body {
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  overflow: hidden;
  cursor: inherit;
  margin: 0;
}

.input-check-color__input {
  position: absolute;
  visibility: hidden;
}

.input-check-color__box {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  background: currentColor;
  cursor: inherit;
}

.input-check-color__icon {
  position: absolute;
  left: 5px;
  top: 6px;
  fill: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.15s;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

.input-check-color__icon svg {
  display: block;
}

.input-check-color__input:checked~.input-check-color__icon {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.input-check-color__input:disabled {
  cursor: default;
}

.input-check-color__input:disabled~.input-check-color__box {
  position: relative;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  opacity: .5;
  cursor: default;
}

.input-check-color__input:disabled~.input-check-color__stick {
  pointer-events: none;
  display: block;
  position: absolute;
  width: 2px;
  border-radius: 1px;
  height: 34px;
  left: calc(50% - 1px);
  top: -6px;
  background: rgba(102, 102, 102, 0.9);
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.input-check-color--light .input-check-color__icon {
  fill: #3d464d;
}

.input-check-color--white .input-check-color__box {
  -webkit-box-shadow: 0 0 0 2px #d9d9d9 inset;
  box-shadow: 0 0 0 2px #d9d9d9 inset;
}

.input-check-color--white .input-check-color__icon {
  fill: #bfbfbf;
}

/*
  // .input-number
  */
.input-number {
  display: block;
  width: 100%;
  position: relative;
}

.input-number__input {
  -moz-appearance: textfield;
  display: block;
  width: 100%;
  min-width: 88px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.input-number__input::-webkit-inner-spin-button,
.input-number__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number__sub,
.input-number__add {
  position: absolute;
  height: 100%;
  width: 24px;
  top: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: .3;
  -webkit-transition: opacity .18s;
  transition: opacity .18s;
}

.input-number__sub:before,
.input-number__sub:after,
.input-number__add:before,
.input-number__add:after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: currentColor;
}

.input-number__sub:before,
.input-number__add:before {
  width: 8px;
  height: 2px;
}

.input-number__sub:hover,
.input-number__add:hover {
  opacity: 1;
}

[dir=ltr] .input-number__sub {
  left: 1px;
}

[dir=rtl] .input-number__sub {
  right: 1px;
}

[dir=ltr] .input-number__add {
  right: 1px;
}

[dir=rtl] .input-number__add {
  left: 1px;
}

.input-number__add:after {
  width: 2px;
  height: 8px;
}

/*
  // .input-radio
  */
.input-radio {
  display: inline-block;
}

.input-radio__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-radio__circle {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17) inset;
  -webkit-transition: background 0.2s, -webkit-box-shadow 0.2s;
  transition: background 0.2s, -webkit-box-shadow 0.2s;
  transition: background 0.2s, box-shadow 0.2s;
  transition: background 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.input-radio__circle:after {
  display: block;
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: background 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s;
  transition: background 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s;
  transition: background 0.2s, transform 0.2s, visibility 0s 0.2s;
  transition: background 0.2s, transform 0.2s, visibility 0s 0.2s, -webkit-transform 0.2s;
  visibility: hidden;
}

.input-radio__input:checked~.input-radio__circle {
  background: #e52727;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
}

.input-radio__input:checked~.input-radio__circle:after {
  background: #fff;
  visibility: visible;
  -webkit-transition-delay: 0s, 0s, 0s;
  transition-delay: 0s, 0s, 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.input-radio__input:disabled~.input-radio__circle {
  background: #f2f2f2;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
}

.input-radio__input:disabled~.input-radio__circle:after {
  background: rgba(0, 0, 0, 0.2);
}

/*
  // .input-radio-color
  */
.input-radio-color {
  display: block;
}

.input-radio-color__list {
  padding: 1px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -2px;
}

.input-radio-color__item {
  width: 28px;
  height: 28px;
  display: block;
  margin: 2px;
  cursor: pointer;
}

.input-radio-color__item input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}

.input-radio-color__item span {
  position: relative;
  width: 28px;
  height: 28px;
  display: block;
  border-radius: 14px;
  background: currentColor;
  -webkit-transform: scale(0.78571);
  transform: scale(0.78571);
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.input-radio-color__item span:before,
.input-radio-color__item span:after {
  display: block;
  position: absolute;
  content: '';
  border-radius: 1000px;
}

.input-radio-color__item span:before {
  left: 3px;
  top: 3px;
  width: 22px;
  height: 22px;
  background: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.input-radio-color__item span:after {
  left: 7px;
  top: 7px;
  width: 14px;
  height: 14px;
  background: currentColor;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.input-radio-color__item:hover input:enabled~span {
  -webkit-transform: scale(0.85714);
  transform: scale(0.85714);
}

.input-radio-color__item input:enabled:checked~span,
.input-radio-color__item input:enabled:checked~span:before,
.input-radio-color__item input:enabled:checked~span:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.input-radio-color__item--disabled {
  cursor: default;
}

.input-radio-color__item--disabled span {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.input-radio-color__item--disabled span:before {
  -webkit-transform: scale(1.33) rotateZ(45deg);
  transform: scale(1.33) rotateZ(45deg);
  left: 13px;
  top: -1px;
  width: 2px;
  height: calc(100% + 2px);
  background: rgba(0, 0, 0, 0.3);
}

.input-radio-color__item--white span {
  background: #fff;
  -webkit-box-shadow: inset 0 0 0 2px #ccc;
  box-shadow: inset 0 0 0 2px #ccc;
}

.input-radio-color__item--white span:after {
  -webkit-box-shadow: inset 0 0 0 2px #ccc;
  box-shadow: inset 0 0 0 2px #ccc;
}

/*
  // .input-radio-label
  */
.input-radio-label__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -3px;
}

.input-radio-label__item {
  margin: 3px;
}

.input-radio-label__input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.input-radio-label__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  height: 28px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  font-size: 13px;
  font-weight: 500;
  padding: 0 12px 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: -webkit-box-shadow .15s;
  transition: -webkit-box-shadow .15s;
  transition: box-shadow .15s;
  transition: box-shadow .15s, -webkit-box-shadow .15s;
}

.input-radio-label__title:hover {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
}

.input-radio-label__title:active {
  -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
}

.input-radio-label__input:checked~.input-radio-label__title {
  -webkit-box-shadow: 0 0 0 2px #e52727 inset;
  box-shadow: 0 0 0 2px #e52727 inset;
}

.input-radio-label__input:disabled~.input-radio-label__title {
  color: #a6a6a6;
  font-weight: 400;
  cursor: default;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

/*
  // .layout-switcher
  */
.layout-switcher__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-switcher__button {
  padding: 7px;
  margin: 0;
  border: none;
  display: block;
  border-radius: 2px;
  background-color: transparent;
  color: #95a0a6;
}

.layout-switcher__button:focus {
  outline: none;
}

.layout-switcher__button svg {
  display: block;
  fill: currentColor;
}

.layout-switcher__button:hover {
  background-color: #f0f0f0;
}

.layout-switcher__button--active {
  color: #e52727;
}

.layout-switcher__button--active:hover {
  background-color: transparent;
}

/*
  // .modal
  */
.modal {
  -webkit-overflow-scrolling: touch;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.modal-backdrop {
  background: rgba(51, 51, 51, 0.8);
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-content {
  border: none;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}

/*
  // .nouislider
  */
/* Functional styling;
   * These styles are required for noUiSlider to function.
   * You don't need to change these rules to apply your design.
   */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
   */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  -webkit-transform-style: flat;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
   */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
   * connect elements.
   */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
   */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  height: 16px;
}

.noUi-base {
  padding-top: 5px;
}

.noUi-connects {
  background: #e0e0e0;
  border-radius: 3px;
  height: 6px;
}

.noUi-connect {
  border-radius: 2px;
  background: #e52727;
}

.noUi-horizontal .noUi-handle {
  cursor: ew-resize;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #e52727;
  -webkit-box-shadow: 0 0 0 0 rgba(229, 39, 39, 0);
  box-shadow: 0 0 0 0 rgba(229, 39, 39, 0);
  -webkit-transition: -webkit-box-shadow .2s;
  transition: -webkit-box-shadow .2s;
  transition: box-shadow .2s;
  transition: box-shadow .2s, -webkit-box-shadow .2s;
  left: auto;
  right: -8px;
}

.noUi-horizontal .noUi-handle:after {
  display: block;
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  margin: 5px;
  border-radius: 3px;
  background: #fff;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  top: 0;
}

.noUi-horizontal .noUi-handle:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(229, 39, 39, 0.3);
  box-shadow: 0 0 0 3px rgba(229, 39, 39, 0.3);
}

.noUi-horizontal .noUi-handle:focus:after {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.noUi-horizontal .noUi-handle {
  top: 0px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -8px;
  right: auto;
}

/*
  // .pagination
  */
.pagination {
  margin-bottom: 0;
  padding: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

[dir=ltr] .pagination .page-item {
  margin-right: 6px;
}

[dir=rtl] .pagination .page-item {
  margin-left: 6px;
}

[dir=ltr] .pagination .page-item:last-child {
  margin-right: 0;
}

[dir=rtl] .pagination .page-item:last-child {
  margin-left: 0;
}

.page-link {
  font-size: 15px;
  line-height: 18px;
  border-radius: 2px;
  padding: 6px 10px;
  -webkit-transition: background .15s;
  transition: background .15s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f0f0f0;
  color: #262626;
}

.page-link:hover {
  background-color: #e5e5e5;
  color: #262626;
}

.page-link:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  background-color: #e0e0e0;
  color: #262626;
}

.page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 2px;
}

.page-item.disabled .page-link {
  background-color: #f0f0f0;
  color: #b3b3b3;
}

.page-item.active .page-link {
  background-color: #e52727;
  color: #fff;
  cursor: default;
}

.pagination__dots {
  position: relative;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 4px;
}

.pagination__dots:before,
.pagination__dots:after {
  display: block;
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}

.pagination__dots:before {
  top: -5px;
}

.pagination__dots:after {
  bottom: -5px;
}

.page-item--dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-link--with-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-link__arrow {
  display: block;
  margin: 3px 0 4px;
  fill: currentColor;
  opacity: .8;
}

[dir=ltr] .page-link__arrow {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

[dir=rtl] .page-link__arrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.page-link__arrow svg {
  display: block;
}

[dir=ltr] .page-link__arrow--left {
  margin-left: -1px;
  margin-right: 1px;
}

[dir=rtl] .page-link__arrow--left {
  margin-right: -1px;
  margin-left: 1px;
}

[dir=ltr] .page-link__arrow--right {
  margin-left: 1px;
  margin-right: -1px;
}

[dir=rtl] .page-link__arrow--right {
  margin-right: 1px;
  margin-left: -1px;
}

@media (max-width: 575.98px) {
  .page-link {
    padding: 5px 9px;
  }
}

/*
  // .rating
  */
.rating__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rating__star {
  width: 13px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d0d5d9' width='13' height='12'%3E%3Cpath d='M6.5,1.5l1.6,3L8.4,5H9h2.4l-2,1.6L8.9,7L9,7.6l0.7,3L7,9.1L6.5,8.9L6,9.1l-2.8,1.5l0.7-3L4.1,7L3.6,6.6L1.6,5 H4h0.6l0.3-0.5L6.5,1.5 M6.5,0C6.3,0,6.1,0.1,6,0.3L4,4H0.5C0,4.1-0.2,4.8,0.2,5.1L3,7.4l-1,4C1.9,11.7,2.2,12,2.5,12 c0.1,0,0.2,0,0.3-0.1L6.5,10l3.7,1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.3,0.5-0.6l-1-4l2.8-2.3c0.3-0.3,0.1-1-0.3-1.1H9L7,0.3 C6.9,0.1,6.7,0,6.5,0L6.5,0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0, 0, 0, 0.08)' width='13' height='12'%3E%3Cpath d='M7,0.3L9,4h3.5c0.4,0.1,0.6,0.8,0.3,1.1L10,7.4l1,4c0.1,0.4-0.4,0.8-0.8,0.6L6.5,10l-3.7,1.9c-0.4,0.2-0.9-0.1-0.8-0.6l1-4 L0.2,5.1C-0.2,4.8,0,4.1,0.5,4H4l2-3.7C6.2-0.1,6.8-0.1,7,0.3z'/%3E%3C/svg%3E");
}

[dir=ltr] .rating__star+.rating__star {
  margin-left: 3px;
}

[dir=rtl] .rating__star+.rating__star {
  margin-right: 3px;
}

.rating__star--active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0, 0, 0, 0.08)' width='13' height='12'%3E%3Cpath d='M6.5,1.5l1.6,3L8.4,5H9h2.4l-2,1.6L8.9,7L9,7.6l0.7,3L7,9.1L6.5,8.9L6,9.1l-2.8,1.5l0.7-3L4.1,7L3.6,6.6L1.6,5 H4h0.6l0.3-0.5L6.5,1.5 M6.5,0C6.3,0,6.1,0.1,6,0.3L4,4H0.5C0,4.1-0.2,4.8,0.2,5.1L3,7.4l-1,4C1.9,11.7,2.2,12,2.5,12 c0.1,0,0.2,0,0.3-0.1L6.5,10l3.7,1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.3,0.5-0.6l-1-4l2.8-2.3c0.3-0.3,0.1-1-0.3-1.1H9L7,0.3 C6.9,0.1,6.7,0,6.5,0L6.5,0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffd333' width='13' height='12'%3E%3Cpath d='M7,0.3L9,4h3.5c0.4,0.1,0.6,0.8,0.3,1.1L10,7.4l1,4c0.1,0.4-0.4,0.8-0.8,0.6L6.5,10l-3.7,1.9c-0.4,0.2-0.9-0.1-0.8-0.6l1-4 L0.2,5.1C-0.2,4.8,0,4.1,0.5,4H4l2-3.7C6.2-0.1,6.8-0.1,7,0.3z'/%3E%3C/svg%3E");
}

/*
  // .section-header
  */
.section-header__body {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.section-header__title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  position: relative;
  top: 2px;
}

.section-header__spring {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.section-header__divider {
  width: 100%;
  height: 2px;
  margin-top: 8px;
  background: #ebebeb;
}

.section-header__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

[dir=ltr] .section-header__arrow--next {
  margin-left: -5.37131px;
}

[dir=rtl] .section-header__arrow--next {
  margin-right: -5.37131px;
}

.section-header__links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[dir=ltr] .section-header__links-item {
  padding-right: 18px;
}

[dir=rtl] .section-header__links-item {
  padding-left: 18px;
}

[dir=ltr] .section-header__links-item:last-child {
  padding-right: 0;
}

[dir=rtl] .section-header__links-item:last-child {
  padding-left: 0;
}

.section-header__links-link {
  color: #6c757d;
  position: relative;
  -webkit-transition: color .1s;
  transition: color .1s;
}

.section-header__links-link:hover {
  color: inherit;
}

[dir=ltr] .section-header__links+.section-header__arrows {
  margin-left: 18px;
}

[dir=rtl] .section-header__links+.section-header__arrows {
  margin-right: 18px;
}

.section-header__groups {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

[dir=ltr] .section-header__groups-item+.section-header__groups-item {
  margin-left: -5.37131px;
}

[dir=rtl] .section-header__groups-item+.section-header__groups-item {
  margin-right: -5.37131px;
}

.section-header__groups-button {
  font-size: 14px;
  height: 23px;
  border: none;
  padding: 0 18.37131px;
  line-height: 23px;
  display: block;
  position: relative;
  z-index: 0;
  pointer-events: none;
  -webkit-transition: color .1s;
  transition: color .1s;
  background: transparent;
  color: #6c757d;
}

.section-header__groups-button:focus {
  outline: none;
}

.section-header__groups-button:after {
  position: absolute;
  display: block;
  content: '';
  width: calc(100% - 8.37131px);
  height: 100%;
  top: 0;
  background: transparent;
  z-index: -1;
  pointer-events: auto;
  -webkit-transition: background .1s;
  transition: background .1s;
}

[dir=ltr] .section-header__groups-button:after {
  left: 0;
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  border-top-left-radius: 2px;
  border-top-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2px;
}

[dir=rtl] .section-header__groups-button:after {
  right: 0;
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  border-top-right-radius: 2px;
  border-top-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  border-bottom-left-radius: 2px;
}

.section-header__groups-button:hover {
  color: #262626;
}

.section-header__groups-button:hover:after {
  background: #ebebeb;
}

.section-header__groups-button:active {
  color: #262626;
}

.section-header__groups-button:active:after {
  background: #e0e0e0;
}

.section-header__groups-button--active,
.section-header__groups-button--active:hover,
.section-header__groups-button--active:active {
  color: #fff;
  cursor: default;
}

.section-header__groups-button--active:after,
.section-header__groups-button--active:hover:after,
.section-header__groups-button--active:active:after {
  background: #333;
}

[dir=ltr] .section-header__groups+.section-header__arrows {
  margin-left: -5.37131px;
}

[dir=rtl] .section-header__groups+.section-header__arrows {
  margin-right: -5.37131px;
}

@media (max-width: 767.98px) {
  .section-header__body {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .section-header__links {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    padding: 9px 0 10px;
    border-bottom: 2px solid #ebebeb;
  }

  .section-header__links-item {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .section-header__groups {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    border-bottom: 2px solid #ebebeb;
    padding: 10px 0 11px;
  }

  .section-header__divider {
    width: 100%;
    height: 2px;
    background: #ebebeb;
    margin-top: 8px;
  }
}

/*
  // .select2
  */
.form-control+.select2-container {
  width: 100%;
}

.form-control+.select2-container .select2-selection__arrow {
  display: none;
}

.form-control+.select2-container .select2-selection--single {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.55)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
  -webkit-transition: border-color .15s ease-in-out,
    background .15s ease-in-out;
  transition: border-color .15s ease-in-out,
    background .15s ease-in-out;
}

.form-control+.select2-container .select2-selection--single:focus {
  outline: none;
}

.form-control+.select2-container .select2-selection__rendered {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-control+.select2-container .select2-selection--single {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.form-control+.select2-container .select2-selection__rendered {
  color: #262626;
}

.form-control+.select2-container--disabled {
  opacity: .7;
}

.form-control+.select2-container:not(.select2-container--open):not(.select2-container--disabled):hover .select2-selection--single {
  border-color: #d9d9d9;
}

.form-control+.select2-container--open .select2-selection--single {
  background-color: #fff;
  border-color: #d9d9d9;
}

.form-control+.select2-container .select2-selection--single {
  height: 36px;
}

[dir=ltr] .form-control+.select2-container .select2-selection--single {
  background-position: right 10px center;
}

[dir=rtl] .form-control+.select2-container .select2-selection--single {
  background-position: left 10px center;
}

.form-control+.select2-container .select2-selection__rendered {
  line-height: 19px;
  padding: calc(15px / 2) 10px;
  font-size: 16px;
}

[dir=ltr] .form-control+.select2-container .select2-selection__rendered {
  padding-right: 27px;
}

[dir=rtl] .form-control+.select2-container .select2-selection__rendered {
  padding-left: 27px;
}

.form-control-lg+.select2-container .select2-selection--single {
  height: 42px;
}

[dir=ltr] .form-control-lg+.select2-container .select2-selection--single {
  background-position: right 12px center;
}

[dir=rtl] .form-control-lg+.select2-container .select2-selection--single {
  background-position: left 12px center;
}

.form-control-lg+.select2-container .select2-selection__rendered {
  line-height: 21px;
  padding: calc(19px / 2) 12px;
  font-size: 18px;
}

[dir=ltr] .form-control-lg+.select2-container .select2-selection__rendered {
  padding-right: 31px;
}

[dir=rtl] .form-control-lg+.select2-container .select2-selection__rendered {
  padding-left: 31px;
}

.form-control-sm+.select2-container .select2-selection--single {
  height: 30px;
}

[dir=ltr] .form-control-sm+.select2-container .select2-selection--single {
  background-position: right 8px center;
}

[dir=rtl] .form-control-sm+.select2-container .select2-selection--single {
  background-position: left 8px center;
}

.form-control-sm+.select2-container .select2-selection__rendered {
  line-height: 17px;
  padding: calc(11px / 2) 8px;
  font-size: 14px;
}

[dir=ltr] .form-control-sm+.select2-container .select2-selection__rendered {
  padding-right: 23px;
}

[dir=rtl] .form-control-sm+.select2-container .select2-selection__rendered {
  padding-left: 23px;
}

.select2-dropdown {
  background: #fff;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
}

.select2-search--dropdown {
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  padding: 6px 10px;
  font-size: 15px;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f2f2f2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #d9ecff;
  color: #36414d;
}

.select2-results__option {
  padding: 4px 10px;
  font-size: 15px;
}

.select2-results__options:before,
.select2-results__options:after {
  display: list-item;
  content: '';
  height: 6px;
}

/*
  // .share-links
  */
.share-links {
  display: block;
}

.share-links__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

[dir=ltr] .share-links__item+.share-links__item {
  margin-left: 4px;
}

[dir=rtl] .share-links__item+.share-links__item {
  margin-right: 4px;
}

.share-links__item a {
  border-radius: 1.5px;
  display: block;
  padding: 2px 7px 1px;
}

.share-links__item a,
.share-links__item a:hover {
  color: inherit;
  text-decoration: none;
}

.share-links__item--type--like a {
  background: #4267b2;
  color: #fff;
}

.share-links__item--type--like a:hover {
  color: #fff;
  background: #3b5c9f;
}

.share-links__item--type--tweet a {
  background: #1b95e0;
  color: #fff;
}

.share-links__item--type--tweet a:hover {
  color: #fff;
  background: #1886c9;
}

.share-links__item--type--pin a {
  background: #e63939;
  color: #fff;
}

.share-links__item--type--pin a:hover {
  color: #fff;
  background: #e32222;
}

.share-links__item--type--counter a {
  background: #fff;
  color: #737373;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}

.share-links__item--type--counter a:hover {
  color: #666666;
}

/*
  // .shop-features
  */
.shop-features__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  padding: 14px 0;
}

.shop-features__item {
  padding: 10px 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-features__item-icon {
  color: #e52727;
}

[dir=ltr] .shop-features__item-icon {
  margin-right: 18px;
}

[dir=rtl] .shop-features__item-icon {
  margin-left: 18px;
}

.shop-features__item-icon svg {
  width: 38px;
  height: 38px;
  fill: currentColor;
  vertical-align: middle;
}

.shop-features__item-title {
  font-size: 14px;
  font-weight: 500;
}

.shop-features__item-subtitle {
  font-size: 14px;
  color: #6c757d;
  margin-top: -2px;
}

.shop-features__item {
  width: calc((100% - 0px) / 1);
}

.shop-features__divider {
  width: 1px;
}

.shop-features__divider:nth-child(2n) {
  width: 100%;
  height: 1px;
}

.shop-features__divider:nth-child(2n):last-child {
  display: none;
}

/*
  // .sidebar
  */
.sidebar__header {
  display: none;
}

@media (max-width: 991.98px) {
  .sidebar--offcanvas--mobile {
    visibility: hidden;
    -webkit-transition: visibility 0s 0.25s;
    transition: visibility 0s 0.25s;
  }

  .sidebar--offcanvas--mobile .sidebar__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(51, 51, 51, 0.8);
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .sidebar--offcanvas--mobile .sidebar__body {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    width: 300px;
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: -webkit-transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    will-change: transform;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  [dir=ltr] .sidebar--offcanvas--mobile .sidebar__body {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  [dir=rtl] .sidebar--offcanvas--mobile .sidebar__body {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .sidebar--offcanvas--mobile .sidebar__content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .sidebar--offcanvas--mobile.sidebar--open {
    visibility: visible;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  .sidebar--offcanvas--mobile.sidebar--open .sidebar__backdrop {
    opacity: 1;
  }

  .sidebar--offcanvas--mobile.sidebar--open .sidebar__body {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .sidebar--offcanvas--mobile .sidebar__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .sidebar--offcanvas--mobile .sidebar__title {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px;
  }

  .sidebar--offcanvas--mobile .sidebar__close {
    width: 48px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    fill: currentColor;
    padding: 0 0 2px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: background-color .15s,
      color .15s;
    transition: background-color .15s,
      color .15s;
    background-color: #fff;
    color: #ccc;
  }

  .sidebar--offcanvas--mobile .sidebar__close:hover {
    background-color: #f2f2f2;
    color: #4d4d4d;
  }

  .sidebar--offcanvas--mobile .sidebar__close:active {
    background-color: #ebebeb;
    color: #4d4d4d;
  }

  .sidebar--offcanvas--mobile .sidebar__close:focus {
    outline: none;
  }
}

.sidebar--offcanvas--always {
  visibility: hidden;
  -webkit-transition: visibility 0s 0.25s;
  transition: visibility 0s 0.25s;
}

.sidebar--offcanvas--always .sidebar__backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(51, 51, 51, 0.8);
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.sidebar--offcanvas--always .sidebar__body {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  width: 300px;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: -webkit-transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

[dir=ltr] .sidebar--offcanvas--always .sidebar__body {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

[dir=rtl] .sidebar--offcanvas--always .sidebar__body {
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.sidebar--offcanvas--always .sidebar__content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar--offcanvas--always.sidebar--open {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.sidebar--offcanvas--always.sidebar--open .sidebar__backdrop {
  opacity: 1;
}

.sidebar--offcanvas--always.sidebar--open .sidebar__body {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.sidebar--offcanvas--always .sidebar__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.sidebar--offcanvas--always .sidebar__title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 15px;
  font-weight: 500;
  padding: 0 20px;
}

.sidebar--offcanvas--always .sidebar__close {
  width: 48px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  fill: currentColor;
  padding: 0 0 2px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: background-color .15s,
    color .15s;
  transition: background-color .15s,
    color .15s;
  background-color: #fff;
  color: #ccc;
}

.sidebar--offcanvas--always .sidebar__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}

.sidebar--offcanvas--always .sidebar__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}

.sidebar--offcanvas--always .sidebar__close:focus {
  outline: none;
}

/*
  // .social-links
  */
.social-links__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: -4px;
}

.social-links__item {
  margin: 4px;
}

.social-links__item a {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: inherit;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.social-links__item a:hover {
  opacity: .8;
}

.social-links__item--facebook a {
  background: #3c5a99;
  color: #fff;
}

.social-links__item--twitter a {
  background: #00a2e8;
  color: #fff;
}

.social-links__item--youtube a {
  background: #e52e2e;
  color: #fff;
}

.social-links__item--instagram a {
  background: #815dc7;
  color: #fff;
}

.social-links__item--rss a {
  background: #ffc338;
  color: #664729;
}

/*
  // .status-badge
  */
.status-badge {
  display: inline-block;
  vertical-align: middle;
  cursor: default;
}

.status-badge__body {
  position: relative;
  min-height: 23px;
  border-radius: 11.5px;
  min-width: 31px;
}

.status-badge__icon {
  fill: currentColor;
  position: absolute;
  top: calc(50% - 6.5px);
  opacity: .85;
}

[dir=ltr] .status-badge__icon {
  left: 9px;
}

[dir=rtl] .status-badge__icon {
  right: 9px;
}

.status-badge__icon svg {
  display: block;
}

.status-badge__text {
  font-size: 13px;
  line-height: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}

[dir=ltr] .status-badge__text {
  padding-left: 12px;
  padding-right: 12px;
}

[dir=rtl] .status-badge__text {
  padding-right: 12px;
  padding-left: 12px;
}

.status-badge__tooltip {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.status-badge__tooltip:focus {
  outline: none;
}

.status-badge--has-text .status-badge__tooltip {
  display: none;
}

[dir=ltr] .status-badge--has-icon .status-badge__text {
  padding-left: 28px;
}

[dir=rtl] .status-badge--has-icon .status-badge__text {
  padding-right: 28px;
}

.status-badge--style--success .status-badge__body {
  /* background-color: #e2f2da;
    color: #44782a; */
  border-radius: 4px;
  background-color: #e3f2fb;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #0069a7;
}

.status-badge--style--failure .status-badge__body {
  background-color: #f2dada;
  color: #993d3d;
}

.status-badge--style--warning .status-badge__body {
  background-color: #f2eeda;
  color: #786b2a;
}

.status-badge--style--unknown .status-badge__body {
  background-color: #ebebeb;
  color: #6a6a6a;
}

/*
  // .tag-badge
  */
.tag-badge {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  z-index: 0;
  height: 18px;
  padding: 4px 14px 0;
}

.tag-badge:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.tag-badge:before {
  left: 3.5px;
  right: 3.5px;
}

[dir=ltr] .tag-badge:before {
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

[dir=rtl] .tag-badge:before {
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.tag-badge--theme {
  color: #fff;
}

.tag-badge--theme:before {
  background: #e52727;
}

.tag-badge--sale {
  color: #fff;
}

.tag-badge--sale:before {
  background: #ff3333;
}

.tag-badge--new {
  color: #fff;
}

.tag-badge--new:before {
  background: #3377ff;
}

.tag-badge--hot {
  color: #fff;
}

.tag-badge--hot:before {
  background: #614ba6;
}

/*
  // .tags
  */
.tags {
  font-size: 13px;
}

.tags__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 500;
  margin: -2px;
}

.tags__list a {
  display: block;
  margin: 2px;
  background: #f2f2f2;
  padding: 4px 11px;
  color: inherit;
  border-radius: 1.5px;
  -webkit-transition: background .12s;
  transition: background .12s;
}

.tags__list a:hover {
  color: inherit;
  background: #e5e5e5;
}

.tags__list a:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  background: #d9d9d9;
}

.tags--sm .tags__list a {
  padding: 2px 8px;
}

/*
  // .teammate
  */
.teammate {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.teammate__info {
  padding: 14px 16px 16px;
  text-align: center;
}

.teammate__position {
  font-size: 14px;
  color: #6c757d;
}

/*
  // .timer
  */
.timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.timer__dots {
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.timer__dots:before,
.timer__dots:after {
  display: block;
  content: '';
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin: 4px 0;
}

.timer__part {
  border-radius: 2px;
  width: 50px;
  line-height: 1;
  padding: 8px 0;
  text-align: center;
  background: #ffdf40;
  color: #262626;
}

.timer__part-value {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: .04em;
}

.timer__part-label {
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 4px;
  color: #99752e;
}

@media (max-width: 767.98px) {
  .timer__dots {
    width: 18px;
  }

  .timer__dots:before,
  .timer__dots:after {
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
  }

  .timer__part {
    width: 40px;
    padding: 5px 0 6px;
  }

  .timer__part-value {
    font-size: 20px;
  }

  .timer__part-label {
    font-size: 9px;
    margin-top: 3px;
  }
}

/*
  // .tooltip
  */
.tooltip {
  pointer-events: none;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  font-family: inherit;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background: #333;
  color: #fff;
  border-radius: 2px;
  position: relative;
  padding: 5px 8px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  font-size: 14px;
  line-height: 1.25;
}

.tooltip .arrow {
  width: 8px;
  height: 8px;
}

.tooltip .arrow:before {
  width: 8px;
  height: 8px;
  border-radius: 1px;
  border: none;
  background: #333;
  -webkit-transform: rotateZ(45deg) scale(0.78431);
  transform: rotateZ(45deg) scale(0.78431);
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 4px 0;
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-auto[x-placement^=top] .tooltip-inner,
.bs-tooltip-top .arrow,
.bs-tooltip-top .tooltip-inner {
  bottom: 3px;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 4px 0;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-auto[x-placement^=bottom] .tooltip-inner,
.bs-tooltip-bottom .arrow,
.bs-tooltip-bottom .tooltip-inner {
  top: 3px;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 4px;
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-auto[x-placement^=right] .tooltip-inner,
.bs-tooltip-right .arrow,
.bs-tooltip-right .tooltip-inner {
  left: 3px;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 4px;
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-auto[x-placement^=left] .tooltip-inner,
.bs-tooltip-left .arrow,
.bs-tooltip-left .tooltip-inner {
  right: 3px;
}

/*
  // .typography
  */
.typography {
  line-height: 1.625;
}

.typography a:hover {
  text-decoration: underline;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  margin-top: 1.5em;
  margin-bottom: .75em;
}

.typography h2,
.typography h3 {
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
}

.typography h3 {
  padding-bottom: 6px;
}

.typography p {
  margin-bottom: 1.5em;
}

.typography ol,
.typography ul {
  margin: 1.5em 0;
}

[dir=ltr] .typography ol,
[dir=ltr] .typography ul {
  padding-left: 2.5rem;
}

[dir=rtl] .typography ol,
[dir=rtl] .typography ul {
  padding-right: 2.5rem;
}

.typography li {
  margin-bottom: .5em;
}

.typography hr {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  border: none;
  background: #d9d9d9;
  margin: 2.5em auto;
}

.typography hr:after,
.typography hr:before {
  position: absolute;
  content: '';
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}

.typography hr:after {
  left: -30px;
}

.typography hr:before {
  right: -30px;
}

.typography blockquote {
  margin: 2.25rem 0;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 2.25em 2.25em 2em;
}

.typography blockquote cite {
  margin-top: -.5em;
  color: #6c757d;
  display: block;
  font-size: 15px;
  font-style: normal;
}

.typography blockquote p {
  margin-bottom: 1.25em;
}

.typography blockquote>*:first-child {
  margin-top: 0;
}

.typography blockquote>*:last-child {
  margin-bottom: 0;
}

.typography>*:first-child {
  margin-top: 0;
}

.typography>*:last-child {
  margin-bottom: 0;
}

.typography img {
  max-width: 100%;
  border-radius: 2px;
}

.typography figure {
  margin: 1.875rem 0 2.25rem;
}

.typography figcaption {
  font-size: 14px;
  text-align: center;
  color: #6c757d;
  padding-top: .875rem;
}

@media (max-width: 575.98px) {
  .typography {
    font-size: 15px;
    line-height: 1.75;
  }

  .typography h1,
  .typography h2,
  .typography h3,
  .typography h4,
  .typography h5,
  .typography h6 {
    margin-top: 1.25em;
    margin-bottom: .5em;
  }

  .typography h2 {
    font-size: 28px;
  }

  .typography h3 {
    font-size: 24px;
  }

  .typography h4 {
    font-size: 20px;
  }

  .typography h5 {
    font-size: 18px;
  }

  .typography p {
    margin-bottom: 1em;
  }

  .typography ol,
  .typography ul {
    margin: 1.25em 0;
  }

  [dir=ltr] .typography ol,
  [dir=ltr] .typography ul {
    padding-left: 2rem;
  }

  [dir=rtl] .typography ol,
  [dir=rtl] .typography ul {
    padding-right: 2rem;
  }

  .typography hr {
    margin: 1.5em auto;
  }

  .typography blockquote {
    padding: 1em;
    font-size: 16px;
    margin: 1.5rem 0;
  }

  .typography blockquote p {
    margin-bottom: .75em;
  }

  .typography figure {
    margin: 1.5rem 0 1.5rem;
  }
}

/*
  // .vehicle-form
  */
.vehicle-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -6px;
}

.vehicle-form .vehicle-form__item {
  width: calc(50% - 12px);
  margin: 6px;
}

.vehicle-form .vehicle-form__item:last-child {
  width: 100%;
}

.vehicle-form__divider {
  width: 100%;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
  margin: 12px 6px;
}

.vehicle-form__divider:before,
.vehicle-form__divider:after {
  display: block;
  content: '';
  height: 1px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background: #ebebeb;
}

[dir=ltr] .vehicle-form__divider:before {
  margin-right: 6px;
}

[dir=rtl] .vehicle-form__divider:before {
  margin-left: 6px;
}

[dir=ltr] .vehicle-form__divider:after {
  margin-left: 6px;
}

[dir=rtl] .vehicle-form__divider:after {
  margin-right: 6px;
}

.vehicle-form--layout--account .vehicle-form__item {
  width: calc(25% - 12px);
}

@media (max-width: 767.98px) {
  .vehicle-form--layout--account .vehicle-form__item {
    width: calc(50% - 12px);
  }
}

@media (max-width: 459px) {
  .vehicle-form--layout--account .vehicle-form__item {
    width: calc(100% - 12px);
  }
}

@media (max-width: 399px) {
  .vehicle-form--layout--modal .vehicle-form__item {
    width: calc(100% - 12px);
  }
}

/*
  // .vehicles-list
  */
.vehicles-list__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -4px;
}

.vehicles-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  margin: 4px;
}

.vehicles-list__item-radio {
  margin: 8px;
}

.vehicles-list__item-info {
  margin: 2px 8px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  line-height: 1.25;
}

.vehicles-list__item-remove {
  margin: 8px;
}

.vehicles-list__item-remove svg {
  display: block;
}

.vehicles-list__item-name {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.vehicles-list__item-details {
  display: block;
  font-size: 13px;
  color: #999;
  margin-top: 2px;
}

.vehicles-list__item-links {
  font-size: 14px;
  margin-top: 12px;
}

.vehicles-list__item-links a:hover {
  text-decoration: underline;
}

.vehicles-list__item-remove {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  border: none;
  margin: 0;
  border-radius: 2px;
  fill: currentColor;
  -webkit-transition: background-color .15s,
    color .15s;
  transition: background-color .15s,
    color .15s;
  background-color: #fff;
  color: #ccc;
}

.vehicles-list__item-remove:hover {
  background-color: #f2f2f2;
  color: #999;
}

.vehicles-list__item-remove:active {
  background-color: #ebebeb;
  color: #808080;
}

.vehicles-list__item-remove:focus {
  outline: none;
}

.vehicles-list--layout--account .vehicles-list__body {
  margin: -6px;
}

.vehicles-list--layout--account .vehicles-list__item {
  padding: 10px 8px;
  width: calc(50% - 12px);
  margin: 6px;
}

@media (max-width: 767.98px) {
  .vehicles-list--layout--account .vehicles-list__body {
    margin: -6px;
  }

  .vehicles-list--layout--account .vehicles-list__item {
    width: calc(100% - 12px);
    margin: 6px;
  }
}

/*
  // .view-options
  */
.view-options {
  font-size: .9375rem;
}

.view-options__body {
  padding: .75rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.view-options__body+.view-options__body {
  border-top: 1px solid #ebebeb;
}

.view-options__body--filters {
  padding: .875rem 1rem;
}

.view-options__label {
  position: absolute;
  background: #fff;
  padding: 0 8px;
  top: -5px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  color: #999;
}

[dir=ltr] .view-options__label {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

[dir=rtl] .view-options__label {
  right: 50%;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

[dir=ltr] .view-options__filters-button {
  margin-right: 1.25rem;
}

[dir=rtl] .view-options__filters-button {
  margin-left: 1.25rem;
}

[dir=ltr] .view-options__layout {
  margin-right: 1.25rem;
}

[dir=rtl] .view-options__layout {
  margin-left: 1.25rem;
}

.view-options__legend {
  white-space: nowrap;
}

[dir=ltr] .view-options__legend {
  margin-right: 1.25rem;
}

[dir=rtl] .view-options__legend {
  margin-left: 1.25rem;
}

.view-options__spring {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.view-options__select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

[dir=ltr] .view-options__select {
  margin-left: 20px;
}

[dir=rtl] .view-options__select {
  margin-right: 20px;
}

.view-options__select>label {
  margin-bottom: 0;
}

[dir=ltr] .view-options__select>label {
  margin-right: 10px;
}

[dir=rtl] .view-options__select>label {
  margin-left: 10px;
}

@media (min-width: 992px) {
  .view-options--offcanvas--mobile .view-options__filters-button {
    display: none;
  }
}

@media (max-width: 991.98px) {
  [dir=ltr] .view-options__legend {
    margin-right: 0;
    margin-left: auto;
  }

  [dir=rtl] .view-options__legend {
    margin-left: 0;
    margin-right: auto;
  }

  .view-options__spring {
    width: 100%;
    height: 8px;
  }

  [dir=ltr] .view-options__select {
    margin-left: auto;
  }

  [dir=rtl] .view-options__select {
    margin-right: auto;
  }

  [dir=ltr] .view-options__select+.view-options__select {
    margin-left: 20px;
  }

  [dir=rtl] .view-options__select+.view-options__select {
    margin-right: 20px;
  }
}

@media (max-width: 575.98px) {
  .view-options__legend {
    width: 100%;
    padding-top: 10px;
  }

  .view-options__select {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  [dir=ltr] .view-options__select {
    margin-left: 0;
  }

  [dir=rtl] .view-options__select {
    margin-right: 0;
  }

  .view-options__select>label {
    margin: 0 0 2px;
  }

  [dir=ltr] .view-options__layout {
    margin-left: auto;
    margin-right: 0;
  }

  [dir=rtl] .view-options__layout {
    margin-right: auto;
    margin-left: 0;
  }
}

/*
  // .widget
  */
.widget+.widget {
  margin-top: 20px;
}

.widget__header {
  padding: 1.375rem 1.5rem;
}

.widget__header h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

/* ----------------------------------------
  // Filters
  // ---------------------------------------- */
/*
  // .filter
  */
.filter__title {
  display: block;
  border: none;
  padding: 6px 0px;
  cursor: pointer;
  width: calc(100% + 20px);
  position: relative;
  color: inherit;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  border-radius: 2px;
  margin: 0 -10px;
  -webkit-transition: background-color .2s, fill .2s;
  transition: background-color .2s, fill .2s;
  background: transparent;
  fill: #ccc;
}

[dir=ltr] .filter__title {
  text-align: left;
}

[dir=rtl] .filter__title {
  text-align: right;
}

.filter__title:focus,
.filter__title:hover {
  -webkit-transition-duration: .1s, .1s;
  transition-duration: .1s, .1s;
  background: #f2f2f2;
  fill: #b3b3b3;
}

.filter__title:focus {
  outline: none;
}

.filter__arrow {
  position: absolute;
  top: 11px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

[dir=ltr] .filter__arrow {
  right: 10px;
}

[dir=rtl] .filter__arrow {
  left: 10px;
}

.filter__arrow svg {
  display: block;
}

.filter__body {
  margin: 0 -1.5rem;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  opacity: 0;
  -webkit-transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.filter__container {
  padding: 3px 1.5rem 16px;
}

.filter--opened .filter__body {
  -webkit-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  visibility: visible;
  height: auto;
  opacity: 1;
}

.filter--opened .filter__arrow {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

/*
  // .filter-categories
  */
.filter-categories__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
}

.filter-categories__list a {
  color: inherit;
  -webkit-transition: color .15s;
  transition: color .15s;
}

.filter-categories__list a:hover {
  color: #cb1818;
}

.filter-categories__item {
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.filter-categories__item>a {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

[dir=ltr] .filter-categories__item>a {
  padding-right: 10px;
}

[dir=rtl] .filter-categories__item>a {
  padding-left: 10px;
}

.filter-categories__item--parent {
  position: relative;
}

[dir=ltr] .filter-categories__item--parent {
  padding-left: 14px;
}

[dir=rtl] .filter-categories__item--parent {
  padding-right: 14px;
}

.filter-categories__item--current {
  font-weight: 700;
}

.filter-categories__item--child {
  position: relative;
  font-size: 13px;
}

[dir=ltr] .filter-categories__item--child {
  padding-left: 8px;
}

[dir=rtl] .filter-categories__item--child {
  padding-right: 14px;
}

.filter-categories__arrow {
  fill: #ccc;
  position: absolute;
  top: 10px;
}

[dir=ltr] .filter-categories__arrow {
  left: 0;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

[dir=rtl] .filter-categories__arrow {
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.filter-categories__arrow svg {
  display: block;
}

.filter-categories__counter {
  font-size: 12px;
  line-height: 12px;
  padding-top: 4px;
  font-weight: 400;
  color: #6c757d;
}

/*
  // .filter-color
  */
.filter-color__list {
  padding: 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -4px;
}

.filter-color__item {
  cursor: pointer;
  margin: 4px;
}

.filter-color__check {
  display: block;
}

/*
  // .filter-list
  */
.filter-list__list {
  margin: -8px 0;
}

.filter-list__item {
  padding: 1px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 8px 0;
}

.filter-list__input {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .filter-list__input {
  margin-right: 7px;
}

[dir=rtl] .filter-list__input {
  margin-left: 7px;
}

.filter-list__title {
  padding: 1px 0;
  font-size: 14px;
  line-height: 16px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.filter-list__counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}

[dir=ltr] .filter-list__counter {
  margin-left: 12px;
}

[dir=rtl] .filter-list__counter {
  margin-right: 12px;
}

.filter-list__item--disabled {
  cursor: default;
}

.filter-list__item--disabled .filter-list__title {
  color: #6c757d;
}

/*
  // .filter-price
  */
.filter-price__slider {
  margin-top: 6px;
}

.filter-price__title {
  padding-top: 2px;
  font-size: 14px;
}

.filter-price__title-button {
  margin-top: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*
  // .filter-rating
  */
.filter-rating__list {
  list-style: none;
  padding: 0;
  margin: -8px 0;
}

.filter-rating__item-label {
  padding: 2px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 8px 0;
}

.filter-rating__item-input {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .filter-rating__item-input {
  margin-right: 8px;
}

[dir=rtl] .filter-rating__item-input {
  margin-left: 8px;
}

.filter-rating__item-stars {
  padding: 2px 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.filter-rating__item-counter {
  padding-top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}

[dir=ltr] .filter-rating__item-counter {
  margin-left: 12px;
}

[dir=rtl] .filter-rating__item-counter {
  margin-right: 12px;
}

/*
  // .filter-vehicle
  */
.filter-vehicle__list {
  margin: -8px 0;
  padding: 0;
  list-style: none;
}

.filter-vehicle__item-label {
  padding: 1px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 8px 0;
}

.filter-vehicle__item-input {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .filter-vehicle__item-input {
  margin-right: 7px;
}

[dir=rtl] .filter-vehicle__item-input {
  margin-left: 7px;
}

.filter-vehicle__item-title {
  padding: 1px 0;
  font-size: 14px;
  line-height: 16px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.filter-vehicle__item-counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}

[dir=ltr] .filter-vehicle__item-counter {
  margin-left: 12px;
}

[dir=rtl] .filter-vehicle__item-counter {
  margin-right: 12px;
}

.filter-vehicle__item--disabled {
  cursor: default;
}

.filter-vehicle__item--disabled .filter-vehicle__item-title {
  color: #999;
}

.filter-vehicle__button {
  padding-top: 12px;
}

/* ----------------------------------------
  // Footer
  // ---------------------------------------- */
/*
  // .footer-contacts
  */
.footer-contacts__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .footer-contacts__title {
    font-weight: 400;
  }
}

.footer-contacts__text {
  line-height: 22px;
  list-style: none;
  margin: 0;
  font-size: 15px;
}

.footer-contacts__text a {
  color: inherit;
}

.footer-contacts__contacts {
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-contacts__contacts a {
  color: inherit;
}

.footer-contacts__contacts dt {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .04em;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.footer-contacts__contacts dd {
  color: #fff;
  margin: 0;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .footer-contacts__contacts dd {
    font-weight: 400;
  }
}

.footer-contacts__icon {
  display: inline-block;
  text-align: center;
  width: 22px;
  margin-right: 2px;
}

@media (min-width: 1400px) {
  [dir=ltr] .footer-contacts {
    padding-right: 36px;
  }

  [dir=rtl] .footer-contacts {
    padding-left: 36px;
  }
}

@media (min-width: 1200px) {
  .footer-contacts__contacts {
    margin: 24px -15px 0;
  }

  .footer-contacts__contacts dl {
    width: calc(100% / 2 - 30px - 1px);
    margin: 0 15px;
  }

  .footer-contacts__contacts dl:nth-child(n + 3) {
    margin-top: 18px;
  }
}

@media (max-width: 1199.98px) {
  .footer-contacts__contacts {
    margin: -6px -6px;
  }

  .footer-contacts__contacts dl {
    width: calc(100% / 4 - 12px);
    margin: 6px 6px;
  }

  .footer-contacts {
    text-align: center;
    margin-bottom: 42px;
  }

  .footer-contacts__title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .footer-contacts__contacts {
    padding-top: 28px;
  }

  .footer-contacts__contacts dl {
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 12px;
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 991.98px) {
  .footer-contacts__contacts {
    margin: -6px -6px;
  }

  .footer-contacts__contacts dl {
    width: calc(100% / 2 - 12px);
    margin: 6px 6px;
  }

  .footer-contacts__contacts dl {
    padding: 20px 16px;
  }
}

@media (max-width: 767.98px) {
  .footer-contacts__contacts dl {
    padding: 16px;
  }
}

@media (max-width: 479px) {
  .footer-contacts__contacts {
    margin: -4px -4px;
  }

  .footer-contacts__contacts dl {
    width: calc(100% / 1 - 8px);
    margin: 4px 4px;
  }
}

/*
  // .footer-links
  */
.footer-links__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 22px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .footer-links__title {
    font-weight: 400;
  }
}

.footer-links__list {
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links__list a {
  color: inherit;
  -webkit-transition: .15s;
  transition: .15s;
}

.footer-links__list a:hover {
  color: #fff;
}

.footer-links__item {
  padding: 5px 0;
}

@media (max-width: 767.98px) {
  .footer-links {
    text-align: center;
  }

  .footer-links__title {
    margin-bottom: 12px;
  }
}

/*
  // .footer-newsletter
  */
.footer-newsletter__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .footer-newsletter__title {
    font-weight: 400;
  }
}

.footer-newsletter__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}

.footer-newsletter__text--social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-newsletter__social-links {
  margin-top: 8px;
}

.footer-newsletter__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 380px;
}

.footer-newsletter__form-input {
  -ms-flex-negative: 1;
  flex-shrink: 1;
  border-radius: 2px;
  font-size: 15px;
  height: 38px;
  padding: 0 12px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  min-width: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-family: inherit;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-clip: padding-box;
  -webkit-transition: border .2s,
    background .2s;
  transition: border .2s,
    background .2s;
  color: #fff;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}

.footer-newsletter__form-input::-webkit-input-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::-moz-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input:-ms-input-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::-ms-input-placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::placeholder {
  color: #b2b2b2;
}

.footer-newsletter__form-input::-webkit-input-placeholder {
  -webkit-transition: color .2s;
  transition: color .2s;
}

.footer-newsletter__form-input::-moz-placeholder {
  -moz-transition: color .2s;
  transition: color .2s;
}

.footer-newsletter__form-input:-ms-input-placeholder {
  -ms-transition: color .2s;
  transition: color .2s;
}

.footer-newsletter__form-input::-ms-input-placeholder {
  -ms-transition: color .2s;
  transition: color .2s;
}

.footer-newsletter__form-input::placeholder {
  -webkit-transition: color .2s;
  transition: color .2s;
}

.footer-newsletter__form-input:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}

.footer-newsletter__form-input:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}

.footer-newsletter__form-input:focus::-webkit-input-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus::-moz-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus:-ms-input-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus::-ms-input-placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-input:focus::placeholder {
  color: #9e9e9e;
}

.footer-newsletter__form-button {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  -webkit-transition: background .2s,
    color .2s;
  transition: background .2s,
    color .2s;
  background-color: #e52727;
  color: #fff;
  background-color: #e52727;
  color: #fff;
}

.footer-newsletter__form-button:hover {
  background-color: #545454;
  color: #fff;
}

.footer-newsletter__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}

[dir=ltr] .footer-newsletter__form-button {
  margin-left: 8px;
}

[dir=rtl] .footer-newsletter__form-button {
  margin-right: 8px;
}

.footer-newsletter__form-button:hover {
  background-color: #545454;
  color: #fff;
}

.footer-newsletter__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}

.footer-newsletter__form-button:active {
  -webkit-transition-duration: .1s, .1s;
  transition-duration: .1s, .1s;
}

.footer-newsletter__form-button:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .footer-newsletter {
    text-align: center;
    margin-top: 28px;
  }

  .footer-newsletter__title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .footer-newsletter__form {
    margin: 0 auto;
  }

  .footer-newsletter__text--social {
    margin-top: 24px;
  }

  .footer-newsletter__social-links {
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/*
  // .site-footer
  */
.site-footer {
  position: relative;
  background-color: #333;
  color: #9e9e9e;
}

.site-footer__decor {
  position: absolute;
  bottom: calc(100% - 1px);
}

.site-footer__decor .decor__start,
.site-footer__decor .decor__end,
.site-footer__decor .decor__center {
  background: #333;
}

.site-footer__decor .decor__start,
.site-footer__decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.site-footer__widgets {
  padding: 56px 0 50px;
}

.site-footer__bottom {
  background-color: #2b2b2b;
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
}

.site-footer__bottom a {
  color: #fff;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .site-footer__bottom a {
    font-weight: 400;
  }
}

.site-footer__bottom-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 72px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .site-footer__decor {
    display: none;
  }

  .site-footer__widgets {
    padding: 40px 0 36px;
  }

  .site-footer__bottom-row {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 0 24px;
  }

  .site-footer__payments {
    margin-top: 12px;
  }
}

/* ----------------------------------------
  // Pages
  // ---------------------------------------- */
/*
  // .about
  */
.about__body {
  display: grid;
  z-index: 0;
}

@media (min-width: 1200px) {
  .about__body {
    grid-template-columns: 1fr 380px 730px 1fr;
    grid-template-rows: 100px auto auto;
  }

  .about__image {
    grid-column: 1 / 5;
    grid-row: 1 / 3;
  }

  .about__card {
    grid-column: 2;
    grid-row: 2 / 4;
  }

  .about__indicators {
    grid-column: 3;
    grid-row: 3;
  }
}

@media (max-width: 1199.98px) {
  .about__image {
    grid-column: 1 / 4;
    grid-row: 1 / 3;
  }

  .about__card {
    grid-column: 2;
    grid-row: 2 / 4;
  }

  .about__indicators {
    grid-column: 2;
    grid-row: 4;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .about__body {
    grid-template-columns: 1fr 690px 1fr;
    grid-template-rows: 80px auto 60px auto;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .about__body {
    grid-template-columns: 1fr 510px 1fr;
    grid-template-rows: 40px auto 60px auto;
  }
}

@media (max-width: 575.98px) {
  .about__body {
    grid-template-columns: 20px calc(100% - 40px) 20px;
    grid-template-rows: 20px auto 60px auto;
  }
}

/*
  // .about__image
  */
.about__image {
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.about__image-decor {
  position: absolute;
  bottom: -1px;
}

.about__image-decor .decor__start,
.about__image-decor .decor__end,
.about__image-decor .decor__center {
  background: #fafafa;
}

.about__image-decor .decor__start,
.about__image-decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.about__image-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.about__image-bg:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(51, 51, 51, 0.7)), to(rgba(51, 51, 51, 0.4)));
  background: linear-gradient(to bottom, rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.4));
}

/*
  // .about__card
  */
.about__card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  padding: 32px 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.about__card-title {
  font-size: 40px;
  font-weight: 700;
  padding: 16px 0 40px;
  text-align: center;
  margin-bottom: 0;
}

.about__card-text {
  text-align: center;
  line-height: 1.75;
}

.about__card-author {
  color: #6c757d;
  font-size: 14px;
  position: relative;
  -ms-flex-item-align: center;
  align-self: center;
  margin-top: 16px;
}

[dir=ltr] .about__card-author {
  padding-left: 28px;
}

[dir=rtl] .about__card-author {
  padding-right: 28px;
}

.about__card-author:before {
  display: block;
  height: 1px;
  width: 20px;
  background: currentColor;
  content: '';
  opacity: .8;
  position: absolute;
  top: 10px;
}

[dir=ltr] .about__card-author:before {
  left: 0;
}

[dir=rtl] .about__card-author:before {
  right: 0;
}

.about__card-signature {
  padding: 40px 0 12px;
  margin-top: auto;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (min-width: 1200px) {
  .about__card {
    min-height: 530px;
  }
}

@media (max-width: 1199.98px) {
  .about__card {
    padding: 32px 64px;
    margin: 0 auto;
    max-width: 510px;
  }
}

@media (max-width: 767.98px) {
  .about__card {
    padding: 32px 36px;
  }
}

@media (max-width: 575.98px) {
  .about__card-title {
    padding: 8px 0 24px;
    font-size: 32px;
  }

  .about__card {
    padding: 24px 28px;
    font-size: 15px;
  }
}

/*
  // .about__indicators
  */
.about__indicators-body {
  margin: -12px;
}

.about__indicators-item {
  margin: 12px;
  width: calc(100% / 3 - 24px);
}

.about__indicators {
  -ms-flex-item-align: center;
  align-self: center;
}

.about__indicators-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.about__indicators-item-value {
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
}

.about__indicators-item-title {
  color: #6c757d;
  font-size: 15px;
  margin-top: 6px;
  line-height: 18px;
}

@media (min-width: 1200px) {
  .about__indicators {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  [dir=ltr] .about__indicators {
    margin-left: 52px;
  }

  [dir=rtl] .about__indicators {
    margin-right: 52px;
  }
}

@media (max-width: 1199.98px) {
  .about__indicators {
    text-align: center;
    margin-top: 48px;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .about__indicators {
    margin-top: 40px;
  }

  .about__indicators-item-value {
    font-size: 36px;
  }
}

@media (max-width: 575.98px) {
  .about__indicators-body {
    margin: -16px;
  }

  .about__indicators-item {
    margin: 16px;
    width: calc(100% / 1 - 32px);
  }
}

/*
  // .contacts
  */
.contacts__map {
  position: relative;
  height: 400px;
}

.contacts__map iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991.98px) {
  .contacts__map {
    height: 380px;
  }
}

@media (max-width: 767.98px) {
  .contacts__map {
    height: 360px;
  }
}

/*
  // .faq
  */
.faq__header {
  padding: 44px 0 52px;
  text-align: center;
}

.faq__header-title {
  font-weight: 700;
  margin: 0;
}

.faq__section {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  padding: 48px;
}

.faq__section+.faq__section {
  margin-top: 30px;
}

.faq__section-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 2.5rem;
  margin-top: -4px;
}

.faq__footer {
  margin-top: 56px;
  text-align: center;
}

.faq__footer-title {
  font-size: 28px;
  margin-bottom: 8px;
}

.faq__footer-subtitle {
  margin-bottom: 36px;
  color: #6c757d;
}

.faq__section-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -20px;
}

.faq__question {
  margin: 20px;
  width: calc(50% - 40px);
}

.faq__question-title {
  margin-bottom: 14px;
}

@media (max-width: 1199.98px) {
  .faq__header {
    padding: 48px 0 48px;
  }

  .faq__section {
    padding: 40px;
  }
}

@media (max-width: 991.98px) {
  .faq__section-body {
    margin: -12px;
  }

  .faq__question {
    margin: 12px;
    width: calc(100% - 24px);
  }
}

@media (max-width: 767.98px) {
  .faq__header {
    padding: 36px 0 32px;
  }

  .faq__header-title {
    font-size: 32px;
  }

  .faq__section {
    padding: 32px;
  }

  .faq__section-title {
    margin-bottom: 32px;
  }

  .faq__footer {
    margin-top: 40px;
  }

  .faq__footer-title {
    font-size: 24px;
  }

  .faq__footer-subtitle {
    font-size: 15px;
    margin-bottom: 24px;
  }
}

@media (max-width: 575.98px) {
  .faq__header {
    padding: 32px 0 28px;
  }

  .faq__section {
    padding: 24px;
  }

  .faq__section-title {
    margin-bottom: 28px;
  }

  .faq__question-title {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .faq__question-answer .typography {
    font-size: 15px;
  }

  .faq__footer-subtitle {
    margin-bottom: 20px;
  }
}

/*
  // .not-found
  */
.not-found {
  text-align: center;
  padding: 36px 0;
  margin: 110px auto;
}

.not-found__404 {
  font-size: 80px;
  font-weight: 700;
  color: #ebebeb;
  padding: 20px 0 4px;
}

.not-found__content {
  width: 480px;
  max-width: 100%;
  margin-left: 77px;
}

.not-found__title {
  margin-bottom: 31px;
  font-family: Montserrat;
  text-align: justify;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: 1.91px;
  color: #3d3839;
}

.not-found__text {
  margin-bottom: 8px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.86px;
  color: #645a5b;
  text-align: justify;
}

.not-found__sub-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.2px;
  color: #645a5b;
  text-align: justify;
}

.go_to_home_btn {
  width: auto;
  float: left;
  height: 45px;
  padding: 14px 16px 13px;
  background-color: #ed2c2b;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  outline: none;
  border: none !important;
}

.go_to_home_btn:focus {
  border: none;
  outline: none;
}

/* ----------------------------------------
  // Blocks
  // ---------------------------------------- */
/*
  // .block-banners
  */
.block-banners__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -15px;
}

.block-banners__item {
  margin: 15px;
  width: calc(50% - 30px);
  height: 210px;
  padding: 32px 34px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  z-index: 0;
}

.block-banners__item,
.block-banners__item:hover {
  color: #fff;
}

.block-banners__item:before {
  border-radius: 3px;
  z-index: -1;
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

[dir=ltr] .block-banners__item:before {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(26, 26, 26, 0.98)), color-stop(90%, rgba(26, 26, 26, 0)));
  background: linear-gradient(to right, rgba(26, 26, 26, 0.98) 25%, rgba(26, 26, 26, 0) 90%);
}

[dir=rtl] .block-banners__item:before {
  background: -webkit-gradient(linear, right top, left top, color-stop(25%, rgba(26, 26, 26, 0.98)), color-stop(90%, rgba(26, 26, 26, 0)));
  background: linear-gradient(to left, rgba(26, 26, 26, 0.98) 25%, rgba(26, 26, 26, 0) 90%);
}

.block-banners__item-title {
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #ffdf40;
}

.block-banners__item-details {
  display: block;
  margin-top: 12px;
  font-size: 15px;
}

.block-banners__item-button {
  margin-top: 28px;
}

.block-banners__item-image {
  border-radius: 3px;
  z-index: -3;
  position: absolute;
  content: '';
  display: block;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  left: -10px;
  top: -10px;
  -webkit-transition: opacity .3s ease-out,
    -webkit-transform .3s ease-out;
  transition: opacity .3s ease-out,
    -webkit-transform .3s ease-out;
  transition: transform .3s ease-out,
    opacity .3s ease-out;
  transition: transform .3s ease-out,
    opacity .3s ease-out,
    -webkit-transform .3s ease-out;
}

.block-banners__item-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.block-banners__item-image--blur {
  z-index: -2;
  opacity: 0;
}

.block-banners__item-image--blur img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.block-banners__item:hover .block-banners__item-image {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.block-banners__item:hover .block-banners__item-image--blur {
  opacity: 1;
}

.block-banners__item--style--two .block-banners__item-title {
  background: #ffdf40;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 5px;
  border-radius: 1.5px;
  color: #262626;
  font-size: 24px;
  margin-top: -2px;
  margin-bottom: 2px;
}

@media (max-width: 1199.98px) {
  .block-banners__list {
    margin: -10px;
  }

  .block-banners__item {
    margin: 10px;
    width: calc(50% - 20px);
  }

  .block-banners__item {
    height: 190px;
    padding: 24px 24px;
  }
}

@media (max-width: 991.98px) {
  .block-banners__list {
    margin: -10px;
  }

  .block-banners__item {
    margin: 10px;
    width: calc(100% - 20px);
  }

  .block-banners__item {
    height: auto;
    padding: 24px 24px;
  }
}

@media (max-width: 474px) {
  [dir=ltr] .block-banners__item:before {
    background: -webkit-gradient(linear, left top, right top, color-stop(35%, rgba(26, 26, 26, 0.98)), to(rgba(26, 26, 26, 0)));
    background: linear-gradient(to right, rgba(26, 26, 26, 0.98) 35%, rgba(26, 26, 26, 0) 100%);
  }

  [dir=rtl] .block-banners__item:before {
    background: -webkit-gradient(linear, right top, left top, color-stop(35%, rgba(26, 26, 26, 0.98)), to(rgba(26, 26, 26, 0)));
    background: linear-gradient(to left, rgba(26, 26, 26, 0.98) 35%, rgba(26, 26, 26, 0) 100%);
  }
}

/*
  // .block-brands
  */
.block-brands__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #ebebeb;
}

.block-brands__item {
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block-brands__item-link {
  position: relative;
  display: block;
  padding: 12px 28px;
  width: 100%;
}

.block-brands__item-link:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.02);
  opacity: 0;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
}

.block-brands__item-link img {
  max-width: 100%;
  max-height: 72px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: .7;
  -webkit-transition: opacity 0.15s, -webkit-filter 0.15s;
  transition: opacity 0.15s, -webkit-filter 0.15s;
  transition: filter 0.15s, opacity 0.15s;
  transition: filter 0.15s, opacity 0.15s, -webkit-filter 0.15s;
}

.block-brands__item-name {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .04em;
  color: #b3b3b3;
  margin-top: 4px;
  margin-bottom: -2px;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.block-brands__item-link:hover:before {
  opacity: 1;
}

.block-brands__item-link:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.block-brands__item-link:hover .block-brands__item-name {
  color: #737373;
}

.block-brands__divider {
  background: #ebebeb;
}

@media (min-width: 1400px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 8px) / 8);
  }

  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }

  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(16n) {
    height: 1px;
    width: 100%;
  }

  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(16n):last-child {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 7px) / 7);
  }

  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }

  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }

  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 6px) / 6);
  }

  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }

  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }

  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}

@media (min-width: 1400px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 7px) / 7);
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 6px) / 6);
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 5px) / 5);
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }

  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-brands__item {
    width: calc((100% - 5px) / 5);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }

  .block-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .block-brands__item {
    width: calc((100% - 4px) / 4);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(8n) {
    height: 1px;
    width: 100%;
  }

  .block-brands__divider:nth-child(8n):last-child {
    display: none;
  }
}

@media (min-width: 420px) and (max-width: 575px) {
  .block-brands__item {
    width: calc((100% - 3px) / 3);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }

  .block-brands__divider:nth-child(6n):last-child {
    display: none;
  }
}

@media (max-width: 419px) {
  .block-brands__item {
    width: calc((100% - 2px) / 2);
  }

  .block-brands__divider {
    width: 1px;
  }

  .block-brands__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }

  .block-brands__divider:nth-child(4n):last-child {
    display: none;
  }
}

/*
  // .block-categories
  */
.block-categories__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-categories__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-categories__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block-categories__title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: -24px;
  padding: 0 64px 28px;
}

.block-categories__title-decor {
  position: absolute;
  width: 100%;
  bottom: 1px;
}

[dir=ltr] .block-categories__title-decor {
  left: 0;
}

[dir=rtl] .block-categories__title-decor {
  right: 0;
}

.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end,
.block-categories__title-decor .decor__center {
  background: #fafafa;
}

.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
}

.block-categories__title-decor .decor__body {
  height: 35px;
  margin: 0 -10px -10px;
}

.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end {
  height: 25px;
  width: calc(50% - 24.43375px);
}

.block-categories__title-decor .decor__start {
  left: 10px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

.block-categories__title-decor .decor__end {
  right: 10px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

.block-categories__title-decor .decor__center {
  height: 25px;
  width: 30.8675px;
}

.block-categories__body {
  position: relative;
  background-color: #f6f6f6;
  padding: 76px 0 64px;
  -webkit-box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.08);
}

.block-categories__body-decor {
  position: absolute;
  bottom: -1px;
  height: 35px;
}

.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end,
.block-categories__body-decor .decor__center {
  background: #fafafa;
}

.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
}

.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end {
  height: 25px;
}

@media (min-width: 1400px) {
  .block-categories__list {
    margin: -12px;
  }

  .block-categories__item {
    width: calc((100% - 72px) / 3);
    margin: 12px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 48px) / 3);
    margin: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 48px) / 3);
    margin: 8px;
  }
}

@media (max-width: 767.98px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
}

@media (max-width: 474px) {
  .block-categories__list {
    margin: -8px;
  }

  .block-categories__item {
    width: calc((100% - 16px) / 1);
    margin: 8px;
  }
}

/*
  // .block-features
  */
.block-features__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 8px 40px;
}

.block-features__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc((100% - 3px) / 4);
  padding: 1.5rem 2.5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block-features__item-icon {
  fill: #e52727;
}

[dir=ltr] .block-features__item-icon {
  margin-right: 1.375rem;
}

[dir=rtl] .block-features__item-icon {
  margin-left: 1.375rem;
}

.block-features__item-title {
  font-size: 18px;
  font-weight: 500;
}

.block-features__item-subtitle {
  color: #6c757d;
  font-size: 15px;
}

.block-features--layout--top-strip .block-features__list {
  border-bottom: 2px solid #ebebeb;
}

.block-features--layout--bottom-strip .block-features__list {
  border-top: 2px solid #ebebeb;
}

@media (max-width: 1399.98px) {
  .block-features__list {
    padding: 0;
  }

  .block-features__item {
    padding: 1.5rem 1.5rem;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .block-features__item-title {
    font-size: 16px;
  }

  .block-features__item-subtitle {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .block-features__item {
    padding: 1.375rem .75rem;
  }

  [dir=ltr] .block-features__item-icon {
    margin-right: 1rem;
  }

  [dir=rtl] .block-features__item-icon {
    margin-left: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-features__list {
    padding: 4px 0;
  }

  .block-features__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .block-features__item-icon {
    margin-bottom: 12px;
  }

  [dir=ltr] .block-features__item-icon {
    margin-right: 0;
  }

  [dir=rtl] .block-features__item-icon {
    margin-left: 0;
  }
}

@media (max-width: 767.98px) {
  .block-features__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 16px 0;
  }

  .block-features__item {
    width: 50%;
    padding: .75rem 1.25rem;
  }
}

@media (max-width: 575px) and (min-width: 400px) {
  .block-features__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .block-features__item-icon {
    margin-bottom: 12px;
  }

  [dir=ltr] .block-features__item-icon {
    margin-right: 0;
  }

  [dir=rtl] .block-features__item-icon {
    margin-left: 0;
  }
}

@media (max-width: 399px) {
  .block-features__item {
    width: 100%;
    padding: .675rem 1.25rem;
  }

  [dir=ltr] .block-features__item-icon {
    margin-right: 18px;
  }

  [dir=rtl] .block-features__item-icon {
    margin-left: 18px;
  }
}

/*
  // .block-finder
  */
.block-finder {
  position: relative;
  height: 500px;
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #333;
}

.block-finder__decor {
  position: absolute;
  bottom: -1px;
  z-index: 2;
}

.block-finder__decor .decor__start,
.block-finder__decor .decor__end,
.block-finder__decor .decor__center {
  background: #fafafa;
}

.block-finder__decor .decor__start,
.block-finder__decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.block-finder__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  opacity: .22;
}

.block-finder__body {
  color: #fff;
  text-align: center;
  z-index: 2;
}

.block-finder__title {
  font-size: 48px;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.block-finder__subtitle {
  margin: 0 0 60px;
  font-size: 18px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.block-finder__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -7px;
}

.block-finder__form-control {
  margin: 7px;
  height: 44px;
  min-height: 44px;
}

.block-finder__form-control:focus {
  outline: none;
}

.block-finder__form-control--select {
  min-width: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.block-finder__form-control--select select {
  width: 100%;
  height: 100%;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
}

.block-finder__form-control--select select:disabled {
  opacity: .65;
  color: #999;
}

.block-finder__form-control--select select,
.block-finder__form-control--select .select2-selection--single {
  height: 44px;
  border-radius: 2px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 5px 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='%234d4d4d' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  -webkit-transition: opacity .25s ease-in-out,
    color .25s ease-in-out;
  transition: opacity .25s ease-in-out,
    color .25s ease-in-out;
}

[dir=ltr] .block-finder__form-control--select select,
[dir=ltr] .block-finder__form-control--select .select2-selection--single {
  background-position: right 12px center;
  text-align: left;
}

[dir=rtl] .block-finder__form-control--select select,
[dir=rtl] .block-finder__form-control--select .select2-selection--single {
  background-position: left 12px center;
  text-align: right;
}

.block-finder__form-control--select select:focus,
.block-finder__form-control--select .select2-selection--single:focus {
  outline: none;
}

.block-finder__form-control--select select,
.block-finder__form-control--select .select2-selection--single .select2-selection__rendered {
  color: #262626;
}

[dir=ltr] .block-finder__form-control--select select,
[dir=ltr] .block-finder__form-control--select .select2-selection--single .select2-selection__rendered {
  padding-left: 16px;
  padding-right: 29px;
}

[dir=rtl] .block-finder__form-control--select select,
[dir=rtl] .block-finder__form-control--select .select2-selection--single .select2-selection__rendered {
  padding-right: 16px;
  padding-left: 29px;
}

.block-finder__form-control--select .select2-selection--single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.block-finder__form-control--select .select2-selection__arrow {
  display: none;
}

.block-finder__form-control--select .select2-selection--single {
  border: none;
}

.block-finder__form-control--select .select2-container--disabled .select2-selection--single {
  background-color: #fff;
  opacity: .65;
}

.block-finder__form-control--select .select2-container--disabled .select2-selection__rendered {
  color: #999;
}

.block-finder__form-control--select .select2-container {
  width: 100%;
  height: 100%;
}

.block-finder__form-control--button {
  border-radius: 2px;
  border: none;
  padding: 0 40px;
  -webkit-transition: background .2s,
    color .2s;
  transition: background .2s,
    color .2s;
  background-color: #e52727;
  color: #fff;
}

.block-finder__form-control--button:hover {
  background-color: #545454;
  color: #fff;
}

.block-finder__form-control--button:active {
  background-color: #4c4c4c;
  color: #fff;
}

.block-finder__form-control--button:active {
  -webkit-transition-duration: .1s, .1s;
  transition-duration: .1s, .1s;
}

.block-finder__form-control--button:focus {
  outline: none;
}

@media (max-width: 1199.98px) {
  .block-finder {
    padding-bottom: 36px;
  }

  .block-finder__subtitle {
    margin-bottom: 48px;
  }

  .block-finder__form {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 548px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .block-finder__form-control {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 260px;
    -ms-flex-preferred-size: 260px;
    flex-basis: 260px;
  }

  .block-finder__form-control--button {
    margin-top: 35px;
  }
}

@media (max-width: 991.98px) {
  .block-finder__title {
    font-size: 32px;
    line-height: 40px;
  }

  .block-finder__subtitle {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .block-finder {
    height: auto;
    padding: 64px 0 76px;
  }
}

@media (max-width: 767.98px) {
  .block-finder__title {
    font-size: 28px;
    line-height: 36px;
  }

  .block-finder__subtitle {
    font-size: 15px;
  }

  .block-finder__form-control {
    height: 40px;
    min-height: 40px;
  }

  .block-finder__form-control--select select,
  .block-finder__form-control--select .select2-selection--single {
    height: 40px;
  }

  .block-finder__form-control--button {
    margin-top: 35px;
  }
}

@media (max-width: 399px) {
  .block-finder {
    height: auto;
    padding: 40px 0 48px;
  }

  .block-finder__title {
    font-size: 26px;
    line-height: 34px;
  }

  .block-finder__subtitle {
    font-size: 14px;
    margin-bottom: 32px;
  }

  .block-finder__form-control--button {
    margin-top: 21px;
  }
}

/*
  // .block-header
  */
.block-header__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.block-header__breadcrumb {
  padding-top: 16px;
  padding-bottom: 8px;
}

.block-header__title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  padding-top: 36px;
  padding-bottom: 40px;
}

@media (min-width: 1200px) {
  .block-header__title {
    margin: 0 auto;
  }

  .block-header__breadcrumb+.block-header__title {
    margin-top: -77px;
  }
}

@media (max-width: 1199.98px) {
  .block-header__title {
    padding-bottom: 20px;
    font-size: 2rem;
    padding-top: 32px;
  }

  .block-header__breadcrumb+.block-header__title {
    margin-top: -44px;
  }
}

/*
  // .block-map
  */
.block-map {
  display: block;
  position: relative;
  height: 420px;
}

.block-map iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.block-map:before,
.block-map:after {
  position: absolute;
  content: '';
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1;
  pointer-events: none;
}

.block-map:before {
  top: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.03)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0));
}

.block-map:after {
  bottom: 0;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.03)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0));
}

@media (max-width: 1399.98px) {
  .block-map {
    height: 400px;
  }
}

@media (max-width: 1199.98px) {
  .block-map {
    height: 380px;
  }
}

@media (max-width: 991.98px) {
  .block-map {
    height: 360px;
  }
}

@media (max-width: 767.98px) {
  .block-map {
    height: 340px;
  }
}

/*
  // .block-posts-carousel
  */
.block-posts-carousel__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
  position: relative;
}

.block-posts-carousel__carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-posts-carousel__carousel .owl-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.block-posts-carousel__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-posts-carousel--layout--grid .post-card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.block-posts-carousel--layout--grid .post-card .post-card__image img {
  max-width: 100%;
  height: auto;
}

.block-posts-carousel--layout--grid .post-card .post-card__content {
  position: relative;
}

.block-posts-carousel--layout--grid .post-card .post-card__title h2 {
  margin: 0;
}

.block-posts-carousel--layout--grid .post-card .post-card__title a {
  color: inherit;
}

.block-posts-carousel--layout--grid .post-card .post-card__title a:hover {
  text-decoration: underline;
}

.block-posts-carousel--layout--grid .post-card .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}

[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__category {
  left: 0;
}

[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__category {
  right: 0;
}

.block-posts-carousel--layout--grid .post-card .post-card__category a {
  color: #cb1818;
  display: block;
}

.block-posts-carousel--layout--grid .post-card .post-card__category a:hover {
  text-decoration: underline;
}

.block-posts-carousel--layout--grid .post-card .post-card__category:before,
.block-posts-carousel--layout--grid .post-card .post-card__category:after {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}

.block-posts-carousel--layout--grid .post-card .post-card__category:before {
  width: 100%;
}

[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.block-posts-carousel--layout--grid .post-card .post-card__category:after {
  width: 50px;
}

[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__category:after {
  left: 0;
}

[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__category:after {
  right: 0;
}

.block-posts-carousel--layout--grid .post-card .post-card__date {
  font-size: 14px;
  color: #999;
}

.block-posts-carousel--layout--grid .post-card .post-card__date a {
  color: inherit;
}

.block-posts-carousel--layout--grid .post-card .post-card__date a:hover {
  color: #cb1818;
}

.block-posts-carousel--layout--grid .post-card .post-card__date:before {
  display: block;
  content: '';
  height: 1px;
  background: currentColor;
  opacity: .6;
}

.block-posts-carousel--layout--grid .post-card .post-card__excerpt {
  overflow: hidden;
}

.block-posts-carousel--layout--grid .post-card .post-card__category {
  font-size: 13px;
}

.block-posts-carousel--layout--grid .post-card .post-card__title h2 {
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  max-height: 40px;
}

.block-posts-carousel--layout--grid .post-card .post-card__category {
  height: 22px;
  padding: 0.75rem 1.25rem 0;
}

.block-posts-carousel--layout--grid .post-card .post-card__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.125rem 1.25rem 1rem;
}

.block-posts-carousel--layout--grid .post-card .post-card__date {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  font-size: 13px;
  margin-top: 6px;
}

.block-posts-carousel--layout--grid .post-card .post-card__date:before {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  top: -1px;
}

[dir=ltr] .block-posts-carousel--layout--grid .post-card .post-card__date:before {
  margin-right: 2px;
}

[dir=rtl] .block-posts-carousel--layout--grid .post-card .post-card__date:before {
  margin-left: 2px;
}

.block-posts-carousel--layout--grid .post-card .post-card__excerpt,
.block-posts-carousel--layout--grid .post-card .post-card__more {
  display: none;
}

@media (min-width: 576px) {
  .block-posts-carousel--layout--list .post-card {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .block-posts-carousel--layout--list .post-card .post-card__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    margin-bottom: 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title a {
    color: inherit;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title a:hover {
    text-decoration: underline;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    font-size: 14px;
    font-weight: 500;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category a {
    color: #cb1818;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category a:hover {
    text-decoration: underline;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    overflow: hidden;
    margin-bottom: auto;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    font-size: 14px;
    color: #999;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date a {
    color: inherit;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date a:hover {
    color: #cb1818;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    content: '';
    height: 1px;
    background: currentColor;
    opacity: .6;
  }
}

@media (min-width: 1400px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 280px;
    min-height: 220px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 20px 26px 22px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 240px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 16px 22px 16px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    max-height: 44px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    line-height: 19px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 12px;
    margin-bottom: 10px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 210px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 280px;
    min-height: 220px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 20px 26px 22px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .block-posts-carousel--layout--list .post-card .post-card__image {
    width: 240px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    padding: 16px 22px 16px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    margin-bottom: 6px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title {
    margin-bottom: 8px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    max-height: 44px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt .typography {
    line-height: 22px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    line-height: 19px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 12px;
    margin-bottom: 10px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .block-posts-carousel--layout--list .post-card {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .block-posts-carousel--layout--list .post-card .post-card__image img {
    max-width: 100%;
    height: auto;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    position: relative;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    margin: 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title a {
    color: inherit;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title a:hover {
    text-decoration: underline;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    position: absolute;
    bottom: calc(100% - 1px);
    font-weight: 500;
    z-index: 0;
    font-size: 14px;
  }

  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__category {
    left: 0;
  }

  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__category {
    right: 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category a {
    color: #cb1818;
    display: block;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category a:hover {
    text-decoration: underline;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category:before,
  .block-posts-carousel--layout--list .post-card .post-card__category:after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category:before {
    width: 100%;
  }

  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__category:before {
    left: 0;
    border-top-right-radius: 2px;
    -webkit-transform: skewX(30deg);
    transform: skewX(30deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__category:before {
    right: 0;
    border-top-left-radius: 2px;
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
    -webkit-transform-origin: right top;
    transform-origin: right top;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category:after {
    width: 50px;
  }

  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__category:after {
    left: 0;
  }

  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__category:after {
    right: 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    font-size: 14px;
    color: #999;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date a {
    color: inherit;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date a:hover {
    color: #cb1818;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    display: block;
    content: '';
    height: 1px;
    background: currentColor;
    opacity: .6;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt {
    overflow: hidden;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    font-size: 13px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__title h2 {
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    max-height: 40px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__category {
    height: 22px;
    padding: 0.75rem 1.25rem 0;
  }

  .block-posts-carousel--layout--list .post-card .post-card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.125rem 1.25rem 1rem;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    font-size: 13px;
    margin-top: 6px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__date:before {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    top: -1px;
  }

  [dir=ltr] .block-posts-carousel--layout--list .post-card .post-card__date:before {
    margin-right: 2px;
  }

  [dir=rtl] .block-posts-carousel--layout--list .post-card .post-card__date:before {
    margin-left: 2px;
  }

  .block-posts-carousel--layout--list .post-card .post-card__excerpt,
  .block-posts-carousel--layout--list .post-card .post-card__more {
    display: none;
  }
}

/*
  // .block-products-carousel
  */
.block-products-carousel__carousel {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-products-carousel__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
  position: relative;
}

.block-products-carousel__carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-products-carousel__carousel .owl-item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-products-carousel__carousel-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s,
    visibility 0s .3s;
  transition: opacity .3s,
    visibility 0s .3s;
}

.block-products-carousel__carousel-loader:before {
  position: absolute;
  display: block;
  content: '';
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: #fafafa;
  opacity: .9;
}

.block-products-carousel__carousel-loader:after {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  -webkit-animation-name: loader-animation;
  animation-name: loader-animation;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: '';
}

.block-products-carousel__carousel--loading .block-products-carousel__carousel-loader {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 1;
}

.block-products-carousel__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.block-products-carousel__cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.block-products-carousel__cell .product-card {
  width: 100%;
}

.block-products-carousel[data-layout="grid-4"] .block-products-carousel__cell+.block-products-carousel__cell,
.block-products-carousel[data-layout="grid-5"] .block-products-carousel__cell+.block-products-carousel__cell {
  margin-top: 20px;
}

.block-products-carousel[data-layout="grid-6"] .block-products-carousel__cell+.block-products-carousel__cell {
  margin-top: 16px;
}

.block-products-carousel[data-layout|="horizontal"] .block-products-carousel__cell+.block-products-carousel__cell {
  margin-top: 14px;
}

/*
  // .block-products-columns
  */
.block-products-columns__title {
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}

.block-products-columns__list-item+.block-products-columns__list-item {
  margin-top: 16px;
}

.block-products-columns__list-item .product-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-products-columns__list-item .product-card .product-card__action--wishlist,
.block-products-columns__list-item .product-card .product-card__action--compare,
.block-products-columns__list-item .product-card .product-card__features,
.block-products-columns__list-item .product-card .product-card__addtocart-icon,
.block-products-columns__list-item .product-card .product-card__addtocart-full,
.block-products-columns__list-item .product-card .product-card__wishlist,
.block-products-columns__list-item .product-card .product-card__compare,
.block-products-columns__list-item .product-card .product-card__meta,
.block-products-columns__list-item .product-card .product-card__fit {
  display: none;
}

.block-products-columns__list-item .product-card .product-card__image {
  padding: 8px 10px;
  width: 112px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.block-products-columns__list-item .product-card .product-card__badges {
  position: absolute;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__badges {
  left: 10px;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__badges {
  right: 10px;
}

.block-products-columns__list-item .product-card .product-card__badges>*+* {
  margin-top: 3px;
}

.block-products-columns__list-item .product-card .tag-badge {
  height: 16px;
  padding: 3px 11px 0;
}

.block-products-columns__list-item .product-card .tag-badge:before {
  left: 3px;
  right: 3px;
}

[dir=ltr] .block-products-columns__list-item .product-card .tag-badge:before {
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

[dir=rtl] .block-products-columns__list-item .product-card .tag-badge:before {
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.block-products-columns__list-item .product-card .product-card__actions-list {
  position: absolute;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__actions-list {
  right: 0;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__actions-list {
  left: 0;
}

.block-products-columns__list-item .product-card .product-card__info {
  padding-top: 12px;
  padding-bottom: 40px;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__info {
  padding-right: 10px;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__info {
  padding-left: 10px;
}

.block-products-columns__list-item .product-card .product-card__name {
  font-size: 15px;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__name {
  padding-right: 26px;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__name {
  padding-left: 26px;
}

.block-products-columns__list-item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-products-columns__list-item .product-card .product-card__rating-stars,
.block-products-columns__list-item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__rating-stars {
  margin-left: 7px;
}

.block-products-columns__list-item .product-card .product-card__rating-label {
  line-height: 1;
}

.block-products-columns__list-item .product-card .product-card__prices {
  position: absolute;
  bottom: 14px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__prices {
  left: 112px;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__prices {
  right: 112px;
}

.block-products-columns__list-item .product-card .product-card__price--old {
  font-size: 14px;
}

[dir=ltr] .block-products-columns__list-item .product-card .product-card__price--old {
  margin-left: 6px;
}

[dir=rtl] .block-products-columns__list-item .product-card .product-card__price--old {
  margin-right: 6px;
}

@media (min-width: 1400px) {
  .block-products-columns__list-item .product-card .product-card__image {
    padding: 8px 16px;
    width: 136px;
  }

  .block-products-columns__list-item .product-card .product-card__info {
    padding-top: 14px;
    padding-bottom: 42px;
  }

  .block-products-columns__list-item .product-card .product-card__name {
    font-size: 16px;
    line-height: 20px;
    max-height: 40px;
  }

  .block-products-columns__list-item .product-card .product-card__prices {
    bottom: 16px;
  }

  [dir=ltr] .block-products-columns__list-item .product-card .product-card__prices {
    left: 136px;
  }

  [dir=rtl] .block-products-columns__list-item .product-card .product-card__prices {
    right: 136px;
  }
}

@media (max-width: 1199.98px) {
  .block-products-columns {
    display: none;
  }
}

/*
  // .block-reviews
  */
.block-reviews__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 28px;
}

.block-reviews__subtitle {
  color: #6c757d;
  text-align: center;
  margin-bottom: 28px;
  font-size: 15px;
  margin-top: -24px;
}

.block-reviews__list .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}

.block-reviews__list .owl-dots {
  margin-top: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block-reviews__list .owl-carousel button.owl-dot:before {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.12);
  -webkit-transition: background .2s;
  transition: background .2s;
  margin: 6px;
}

.block-reviews__list .owl-carousel button.owl-dot:focus {
  outline: none;
}

.block-reviews__list .owl-carousel button.owl-dot:hover:before {
  background: rgba(0, 0, 0, 0.22);
}

.block-reviews__list .owl-carousel button.owl-dot.active:before {
  background: #e52727;
}

.block-reviews__item {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  max-width: 690px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-reviews__item-avatar {
  width: 190px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .block-reviews__item-avatar {
  margin-left: 12px;
  margin-right: -12px;
}

[dir=rtl] .block-reviews__item-avatar {
  margin-right: 12px;
  margin-left: -12px;
}

.block-reviews__item-avatar img {
  max-width: 100%;
}

.block-reviews__item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px 32px;
}

.block-reviews__item-text {
  font-size: 15px;
  font-style: italic;
  line-height: 24px;
}

.block-reviews__item-meta {
  margin-top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.block-reviews__item-author {
  color: #6c757d;
  font-size: 14px;
  position: relative;
  line-height: 20px;
}

.block-reviews__item-author:before {
  display: inline-block;
  height: 1px;
  width: 18px;
  background: currentColor;
  content: '';
  opacity: .8;
  position: relative;
  vertical-align: middle;
}

[dir=ltr] .block-reviews__item-author:before {
  left: 0;
  margin-right: 4px;
}

[dir=rtl] .block-reviews__item-author:before {
  right: 0;
  margin-left: 4px;
}

.block-reviews__item-rating {
  padding-bottom: 4px;
}

@media (max-width: 767.98px) {
  .block-reviews__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .block-reviews__item-avatar {
    margin: 36px 36px 0;
    overflow: hidden;
    -ms-flex-item-align: center;
    align-self: center;
    width: 140px;
    border-radius: 70px;
  }

  [dir=ltr] .block-reviews__item-avatar {
    margin-left: 0;
    margin-right: 0;
  }

  [dir=rtl] .block-reviews__item-avatar {
    margin-right: 0;
    margin-left: 0;
  }

  .block-reviews__item-content {
    text-align: center;
  }

  .block-reviews__item-meta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 0;
    padding: 28px 0 20px;
  }

  .block-reviews__item-rating {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-top: 8px;
    padding-bottom: 0;
  }
}

/*
  // .block-sale
  */
.block-sale__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.block-sale__header {
  text-align: center;
  position: relative;
  padding: 14px 32px 0;
  margin: 0 auto -28px;
  z-index: 1;
}

.block-sale__header-decor {
  position: absolute;
  width: 100%;
  bottom: 1px;
  left: 0;
  z-index: -1;
}

.block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end,
.block-sale__header-decor .decor__center {
  background: #fafafa;
}

.block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.block-sale__header-decor .decor__body {
  height: 38px;
  margin: 0 -10px -10px;
}

.block-sale__header-decor .decor__start,
.block-sale__header-decor .decor__end {
  height: 28px;
  width: calc(50% - 26.1658px);
}

.block-sale__header-decor .decor__start {
  left: 10px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

.block-sale__header-decor .decor__end {
  right: 10px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

.block-sale__header-decor .decor__center {
  height: 28px;
  width: 34.3316px;
}

.block-sale__header-decor .decor__start {
  border-bottom-left-radius: 2px;
}

.block-sale__header-decor .decor__end {
  border-bottom-right-radius: 2px;
}

.block-sale__controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.block-sale__link {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 15px;
  line-height: 22px;
  padding: 18px 0;
}

.block-sale__link a {
  color: #6c757d;
  -webkit-transition: color .12s;
  transition: color .12s;
}

.block-sale__link a:hover {
  color: inherit;
}

.block-sale__arrow {
  position: absolute;
  bottom: 3px;
}

.block-sale__arrow .arrow__button {
  height: 31px;
  padding: 0 24.89785px;
}

.block-sale__arrow .arrow__button:before {
  width: calc(100% - 17.89785px);
}

[dir=ltr] .block-sale__arrow .arrow__button:before {
  left: 8.94893px;
}

[dir=rtl] .block-sale__arrow .arrow__button:before {
  right: 8.94893px;
}

[dir=ltr] .block-sale__arrow .arrow__button:before {
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

[dir=rtl] .block-sale__arrow .arrow__button:before {
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

[dir=ltr] .block-sale__arrow--prev {
  left: -49.7957px;
}

[dir=rtl] .block-sale__arrow--prev {
  right: -49.7957px;
}

[dir=ltr] .block-sale__arrow--next .arrow__button:before {
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}

[dir=rtl] .block-sale__arrow--next .arrow__button:before {
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}

[dir=ltr] .block-sale__arrow--next {
  right: -49.7957px;
}

[dir=rtl] .block-sale__arrow--next {
  left: -49.7957px;
}

.block-sale__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
}

.block-sale__subtitle {
  color: #6c757d;
  line-height: 1;
  margin-top: 10px;
}

.block-sale__timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 24px;
}

.block-sale__arrows {
  position: absolute;
  z-index: 1;
  bottom: 3px;
  left: 0;
  width: 100%;
}

.block-sale__body {
  position: relative;
  padding-top: 104px;
  padding-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.block-sale__body-decor {
  position: absolute;
  width: 100%;
  bottom: -1px;
}

.block-sale__body-decor .decor__start,
.block-sale__body-decor .decor__end,
.block-sale__body-decor .decor__center {
  background: #fafafa;
}

.block-sale__body-decor .decor__start,
.block-sale__body-decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.block-sale__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.block-sale__image:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.8);
}

.block-sale__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}

.block-sale__carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-sale__carousel .owl-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.block-sale__carousel .owl-dots {
  margin-top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: -38px;
}

.block-sale__carousel .owl-carousel button.owl-dot:before {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-transition: background .12s;
  transition: background .12s;
  margin: 6px;
}

.block-sale__carousel .owl-carousel button.owl-dot:focus {
  outline: none;
}

.block-sale__carousel .owl-carousel button.owl-dot:hover:before {
  background: rgba(255, 255, 255, 0.5);
}

.block-sale__carousel .owl-carousel button.owl-dot:active:before {
  background: rgba(255, 255, 255, 0.7);
}

.block-sale__carousel .owl-carousel button.owl-dot.active:before {
  background: #e52727;
}

.block-sale__item {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  width: 100%;
}

.block-sale__item .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-radius: 2px;
}

.block-sale__item .product-card .product-card__addtocart-full,
.block-sale__item .product-card .product-card__wishlist,
.block-sale__item .product-card .product-card__compare {
  display: none;
}

.block-sale__item .product-card:after {
  display: block;
  position: static;
  content: '';
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.block-sale__item .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[dir=ltr] .block-sale__item .product-card .product-card__badges {
  left: 16px;
}

[dir=rtl] .block-sale__item .product-card .product-card__badges {
  right: 16px;
}

.block-sale__item .product-card .product-card__badges>*+* {
  margin-top: 3px;
}

.block-sale__item .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

[dir=ltr] .block-sale__item .product-card .product-card__actions-list {
  right: 0;
}

[dir=rtl] .block-sale__item .product-card .product-card__actions-list {
  left: 0;
}

.block-sale__item .product-card .product-card__image {
  display: block;
  position: relative;
}

.block-sale__item .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}

.block-sale__item .product-card .product-card__fit .status-badge__text {
  display: none;
}

.block-sale__item .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}

[dir=ltr] .block-sale__item .product-card .product-card__fit {
  left: calc(16px - 3px);
}

[dir=rtl] .block-sale__item .product-card .product-card__fit {
  right: calc(16px - 3px);
}

.block-sale__item .product-card .product-card__meta {
  padding: 6px 16px;
}

.block-sale__item .product-card .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}

.block-sale__item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-sale__item .product-card .product-card__rating-stars,
.block-sale__item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .block-sale__item .product-card .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .block-sale__item .product-card .product-card__rating-stars {
  margin-left: 7px;
}

.block-sale__item .product-card .product-card__rating-label {
  line-height: 1;
}

.block-sale__item .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}

.block-sale__item .product-card .product-card__footer {
  padding: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.block-sale__item .product-card .product-card__prices {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-sale__item .product-card .product-card__price--old {
  font-size: 14px;
}

[dir=ltr] .block-sale__item .product-card .product-card__price--old {
  margin-left: 6px;
}

[dir=rtl] .block-sale__item .product-card .product-card__price--old {
  margin-right: 6px;
}

.block-sale__item .product-card .product-card__addtocart-icon {
  margin: -3px;
}

@media (hover: hover) {

  .block-sale__item .product-card .product-card__action--wishlist,
  .block-sale__item .product-card .product-card__action--compare {
    -webkit-transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    opacity: 0;
    will-change: transform;
  }

  [dir=ltr] .block-sale__item .product-card .product-card__action--wishlist,
  [dir=ltr] .block-sale__item .product-card .product-card__action--compare {
    -webkit-transform: translateX(100%) translateX(6px);
    transform: translateX(100%) translateX(6px);
  }

  [dir=rtl] .block-sale__item .product-card .product-card__action--wishlist,
  [dir=rtl] .block-sale__item .product-card .product-card__action--compare {
    -webkit-transform: translateX(-100%) translateX(6px);
    transform: translateX(-100%) translateX(6px);
  }

  .block-sale__item .product-card:hover .product-card__action--wishlist,
  .block-sale__item .product-card:hover .product-card__action--compare,
  .block-sale__item .product-card:active .product-card__action--wishlist,
  .block-sale__item .product-card:active .product-card__action--compare {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

[dir=ltr] .block-sale__item .product-card .product-card__image img {
  border-top-left-radius: 2.5px;
  border-top-right-radius: 2.5px;
}

[dir=rtl] .block-sale__item .product-card .product-card__image img {
  border-top-right-radius: 2.5px;
  border-top-left-radius: 2.5px;
}

[dir=ltr] .block-sale__item .product-card .product-card__action:first-child {
  border-top-right-radius: 2.5px;
}

[dir=rtl] .block-sale__item .product-card .product-card__action:first-child {
  border-top-left-radius: 2.5px;
}

@media (max-width: 1399.98px) {
  .block-sale__body {
    padding-top: 96px;
    padding-bottom: 92px;
  }
}

@media (max-width: 1199.98px) {
  .block-sale__body {
    padding-top: 88px;
    padding-bottom: 84px;
  }
}

@media (max-width: 991.98px) {
  .block-sale__body {
    padding-top: 80px;
    padding-bottom: 76px;
  }
}

@media (max-width: 767.98px) {
  .block-sale__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .block-sale__link {
    padding: 16px 24px;
    min-width: 300px;
  }

  .block-sale__controls {
    padding: 0;
    position: relative;
    -ms-flex-item-align: center;
    align-self: center;
    min-width: 220px;
  }
}

@media (max-width: 575.98px) {
  .block-sale__body {
    padding-top: 51px;
    padding-bottom: 28px;
  }

  .block-sale__carousel .owl-dots {
    margin-bottom: 0;
    margin-top: 24px;
  }
}

@media (max-width: 499px) {
  .block-sale__header {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto -23px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .block-sale__header-decor .decor__body {
    height: 33px;
    margin: 0 -10px -10px;
  }

  .block-sale__header-decor .decor__start,
  .block-sale__header-decor .decor__end {
    height: 23px;
    width: calc(50% - 23.27905px);
  }

  .block-sale__header-decor .decor__start {
    left: 10px;
    -webkit-transform: skewX(30deg);
    transform: skewX(30deg);
  }

  .block-sale__header-decor .decor__end {
    right: 10px;
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
  }

  .block-sale__header-decor .decor__center {
    height: 23px;
    width: 28.5581px;
  }

  .block-sale__link {
    padding: 14px 20px;
  }

  .block-sale__arrow .arrow__button {
    height: 27px;
    padding: 0 20.58845px;
  }

  .block-sale__arrow .arrow__button:before {
    width: calc(100% - 15.58845px);
  }

  [dir=ltr] .block-sale__arrow .arrow__button:before {
    left: 7.79423px;
  }

  [dir=rtl] .block-sale__arrow .arrow__button:before {
    right: 7.79423px;
  }

  [dir=ltr] .block-sale__arrow .arrow__button:before {
    -webkit-transform: skewX(30deg);
    transform: skewX(30deg);
  }

  [dir=rtl] .block-sale__arrow .arrow__button:before {
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
  }

  [dir=ltr] .block-sale__arrow--prev {
    left: -42.1769px;
  }

  [dir=rtl] .block-sale__arrow--prev {
    right: -42.1769px;
  }

  [dir=ltr] .block-sale__arrow--next .arrow__button:before {
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
  }

  [dir=rtl] .block-sale__arrow--next .arrow__button:before {
    -webkit-transform: skewX(30deg);
    transform: skewX(30deg);
  }

  [dir=ltr] .block-sale__arrow--next {
    right: -42.1769px;
  }

  [dir=rtl] .block-sale__arrow--next {
    left: -42.1769px;
  }
}

@media (max-width: 474px) {
  .block-sale__title {
    font-size: 28px;
    line-height: 30px;
  }

  .block-sale__subtitle {
    margin-top: 6px;
    font-size: 15px;
  }

  .block-sale__timer {
    margin-top: 16px;
  }

  .block-sale__link {
    padding: 13px 20px;
    min-width: 220px;
    font-size: 14px;
  }
}

/*
  // .block-slideshow
  */
.block-slideshow__item {
  border-radius: 2.5px;
  overflow: hidden;
  height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 84px 100px;
  position: relative;
  z-index: 0;
}

.block-slideshow__item,
.block-slideshow__item:hover {
  color: #262626;
}

.block-slideshow__item-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
}

.block-slideshow__item-image--mobile {
  display: none;
  background-position: top center;
}

.block-slideshow__item-offer {
  background: #ffdf40;
  color: #262626;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  padding: 2px 8px 0;
  margin-bottom: 12px;
  opacity: 0;
  -webkit-transition: opacity .5s .5s,
    -webkit-transform .5s .5s;
  transition: opacity .5s .5s,
    -webkit-transform .5s .5s;
  transition: transform .5s .5s,
    opacity .5s .5s;
  transition: transform .5s .5s,
    opacity .5s .5s,
    -webkit-transform .5s .5s;
}

[dir=ltr] .block-slideshow__item-offer {
  -webkit-transform: translateX(-24px);
  transform: translateX(-24px);
}

[dir=rtl] .block-slideshow__item-offer {
  -webkit-transform: translateX(24px);
  transform: translateX(24px);
}

.owl-item.active .block-slideshow__item-offer {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.block-slideshow__item-title {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  display: block;
  opacity: 0;
  -webkit-transition: opacity .8s .2s;
  transition: opacity .8s .2s;
}

.owl-item.active .block-slideshow__item-title {
  opacity: 1;
}

.block-slideshow__item-details {
  color: #6c757d;
  font-size: 18px;
  line-height: 30px;
  display: block;
  margin-top: 16px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  opacity: 0;
  -webkit-transition: opacity .5s .5s,
    -webkit-transform .5s .5s;
  transition: opacity .5s .5s,
    -webkit-transform .5s .5s;
  transition: transform .5s .5s,
    opacity .5s .5s;
  transition: transform .5s .5s,
    opacity .5s .5s,
    -webkit-transform .5s .5s;
}

.owl-item.active .block-slideshow__item-details {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.block-slideshow__item-button {
  margin-top: 24px;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-size: 18px;
  line-height: 28px;
  padding: 10px 40px;
  border-radius: 2px;
  -webkit-transition: background-color .2s,
    color .2s,
    opacity .5s .5s;
  transition: background-color .2s,
    color .2s,
    opacity .5s .5s;
  background: #e52727;
  color: #fff;
  opacity: 0;
}

.owl-item.active .block-slideshow__item-button {
  opacity: 1;
}

.block-slideshow__item-button:hover {
  background: #333;
  color: #fff;
}

.block-slideshow__item-button:active {
  background: #4d4d4d;
  color: #fff;
}

.block-slideshow__carousel {
  position: relative;
}

.block-slideshow__carousel .owl-dots {
  position: absolute;
  bottom: 26px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: none;
}

.block-slideshow__carousel .owl-carousel button.owl-dot {
  pointer-events: auto;
}

.block-slideshow__carousel .owl-carousel button.owl-dot:focus {
  outline: none;
}

.block-slideshow__carousel .owl-carousel button.owl-dot:before {
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: rgba(204, 204, 204, 0.7);
  margin: 5px;
  -webkit-transition: background .12s;
  transition: background .12s;
}

.block-slideshow__carousel .owl-carousel button.owl-dot:hover:before {
  background: rgba(179, 179, 179, 0.7);
}

.block-slideshow__carousel .owl-carousel button.owl-dot:active:before {
  background: rgba(153, 153, 153, 0.7);
}

.block-slideshow__carousel .owl-carousel button.owl-dot.active:before {
  background: #e52727;
}

@media (max-width: 1399.98px) {
  .block-slideshow__item {
    padding: 68px 80px;
    height: 460px;
  }

  .block-slideshow__item-details {
    margin-top: 12px;
  }
}

@media (max-width: 1199.98px) {
  .block-slideshow__item {
    padding: 60px;
    height: 380px;
  }

  .block-slideshow__item-offer {
    font-size: 24px;
    line-height: 28px;
    padding: 1px 6px 1px;
    margin-bottom: 8px;
  }

  .block-slideshow__item-title {
    font-size: 36px;
    line-height: 44px;
  }

  .block-slideshow__item-details {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
  }

  .block-slideshow__item-button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 28px;
  }

  .block-slideshow__carousel .owl-dots {
    bottom: 22px;
  }
}

@media (max-width: 991.98px) {
  .block-slideshow__item {
    padding: 36px 40px 40px;
    height: 320px;
  }

  .block-slideshow__item-offer {
    margin-bottom: 10px;
  }

  .block-slideshow__item-title {
    font-size: 32px;
    line-height: 38px;
  }

  .block-slideshow__item-button {
    font-size: 15px;
    line-height: 24px;
    padding: 6px 24px;
  }

  .block-slideshow__carousel .owl-dots {
    bottom: 18px;
  }
}

@media (max-width: 767.98px) {
  .block-slideshow__item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    height: 480px;
  }

  .block-slideshow__item-image--desktop {
    display: none;
  }

  .block-slideshow__item-image--mobile {
    display: block;
  }

  .block-slideshow__item-offer {
    -ms-flex-item-align: center;
    align-self: center;
  }

  .block-slideshow__item-details {
    display: none;
  }

  .block-slideshow__item-button {
    -ms-flex-item-align: center;
    align-self: center;
  }

  .block-slideshow__item--dark {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 474px) {
  .block-slideshow__item {
    padding: 28px 32px 32px;
    height: 360px;
  }

  .block-slideshow__item-offer {
    font-size: 20px;
    line-height: 24px;
    padding: 1px 6px 1px;
    margin-bottom: 8px;
  }

  .block-slideshow__item-title {
    font-size: 24px;
    line-height: 30px;
  }

  .block-slideshow__item-button {
    font-size: 14px;
    line-height: 21px;
    padding: 5.5px 20px;
    margin-top: 20px;
  }
}

/*
  // .block-space
  */
@media (min-width: 1200px) {
  .block-space--layout--after-header {
    height: 84px;
  }

  .block-space--layout--after-header.block-space--header--classic {
    height: 72px;
  }
}

@media (max-width: 1199.98px) {
  .block-space--layout--after-header {
    height: 56px;
  }
}

@media (max-width: 767.98px) {
  .block-space--layout--after-header {
    height: 48px;
  }
}

@media (max-width: 575.98px) {
  .block-space--layout--after-header {
    height: 36px;
  }
}

.block-space--layout--before-footer {
  height: 80px;
}

@media (max-width: 1199.98px) {
  .block-space--layout--before-footer {
    height: 72px;
  }
}

@media (max-width: 575.98px) {
  .block-space--layout--before-footer {
    height: 48px;
  }
}

.block-space--layout--divider-xl {
  height: 72px;
}

.block-space--layout--divider-lg {
  height: 60px;
}

.block-space--layout--divider-nl {
  height: 30px;
}

.block-space--layout--divider-sm {
  height: 40px;
}

.block-space--layout--divider-xs {
  height: 36px;
}

.block-space--layout--spaceship-ledge-height {
  height: 24px;
}

@media (max-width: 1199.98px) {
  .block-space--layout--spaceship-ledge-height {
    display: none;
  }
}

/*
  // .block-split
  */
@media (min-width: 1400px) {
  .block-split--has-sidebar .block-split__item-sidebar {
    width: 270px;
  }

  .block-split--has-sidebar .block-split__item-content {
    width: calc(100% - 310px);
  }

  [dir=ltr] .block-split--has-sidebar .block-split__item+.block-split__item {
    margin-left: 40px;
  }

  [dir=rtl] .block-split--has-sidebar .block-split__item+.block-split__item {
    margin-right: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .block-split--has-sidebar .block-split__item-sidebar {
    width: 260px;
  }

  .block-split--has-sidebar .block-split__item-content {
    width: calc(100% - 292px);
  }

  [dir=ltr] .block-split--has-sidebar .block-split__item+.block-split__item {
    margin-left: 32px;
  }

  [dir=rtl] .block-split--has-sidebar .block-split__item+.block-split__item {
    margin-right: 32px;
  }
}

@media (max-width: 991.98px) {
  .block-split__item-sidebar {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-top: 52px;
  }

  .block-split__item-content {
    width: 100%;
  }
}

/*
  // .block-teammates
  */
.block-teammates__title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.block-teammates__subtitle {
  color: #6c757d;
  text-align: center;
  margin-bottom: 32px;
  font-size: 15px;
  margin-top: -36px;
}

.block-teammates__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-teammates__list .owl-stage-outer {
  margin: -10px;
  padding: 10px;
}

.block-teammates__list .owl-dots {
  margin-top: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block-teammates__list .owl-carousel button.owl-dot:before {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.12);
  -webkit-transition: background .2s;
  transition: background .2s;
  margin: 6px;
}

.block-teammates__list .owl-carousel button.owl-dot:focus {
  outline: none;
}

.block-teammates__list .owl-carousel button.owl-dot:hover:before {
  background: rgba(0, 0, 0, 0.22);
}

.block-teammates__list .owl-carousel button.owl-dot.active:before {
  background: #e52727;
}

.block-teammates__item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 240px;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .block-teammates__list {
    padding: 0 10px;
  }
}

/*
  // .block-zone
  */
.block-zone__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__widget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.block-zone__widget-header {
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__widget-body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__tabs {
  margin-bottom: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__tabs-button {
  padding: 7px 16px 6px;
  margin: 0;
  border: none;
  background: transparent;
  font-family: inherit;
  color: inherit;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #ebebeb;
  -webkit-transition: background .12s,
    border-color .12s;
  transition: background .12s,
    border-color .12s;
}

[dir=ltr] .block-zone__tabs-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

[dir=rtl] .block-zone__tabs-button {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.block-zone__tabs-button:focus {
  outline: none;
}

.block-zone__tabs-button:not(.block-zone__tabs-button--active):hover {
  background: #f2f2f2;
  border-color: #e0e0e0;
}

.block-zone__tabs-button--active {
  border-color: #e52727;
}

.block-zone__arrows {
  -ms-flex-item-align: center;
  align-self: center;
}

.block-zone__arrow {
  -ms-flex-item-align: center;
  align-self: center;
}

[dir=ltr] .block-zone__arrow {
  margin-left: auto;
}

[dir=rtl] .block-zone__arrow {
  margin-right: auto;
}

[dir=ltr] .block-zone__arrow--next {
  margin-left: -5.37131px;
}

[dir=rtl] .block-zone__arrow--next {
  margin-right: -5.37131px;
}

.block-zone__carousel {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__carousel .owl-carousel.owl-loaded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__carousel .owl-stage-outer {
  margin: -10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__carousel .owl-item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-zone__carousel-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.block-zone__carousel-item .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.block-zone__carousel-item .product-card .product-card__addtocart-full,
.block-zone__carousel-item .product-card .product-card__wishlist,
.block-zone__carousel-item .product-card .product-card__compare {
  display: none;
}

.block-zone__carousel-item .product-card:after {
  display: block;
  position: static;
  content: '';
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.block-zone__carousel-item .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[dir=ltr] .block-zone__carousel-item .product-card .product-card__badges {
  left: 16px;
}

[dir=rtl] .block-zone__carousel-item .product-card .product-card__badges {
  right: 16px;
}

.block-zone__carousel-item .product-card .product-card__badges>*+* {
  margin-top: 3px;
}

.block-zone__carousel-item .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

[dir=ltr] .block-zone__carousel-item .product-card .product-card__actions-list {
  right: 0;
}

[dir=rtl] .block-zone__carousel-item .product-card .product-card__actions-list {
  left: 0;
}

.block-zone__carousel-item .product-card .product-card__image {
  display: block;
  position: relative;
}

.block-zone__carousel-item .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}

.block-zone__carousel-item .product-card .product-card__fit .status-badge__text {
  display: none;
}

.block-zone__carousel-item .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}

[dir=ltr] .block-zone__carousel-item .product-card .product-card__fit {
  left: calc(16px - 3px);
}

[dir=rtl] .block-zone__carousel-item .product-card .product-card__fit {
  right: calc(16px - 3px);
}

.block-zone__carousel-item .product-card .product-card__meta {
  padding: 6px 16px;
}

.block-zone__carousel-item .product-card .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}

.block-zone__carousel-item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-zone__carousel-item .product-card .product-card__rating-stars,
.block-zone__carousel-item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .block-zone__carousel-item .product-card .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .block-zone__carousel-item .product-card .product-card__rating-stars {
  margin-left: 7px;
}

.block-zone__carousel-item .product-card .product-card__rating-label {
  line-height: 1;
}

.block-zone__carousel-item .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}

.block-zone__carousel-item .product-card .product-card__footer {
  padding: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.block-zone__carousel-item .product-card .product-card__prices {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.block-zone__carousel-item .product-card .product-card__price--old {
  font-size: 14px;
}

[dir=ltr] .block-zone__carousel-item .product-card .product-card__price--old {
  margin-left: 6px;
}

[dir=rtl] .block-zone__carousel-item .product-card .product-card__price--old {
  margin-right: 6px;
}

.block-zone__carousel-item .product-card .product-card__addtocart-icon {
  margin: -3px;
}

@media (hover: hover) {

  .block-zone__carousel-item .product-card .product-card__action--wishlist,
  .block-zone__carousel-item .product-card .product-card__action--compare {
    -webkit-transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    opacity: 0;
    will-change: transform;
  }

  [dir=ltr] .block-zone__carousel-item .product-card .product-card__action--wishlist,
  [dir=ltr] .block-zone__carousel-item .product-card .product-card__action--compare {
    -webkit-transform: translateX(100%) translateX(6px);
    transform: translateX(100%) translateX(6px);
  }

  [dir=rtl] .block-zone__carousel-item .product-card .product-card__action--wishlist,
  [dir=rtl] .block-zone__carousel-item .product-card .product-card__action--compare {
    -webkit-transform: translateX(-100%) translateX(6px);
    transform: translateX(-100%) translateX(6px);
  }

  .block-zone__carousel-item .product-card:hover .product-card__action--wishlist,
  .block-zone__carousel-item .product-card:hover .product-card__action--compare,
  .block-zone__carousel-item .product-card:active .product-card__action--wishlist,
  .block-zone__carousel-item .product-card:active .product-card__action--compare {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.block-zone__carousel-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s,
    visibility 0s .3s;
  transition: opacity .3s,
    visibility 0s .3s;
}

.block-zone__carousel-loader:before {
  position: absolute;
  display: block;
  content: '';
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: #fafafa;
  opacity: .9;
}

.block-zone__carousel-loader:after {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  -webkit-animation-name: loader-animation;
  animation-name: loader-animation;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: '';
}

.block-zone__carousel--loading .block-zone__carousel-loader {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 1;
}

@media (min-width: 1200px) {
  .block-zone__card {
    width: 285px;
  }

  [dir=ltr] .block-zone__card {
    margin-right: 36px;
  }

  [dir=rtl] .block-zone__card {
    margin-left: 36px;
  }

  .block-zone__widget {
    width: calc(100% - 321px);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-zone__card {
    width: 264px;
  }

  [dir=ltr] .block-zone__card {
    margin-right: 28px;
  }

  [dir=rtl] .block-zone__card {
    margin-left: 28px;
  }

  .block-zone__widget {
    width: calc(100% - 292px);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-zone__card {
    width: 232px;
  }

  [dir=ltr] .block-zone__card {
    margin-right: 24px;
  }

  [dir=rtl] .block-zone__card {
    margin-left: 24px;
  }

  .block-zone__widget {
    width: calc(100% - 256px);
  }
}

@media (max-width: 767.98px) {
  .block-zone__body {
    display: block;
  }

  .block-zone__widget {
    margin-top: 16px;
  }

  .block-zone__tabs {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  [dir=ltr] .block-zone__tabs> :first-child {
    margin-left: auto;
  }

  [dir=rtl] .block-zone__tabs> :first-child {
    margin-right: auto;
  }

  [dir=ltr] .block-zone__tabs> :last-child {
    margin-right: auto;
  }

  [dir=rtl] .block-zone__tabs> :last-child {
    margin-left: auto;
  }

  .block-zone__arrow--prev {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  [dir=ltr] .block-zone__arrow--prev .arrow__button:before {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
  }

  [dir=rtl] .block-zone__arrow--prev .arrow__button:before {
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }

  [dir=ltr] .block-zone__arrow--prev {
    margin-left: 0;
    margin-right: auto;
  }

  [dir=rtl] .block-zone__arrow--prev {
    margin-right: 0;
    margin-left: auto;
  }

  [dir=ltr] .block-zone__arrow--next {
    margin-left: 8px;
  }

  [dir=rtl] .block-zone__arrow--next {
    margin-right: 8px;
  }
}

/* ----------------------------------------
  // Widgets
  // ---------------------------------------- */
/*
  // .widget-about-us
  */
.widget-about-us__body {
  padding: 0 1.5rem 1.5rem;
  margin-top: -.25rem;
}

.widget-about-us__text {
  font-size: 16px;
  line-height: 26px;
}

.widget-about-us__social-links {
  margin-top: 16px;
}

/*
  // .widget-categories-list
  */
.widget-categories-list__body {
  padding: 1.5rem;
}

.widget-categories-list__body a {
  color: inherit;
  -webkit-transition: color .1s;
  transition: color .1s;
}

.widget-categories-list__body a:hover {
  color: #cb1818;
}

.widget-categories-list__root {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-categories-list__root-item {
  line-height: 20px;
}

.widget-categories-list__root-item+.widget-categories-list__root-item {
  margin-top: .375rem;
}

.widget-categories-list__root-link {
  font-weight: 500;
  font-size: 17px;
}

.widget-categories-list__root-item--has-children+.widget-categories-list__root-item {
  margin-top: 1.375rem;
}

.widget-categories-list__child {
  list-style: none;
  padding: 0;
  font-size: 15px;
  margin: 10px 0 0;
}

.widget-categories-list__child+.widget-categories-list__child {
  margin-top: 0;
  overflow: hidden;
  height: 0;
  opacity: 0;
  -webkit-transition: height .2s ease-in-out,
    opacity .2s ease-in-out;
  transition: height .2s ease-in-out,
    opacity .2s ease-in-out;
}

.widget-categories-list__child-item {
  padding: 3px 0;
}

.widget-categories-list__show-more {
  position: relative;
  display: inline;
  border: none;
  background: transparent;
  padding: 0;
  color: #999;
  font-size: 14px;
  font-family: inherit;
  margin-top: 4px;
}

[dir=ltr] .widget-categories-list__show-more {
  padding-left: 16px;
}

[dir=rtl] .widget-categories-list__show-more {
  padding-right: 16px;
}

.widget-categories-list__show-more:hover {
  text-decoration: underline;
}

.widget-categories-list__show-more:focus {
  outline: none;
}

.widget-categories-list__show-more-arrow {
  fill: currentColor;
  position: absolute;
  top: 6px;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  opacity: .78;
}

[dir=ltr] .widget-categories-list__show-more-arrow {
  left: 0;
}

[dir=rtl] .widget-categories-list__show-more-arrow {
  right: 0;
}

.widget-categories-list__show-more-arrow svg {
  display: block;
}

.widget-categories-list__show-more-collapse-text {
  display: none;
}

.widget-categories-list--open .widget-categories-list__child+.widget-categories-list__child {
  height: auto;
  opacity: 1;
}

.widget-categories-list--open .widget-categories-list__show-more-expand-text {
  display: none;
}

.widget-categories-list--open .widget-categories-list__show-more-collapse-text {
  display: inline;
}

.widget-categories-list--open .widget-categories-list__show-more-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/*
  // .widget-categories
  */
.widget-categories__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget-categories__item {
  position: relative;
}

.widget-categories__link {
  display: block;
  color: inherit;
  padding: 5px 0;
  -webkit-transition: color .12s;
  transition: color .12s;
}

[dir=ltr] .widget-categories__link {
  margin-right: 34px;
}

[dir=rtl] .widget-categories__link {
  margin-left: 34px;
}

.widget-categories__link:hover {
  color: #cb1818;
}

.widget-categories__expander {
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  -webkit-transition: color .15s,
    background .15s;
  transition: color .15s,
    background .15s;
  background-color: #fff;
  color: #ccc;
}

.widget-categories__expander:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}

.widget-categories__expander:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}

[dir=ltr] .widget-categories__expander {
  right: 0;
}

[dir=rtl] .widget-categories__expander {
  left: 0;
}

.widget-categories__expander:focus {
  outline: none;
}

.widget-categories__expander:before,
.widget-categories__expander:after {
  display: block;
  position: absolute;
  content: '';
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 12px;
}

[dir=ltr] .widget-categories__expander:before,
[dir=ltr] .widget-categories__expander:after {
  left: 8px;
}

[dir=rtl] .widget-categories__expander:before,
[dir=rtl] .widget-categories__expander:after {
  right: 8px;
}

.widget-categories__expander:after {
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.widget-categories__expander:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.widget-categories__container {
  overflow: hidden;
  height: 0;
  opacity: 0;
  -webkit-transition: height .2s ease-in-out,
    opacity .2s ease-in-out;
  transition: height .2s ease-in-out,
    opacity .2s ease-in-out;
}

.widget-categories__container:before,
.widget-categories__container:after {
  display: block;
  content: '';
}

.widget-categories__container:before {
  height: 4px;
}

.widget-categories__container:after {
  height: 12px;
}

.widget-categories__list--root {
  padding: 0 1.5rem 1.5rem;
  font-size: 15px;
  line-height: 20px;
}

[dir=ltr] .widget-categories__list--root>.widget-categories__item {
  padding-left: 16px;
}

[dir=rtl] .widget-categories__list--root>.widget-categories__item {
  padding-right: 16px;
}

.widget-categories__list--root>.widget-categories__item:before {
  position: absolute;
  display: block;
  content: '';
  width: 4px;
  height: 4px;
  background: currentColor;
  opacity: .2;
  top: 13px;
}

[dir=ltr] .widget-categories__list--root>.widget-categories__item:before {
  left: 2px;
}

[dir=rtl] .widget-categories__list--root>.widget-categories__item:before {
  right: 2px;
}

.widget-categories__list--child {
  background: #f5f5f5;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 18px;
}

.widget-categories__list--child .widget-categories__link {
  padding: 5px 0 3px;
}

.widget-categories__item--open .widget-categories__container {
  opacity: 1;
  height: auto;
}

.widget-categories__item--open .widget-categories__expander:after {
  -webkit-transform: none;
  transform: none;
}

/*
  // .widget-comments
  */
.widget-comments__body {
  padding: 0 1.5rem 1.5rem;
}

.widget-comments__body a {
  color: inherit;
  -webkit-transition: color .15s;
  transition: color .15s;
}

.widget-comments__body a:hover {
  color: #cb1818;
}

.widget-comments__list {
  list-style: none;
  margin: 0;
  padding: 0 0 2px;
}

.widget-comments__item+.widget-comments__item {
  margin-top: 20px;
}

.widget-comments__author {
  font-size: 15px;
  line-height: 18px;
}

.widget-comments__author a {
  border-bottom: 2px solid #ebebeb;
  -webkit-transition: border .15s;
  transition: border .15s;
}

.widget-comments__author a:hover {
  border-color: rgba(203, 24, 24, 0.8);
}

.widget-comments__content {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20px;
}

.widget-comments__meta {
  margin-top: 4px;
  font-size: 13px;
  color: #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
}

.widget-comments__date {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.widget-comments__name {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

[dir=ltr] .widget-comments__name {
  padding-left: 20px;
}

[dir=rtl] .widget-comments__name {
  padding-right: 20px;
}

.widget-comments__name:before {
  position: absolute;
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 2px;
  top: 8px;
  opacity: .8;
}

[dir=ltr] .widget-comments__name:before {
  left: 8px;
}

[dir=rtl] .widget-comments__name:before {
  right: 8px;
}

/*
  // .widget-filters
  */
.widget-filters__item {
  padding: 8px 1.5rem;
  border-bottom: 1px solid #ebebeb;
}

.white-bg {
  background: #fff;
}

.widget-filters__item:first-child {
  border-top: 1px solid #ebebeb;
}

.widget-filters__actions {
  padding: 20px 1.5rem 1.5rem;
}

[dir=ltr] .widget-filters__actions>*+* {
  margin-left: 8px;
}

[dir=rtl] .widget-filters__actions>*+* {
  margin-right: 8px;
}

.widget-filters--offcanvas--none {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

@media (min-width: 992px) {
  .widget-filters--offcanvas--mobile {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
}

@media (max-width: 991.98px) {
  .widget-filters--offcanvas--mobile .widget-filters__header {
    display: none;
  }

  .widget-filters--offcanvas--mobile .widget-filters__item {
    padding: 8px 20px;
  }

  .widget-filters--offcanvas--mobile .widget-filters__item:first-child {
    border-top: none;
  }
}

.widget-filters--offcanvas--always .widget-filters__header {
  display: none;
}

.widget-filters--offcanvas--always .widget-filters__item {
  padding: 8px 20px;
}

.widget-filters--offcanvas--always .widget-filters__item:first-child {
  border-top: none;
}

/*
  // .widget-newsletter
  */
.widget-newsletter {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  background: #333;
  border-radius: 2px;
  padding: 2.25rem 2.5rem 2.5rem;
  color: #fff;
  text-align: center;
}

.widget-newsletter__title {
  font-size: 24px;
  letter-spacing: .02em;
  position: relative;
  margin-bottom: 1.375rem;
  padding-bottom: 1.25rem;
}

.widget-newsletter__title:after {
  position: absolute;
  display: block;
  content: '';
  height: 1px;
  width: 56px;
  background: #4d4d4d;
  bottom: 0;
}

[dir=ltr] .widget-newsletter__title:after {
  left: calc(50% - 28px);
}

[dir=rtl] .widget-newsletter__title:after {
  right: calc(50% - 28px);
}

.widget-newsletter__title h4 {
  margin-bottom: 0;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .widget-newsletter__title h4 {
    font-weight: 400;
  }
}

.widget-newsletter__text {
  font-size: 15px;
  color: #9e9e9e;
  line-height: 24px;
  margin-bottom: 1.625rem;
}

.widget-newsletter__email {
  display: block;
  width: 100%;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  height: 38px;
  padding: 0 12px;
  font-size: 15px;
  font-family: inherit;
  background-clip: padding-box;
  -webkit-transition: border .2s,
    background .2s;
  transition: border .2s,
    background .2s;
  color: #fff;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}

.widget-newsletter__email::-webkit-input-placeholder {
  color: #b2b2b2;
}

.widget-newsletter__email::-moz-placeholder {
  color: #b2b2b2;
}

.widget-newsletter__email:-ms-input-placeholder {
  color: #b2b2b2;
}

.widget-newsletter__email::-ms-input-placeholder {
  color: #b2b2b2;
}

.widget-newsletter__email::placeholder {
  color: #b2b2b2;
}

.widget-newsletter__email::-webkit-input-placeholder {
  -webkit-transition: color .2s;
  transition: color .2s;
}

.widget-newsletter__email::-moz-placeholder {
  -moz-transition: color .2s;
  transition: color .2s;
}

.widget-newsletter__email:-ms-input-placeholder {
  -ms-transition: color .2s;
  transition: color .2s;
}

.widget-newsletter__email::-ms-input-placeholder {
  -ms-transition: color .2s;
  transition: color .2s;
}

.widget-newsletter__email::placeholder {
  -webkit-transition: color .2s;
  transition: color .2s;
}

.widget-newsletter__email:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}

.widget-newsletter__email:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}

.widget-newsletter__email:focus::-webkit-input-placeholder {
  color: #9e9e9e;
}

.widget-newsletter__email:focus::-moz-placeholder {
  color: #9e9e9e;
}

.widget-newsletter__email:focus:-ms-input-placeholder {
  color: #9e9e9e;
}

.widget-newsletter__email:focus::-ms-input-placeholder {
  color: #9e9e9e;
}

.widget-newsletter__email:focus::placeholder {
  color: #9e9e9e;
}

.widget-newsletter__button {
  border: none;
  font-size: 15px;
  border-radius: 2px;
  padding: .5rem 1.3125rem;
  margin-top: 1.25rem;
  width: 100%;
  -webkit-transition: background .2s,
    color .2s;
  transition: background .2s,
    color .2s;
  background-color: #e52727;
  color: #fff;
}

.widget-newsletter__button:hover {
  background-color: #545454;
  color: #fff;
}

.widget-newsletter__button:active {
  background-color: #4c4c4c;
  color: #fff;
}

.widget-newsletter__button:active {
  -webkit-transition-duration: .1s, .1s;
  transition-duration: .1s, .1s;
}

.widget-newsletter__button:focus {
  outline: none;
}

/*
  // .widget-posts
  */
.widget-posts__list {
  list-style: none;
  padding: 0 1.5rem 1.5rem;
  margin: 0;
}

.widget-posts__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.widget-posts__item:hover .widget-posts__name {
  color: #cb1818;
}

.widget-posts__item+.widget-posts__item {
  margin-top: 1rem;
}

.widget-posts__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

[dir=ltr] .widget-posts__image {
  margin-right: 14px;
}

[dir=rtl] .widget-posts__image {
  margin-left: 14px;
}

.widget-posts__image img {
  display: block;
  border-radius: 1.5px;
}

.widget-posts__image:before {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.2);
  border-radius: 1.5px;
  opacity: 0;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  pointer-events: none;
  height: fit-content;
}

[dir=ltr] .widget-posts__image:before {
  left: 0;
  right: 0;
}

[dir=rtl] .widget-posts__image:before {
  right: 0;
  left: 0;
}

.widget-posts__image:hover:before {
  opacity: 1;
}

.widget-posts__info {
  padding-top: 5px;
  max-width: 180px;
}

.widget-posts__name {
  font-size: 15px;
  line-height: 19px;
  overflow: hidden;
  max-height: 38px;
}

.widget-posts__name a {
  color: inherit;
  -webkit-transition: color .12s;
  transition: color .12s;
}

.widget-posts__name a:hover {
  color: #cb1818;
}

.widget-posts__date {
  font-size: 13px;
  color: #999;
  margin-top: 3px;
  position: relative;
}

[dir=ltr] .widget-posts__date {
  padding-left: 22px;
}

[dir=rtl] .widget-posts__date {
  padding-right: 22px;
}

.widget-posts__date:before {
  position: absolute;
  display: block;
  content: '';
  height: 1px;
  width: 16px;
  background: currentColor;
  opacity: .6;
  top: 10px;
}

[dir=ltr] .widget-posts__date:before {
  left: 0;
}

[dir=rtl] .widget-posts__date:before {
  right: 0;
}

/*
  // .widget-products
  */
.widget-products__list {
  padding-bottom: 1.5rem;
}

.widget-products__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.5rem;
}

.widget-products__item+.widget-products__item {
  margin-top: 12px;
}

.widget-products__image {
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 64px;
}

[dir=ltr] .widget-products__image {
  margin-right: 12px;
}

[dir=rtl] .widget-products__image {
  margin-left: 12px;
}

.widget-products__image:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: inherit;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 1;
}

.widget-products__info {
  padding-top: 2px;
}

.widget-products__name {
  font-size: 14px;
  line-height: 17px;
  height: 34px;
  overflow: hidden;
}

.widget-products__name a {
  color: inherit;
  -webkit-transition: color .12s;
  transition: color .12s;
}

.widget-products__name a:hover {
  color: #cb1818;
}

.widget-products__prices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2px;
}

.widget-products__price {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
}

[dir=ltr] .widget-products__price+.widget-products__price {
  margin-left: 3px;
}

[dir=rtl] .widget-products__price+.widget-products__price {
  margin-right: 3px;
}

.widget-products__price--new {
  color: #262626;
}

.widget-products__price--old {
  font-weight: 400;
  font-size: 13px;
  text-decoration: line-through;
  color: #999;
  padding-top: 1px;
}

/*
  // .widget-search
  */
.widget-search__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 0;
}

.widget-search__input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 15.5px 24px;
  font-family: inherit;
  font-size: 16px;
  line-height: 21px;
}

.widget-search__input:focus {
  outline: none;
}

.widget-search__button {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border: none;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #bfbfbf;
  fill: currentColor;
  width: 52px;
  height: 52px;
  padding: 0;
}

.widget-search__button:focus {
  outline: none;
}

.widget-search__button:hover {
  color: #a6a6a6;
  background: #f5f5f5;
}

.widget-search__button:active {
  color: #a6a6a6;
  background: #f0f0f0;
}

.widget-search__field {
  position: absolute;
  display: block;
  content: '';
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 1px;
  -webkit-transition: -webkit-box-shadow .2s;
  transition: -webkit-box-shadow .2s;
  transition: box-shadow .2s;
  transition: box-shadow .2s, -webkit-box-shadow .2s;
}

.widget-search__form:hover .widget-search__field {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.widget-search__input:focus~.widget-search__field {
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

/*
  // .widget-tags
  */
.widget-tags__body {
  padding: 0 1.5rem 1.5rem;
}

/* ----------------------------------------
  // Shop
  // ---------------------------------------- */
/*
  // .analogs-table
  */
.analogs-table__rating {
  display: inline-block;
  vertical-align: middle;
}

.analogs-table__rating-stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.analogs-table__rating-label {
  color: #6c757d;
  font-size: 13px;
  line-height: 1;
}

.analogs-table__sku,
.analogs-table__country {
  color: #6c757d;
  font-size: 13px;
  line-height: 1;
  margin-top: 1px;
  margin-bottom: 4px;
}

.analogs-table__product-name {
  color: inherit;
  -webkit-transition: color .12s;
  transition: color .12s;
}

.analogs-table__product-name:hover {
  color: #cb1818;
}

@media (min-width: 768px) {
  .analogs-table {
    width: 100%;
    font-size: 15px;
  }

  .analogs-table th,
  .analogs-table td {
    padding: 0;
  }

  [dir=ltr] .analogs-table th:first-child,
  [dir=ltr] .analogs-table td:first-child {
    padding-left: 14px;
  }

  [dir=rtl] .analogs-table th:first-child,
  [dir=rtl] .analogs-table td:first-child {
    padding-right: 14px;
  }

  [dir=ltr] .analogs-table th:last-child,
  [dir=ltr] .analogs-table td:last-child {
    padding-right: 14px;
  }

  [dir=rtl] .analogs-table th:last-child,
  [dir=rtl] .analogs-table td:last-child {
    padding-left: 14px;
  }

  [dir=ltr] .analogs-table th:not(:first-child),
  [dir=ltr] .analogs-table td:not(:first-child) {
    padding-left: 16px;
  }

  [dir=rtl] .analogs-table th:not(:first-child),
  [dir=rtl] .analogs-table td:not(:first-child) {
    padding-right: 16px;
  }

  .analogs-table thead {
    font-size: 13px;
    text-transform: uppercase;
  }

  .analogs-table thead th,
  .analogs-table thead td {
    padding-bottom: 6px;
  }

  .analogs-table tbody th,
  .analogs-table tbody td {
    border-top: 1px solid #ebebeb;
    padding: 10px 0;
  }

  [dir=ltr] .analogs-table tbody th:first-child,
  [dir=ltr] .analogs-table tbody td:first-child {
    border-left: 1px solid #ebebeb;
  }

  [dir=rtl] .analogs-table tbody th:first-child,
  [dir=rtl] .analogs-table tbody td:first-child {
    border-right: 1px solid #ebebeb;
  }

  [dir=ltr] .analogs-table tbody th:last-child,
  [dir=ltr] .analogs-table tbody td:last-child {
    border-right: 1px solid #ebebeb;
  }

  [dir=rtl] .analogs-table tbody th:last-child,
  [dir=rtl] .analogs-table tbody td:last-child {
    border-left: 1px solid #ebebeb;
  }

  .analogs-table tbody tr:last-child th,
  .analogs-table tbody tr:last-child td {
    border-bottom: 1px solid #ebebeb;
  }

  .analogs-table tbody tr:hover th,
  .analogs-table tbody tr:hover td {
    background: #f7f7f7;
  }

  .analogs-table__column--rating {
    text-align: center;
  }

  .analogs-table__column--vendor {
    text-align: center;
  }

  [dir=ltr] .analogs-table__column--price {
    text-align: right;
  }

  [dir=rtl] .analogs-table__column--price {
    text-align: left;
  }

  .analogs-table__rating-label {
    margin-top: 6px;
  }
}

@media (max-width: 767.98px) {
  .analogs-table {
    display: block;
    font-size: 15px;
  }

  .analogs-table thead {
    display: none;
  }

  .analogs-table tbody {
    display: block;
  }

  .analogs-table tr,
  .analogs-table td {
    display: block;
  }

  .analogs-table tr {
    padding: 14px 16px;
    background: #f5f5f5;
    border-radius: 2px;
  }

  .analogs-table tr+tr {
    margin-top: 12px;
  }

  .analogs-table td {
    padding: 0;
  }

  .analogs-table .analogs-table__column--rating {
    margin-top: 11px;
  }

  .analogs-table .analogs-table__column--vendor {
    font-size: 13px;
    padding-top: 8px;
    margin-top: 12px;
    border-top: 1px solid #e5e5e5;
  }

  .analogs-table .analogs-table__column--vendor:before {
    content: attr(data-title) ": ";
  }

  .analogs-table .analogs-table__column--price {
    font-weight: 500;
    margin-top: 16px;
  }

  .analogs-table .analogs-table__sku {
    margin: 2px 0 0;
  }

  .analogs-table .analogs-table__sku:before {
    content: attr(data-title) ": ";
  }

  .analogs-table .analogs-table__rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  [dir=ltr] .analogs-table .analogs-table__rating-label {
    margin-left: 8px;
  }

  [dir=rtl] .analogs-table .analogs-table__rating-label {
    margin-right: 8px;
  }

  .analogs-table .analogs-table__country {
    display: inline;
  }
}

/*
  // .applied-filters
  */
.applied-filters__list {
  list-style: none;
  padding: 0;
  margin: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.applied-filters__item {
  margin: 2px;
}

.applied-filters__button {
  padding: 4px 11px;
  display: block;
  height: 21px;
  font-size: 13px;
  line-height: 1;
  color: inherit;
  background-color: #f0f0f0;
  -webkit-transition: background .12s,
    color .12s;
  transition: background .12s,
    color .12s;
  border-radius: 11.5px;
  position: relative;
  border: none;
}

.applied-filters__button:focus {
  outline: none;
}

.applied-filters__button:hover {
  background-color: #e5e5e5;
  color: inherit;
}

[dir=ltr] .applied-filters__button--filter {
  padding-right: 16px;
  padding-left: 15px;
  padding-bottom: 16px;
}

[dir=rtl] .applied-filters__button--filter {
  padding-left: 33px;
}

.applied-filters__button--filter:after {
  /* content: '';
    display: block;
    position: absolute;
    width: 1px;
    top: 4px;
    bottom: 4px;
    background-color: rgba(0, 0, 0, 0.15); */
}

[dir=ltr] .applied-filters__button--filter:after {
  /* right: 25px; */
}

[dir=rtl] .applied-filters__button--filter:after {
  /* left: 25px; */
}

.applied-filters__button--filter svg {
  position: absolute;
  top: 6px;
  fill: rgba(0, 0, 0, 0.4);
}

[dir=ltr] .applied-filters__button--filter svg {
  right: 10px;
}

[dir=rtl] .applied-filters__button--filter svg {
  left: 10px;
}

.applied-filters__button--clear {
  background: transparent;
  color: #999;
}

/*
  // .cart
  */
.cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cart__table {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.cart__totals {
  width: 360px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media only screen and (min-width:1400px) {
  [dir=ltr] .cart__totals {
    margin-left: 50px;
  }
}

[dir=rtl] .cart__totals {
  margin-right: 50px;
}

.cart__totals-table {
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
}

.cart__totals-table a:hover {
  text-decoration: underline;
}

.cart__totals-table,
.cart__totals-table thead,
.cart__totals-table tbody,
.cart__totals-table tfoot,
.cart__totals-table tr,
.cart__totals-table th,
.cart__totals-table td {
  display: block;
}

.cart__totals-table tr:after {
  display: block;
  content: '';
  clear: both;
}

.cart__totals-table th,
.cart__totals-table td {
  padding: 0;
}

[dir=ltr] .cart__totals-table th {
  float: left;
}

[dir=rtl] .cart__totals-table th {
  float: right;
}

[dir=ltr] .cart__totals-table td {
  text-align: right;
}

[dir=rtl] .cart__totals-table td {
  text-align: left;
}

.cart__totals-table thead,
.cart__totals-table tbody {
  line-height: 20px;
}

.cart__totals-table tbody tr>* {
  padding-top: 8px;
}

.cart__totals-table tbody tr:last-child>* {
  padding-bottom: 10px;
}

.cart__totals-table tfoot {
  font-size: 20px;
}

.cart__totals-table tfoot th {
  font-weight: 500;
}

.cart__totals-table tfoot td,
.cart__totals-table tfoot th {
  padding-top: 12px;
  border-top: 1px solid #ebebeb;
}

.cart__totals-table tfoot td {
  letter-spacing: -.02em;
}

@media (max-width: 1399.98px) {
  .cart {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cart__table {
    width: 100%;
  }

  .cart__totals {
    margin-top: 40px;
    width: 400px;
  }

  [dir=ltr] .cart__totals {
    /* margin-left: auto; */
  }

  [dir=rtl] .cart__totals {
    margin-right: auto;
  }
}

@media (max-width: 991.98px) {
  .cart__totals {
    width: 360px;
  }

  [dir=ltr] .cart__totals {
    margin-left: 0;
  }

  [dir=rtl] .cart__totals {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .cart__totals {
    width: 100%;
  }
}

/*
  // .cart-table
  */
.cart-table__table {
  width: 100%;
  border-spacing: 0;
}

.cart-table__head {
  font-size: 13px;
  text-transform: uppercase;
}

.cart-table__column--product {
  line-height: 1.25;
}

.cart-table__column--price {
  width: 130px;
}

[dir=ltr] .cart-table__column--price {
  text-align: right;
}

[dir=rtl] .cart-table__column--price {
  text-align: left;
}

.cart-table__column--quantity {
  width: 150px;
  text-align: center;
}

[dir=ltr] .cart-table__column--quantity {
  padding-left: 36px;
}

[dir=rtl] .cart-table__column--quantity {
  padding-right: 36px;
}

.cart-table__column--total {
  width: 130px;
}

[dir=ltr] .cart-table__column--total {
  text-align: right;
}

[dir=rtl] .cart-table__column--total {
  text-align: left;
}

.cart-table__column--remove {
  width: 1px;
  white-space: nowrap;
}

[dir=ltr] .cart-table__column--remove {
  padding-left: 0;
}

[dir=rtl] .cart-table__column--remove {
  padding-right: 0;
}

.cart-table__product-name {
  color: inherit;
  -webkit-transition: color .15s;
  transition: color .15s;
}

.cart-table__product-name:hover {
  color: #cb1818;
}

.cart-table__options {
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 1.375;
  color: #6c757d;
}

@media (min-width: 992px) {
  .cart-table {
    -ms-flex-item-align: start;
    align-self: flex-start;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .cart-table__foot td {
    padding: 20px 24px;
  }

  .cart-table__column {
    padding: 14px 16px;
    border-bottom: 1px solid #ebebeb;
  }

  [dir=ltr] .cart-table__column:first-child {
    padding-left: 28px;
  }

  [dir=rtl] .cart-table__column:first-child {
    padding-right: 28px;
  }

  [dir=ltr] .cart-table__column:last-child {
    padding-right: 28px;
  }

  [dir=rtl] .cart-table__column:last-child {
    padding-left: 28px;
  }

  .cart-table__column--image {
    width: 1px;
    text-align: center;
  }

  .cart-table__column--image .image {
    width: 80px;
  }

  .cart-table__options li {
    position: relative;
  }

  [dir=ltr] .cart-table__options li {
    padding-left: 12px;
  }

  [dir=rtl] .cart-table__options li {
    padding-right: 12px;
  }

  .cart-table__options li:before {
    display: block;
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: currentColor;
    top: 7px;
    opacity: .7;
  }

  [dir=ltr] .cart-table__options li:before {
    left: 2px;
  }

  [dir=rtl] .cart-table__options li:before {
    right: 2px;
  }

  [dir=ltr] .cart-table__remove {
    margin-right: -12px;
  }

  [dir=rtl] .cart-table__remove {
    margin-left: -12px;
  }

  .cart-table__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cart-table__coupon-form {
    width: 360px;
  }
}

@media (max-width: 991.98px) {
  .cart-table__table {
    display: block;
  }

  .cart-table__head {
    display: none;
  }

  .cart-table__column {
    display: block;
  }

  .cart-table__column {
    padding: 0;
  }

  .cart-table__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px;
  }

  .cart-table__row {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    margin: 8px;
    width: calc(100% / 3 - 16px);
  }

  .cart-table__column--image {
    text-align: center;
    padding: 16px 14px;
  }

  .cart-table__column--image .image {
    max-width: 220px;
    margin: 0 auto;
  }

  .cart-table__column--product {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    padding: 0 14px 16px;
  }

  .cart-table__column--price,
  .cart-table__column--quantity,
  .cart-table__column--total {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid #ebebeb;
    padding: 10px 14px;
    font-size: 15px;
  }

  [dir=ltr] .cart-table__column--price,
  [dir=ltr] .cart-table__column--quantity,
  [dir=ltr] .cart-table__column--total {
    padding-left: 14px;
  }

  [dir=rtl] .cart-table__column--price,
  [dir=rtl] .cart-table__column--quantity,
  [dir=rtl] .cart-table__column--total {
    padding-right: 14px;
  }

  .cart-table__column--price:before,
  .cart-table__column--quantity:before,
  .cart-table__column--total:before {
    content: attr(data-title) ":";
    display: block;
    width: calc(40% - 6px);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-weight: 500;
  }

  [dir=ltr] .cart-table__column--price:before,
  [dir=ltr] .cart-table__column--quantity:before,
  [dir=ltr] .cart-table__column--total:before {
    margin-right: 12px;
    text-align: left;
  }

  [dir=rtl] .cart-table__column--price:before,
  [dir=rtl] .cart-table__column--quantity:before,
  [dir=rtl] .cart-table__column--total:before {
    margin-left: 12px;
    text-align: right;
  }

  .cart-table__remove {
    position: absolute;
    top: 0;
  }

  [dir=ltr] .cart-table__remove {
    right: 0;
  }

  [dir=rtl] .cart-table__remove {
    left: 0;
  }

  .cart-table__foot,
  .cart-table__foot tr,
  .cart-table__foot td {
    display: block;
    padding: 0;
  }

  .cart-table__actions {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cart-table__coupon-form {
    padding: 16px;
  }

  .cart-table__update-button {
    padding: 16px;
  }

  .cart-table__quantity {
    max-width: 120px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .cart-table__coupon-form {
    width: 60%;
  }
}

@media (max-width: 767.98px) {
  .cart-table__row {
    width: calc(50% - 16px);
  }

  .cart-table__update-button {
    border-top: 1px solid #ebebeb;
  }
}

@media (max-width: 767.98px) {
  .cart-table__actions {
    display: block;
  }

  .cart-table__update-button {
    border-top: 1px solid #ebebeb;
    text-align: center;
  }
}

@media (max-width: 474px) {
  .cart-table__row {
    width: 100%;
  }
}

/*
  // .categories-list
  */
.categories-list__body {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.categories-list__item {
  padding: 1.125rem;
  text-align: center;
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.categories-list__item a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: inherit;
}

.categories-list__item .image {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.categories-list__item:before {
  position: absolute;
  display: block;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.03);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .12s;
  transition: opacity .12s;
  z-index: 1;
}

.categories-list__item:hover:before {
  opacity: 1;
}

.categories-list__item-products {
  margin-top: 4px;
  font-size: 14px;
  color: #6c757d;
}

.categories-list__divider {
  width: 1px;
  background-color: #ebebeb;
}

@media (min-width: 576px) {
  .categories-list--layout--columns-3-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-3-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-3-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-3-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-3-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-3-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 3px) / 4);
  }

  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1400px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 4px) / 5);
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(10n) {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 3px) / 4);
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 992px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }

  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 4px) / 5);
  }

  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(10n) {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }

  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 5px) / 6);
  }

  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(12n) {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }

  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1400px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 6px) / 7);
  }

  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(14n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(14n) {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 5px) / 6);
  }

  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(12n) {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }

  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }

  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }

  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }

  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

/*
  // .category-card
  */
.category-card__body {
  position: relative;
}

.category-card__overlay-image {
  display: none;
}

.category-card--layout--classic .category-card__body {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
}

.category-card--layout--classic .category-card__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.category-card--layout--classic .category-card__image {
  width: 160px;
  padding: 24px 0;
  margin: 0 32px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.category-card--layout--classic .category-card__info {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 24px;
}

[dir=ltr] .category-card--layout--classic .category-card__info {
  padding-left: 0;
  padding-right: 28px;
}

[dir=rtl] .category-card--layout--classic .category-card__info {
  padding-right: 0;
  padding-left: 28px;
}

.category-card--layout--classic .category-card__name a,
.category-card--layout--classic .category-card__children a {
  -webkit-transition: color .12s;
  transition: color .12s;
}

.category-card--layout--classic .category-card__name a:hover,
.category-card--layout--classic .category-card__children a:hover {
  color: #cb1818;
}

.category-card--layout--classic .category-card__name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.category-card--layout--classic .category-card__name a {
  color: inherit;
}

.category-card--layout--classic .category-card__children {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  font-size: 15px;
}

.category-card--layout--classic .category-card__children li {
  position: relative;
  color: #6c757d;
  padding-top: 1px;
  padding-bottom: 1px;
}

[dir=ltr] .category-card--layout--classic .category-card__children li {
  padding-left: 11px;
  padding-right: 0;
}

[dir=rtl] .category-card--layout--classic .category-card__children li {
  padding-right: 11px;
  padding-left: 0;
}

.category-card--layout--classic .category-card__children li:before {
  display: block;
  position: absolute;
  content: '';
  width: 3px;
  height: 3px;
  background: currentColor;
  opacity: .7;
  top: 10px;
}

[dir=ltr] .category-card--layout--classic .category-card__children li:before {
  left: 0;
}

[dir=rtl] .category-card--layout--classic .category-card__children li:before {
  right: 0;
}

.category-card--layout--classic .category-card__children a {
  color: inherit;
}

.category-card--layout--classic .category-card__actions {
  margin-top: 8px;
}

.category-card--layout--classic .category-card__link {
  font-size: 14px;
  font-weight: 500;
  color: #cb1818;
}

.category-card--layout--classic .category-card__link:hover {
  text-decoration: underline;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .category-card--layout--classic .category-card__image {
    width: 120px;
    margin: 0 26px;
  }
}

@media (max-width: 991px) and (min-width: 475px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
    margin: 0 26px;
    padding: 20px 0;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .category-card--layout--classic .category-card__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .category-card--layout--classic .category-card__info {
    padding-top: 0;
    padding-bottom: 20px;
  }

  [dir=ltr] .category-card--layout--classic .category-card__info {
    padding-left: 26px;
    padding-right: 26px;
  }

  [dir=rtl] .category-card--layout--classic .category-card__info {
    padding-right: 26px;
    padding-left: 26px;
  }
}

@media (max-width: 474px) and (min-width: 360px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
  }
}

@media (max-width: 474px) and (min-width: 360px) and (max-width: 420px) {
  .category-card--layout--classic .category-card__image {
    width: 100px;
    margin: 0 28px;
  }
}

@media (max-width: 359px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
    margin: 0 26px;
    padding: 24px 0;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .category-card--layout--classic .category-card__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .category-card--layout--classic .category-card__info {
    padding-top: 0;
    padding-bottom: 24px;
  }

  [dir=ltr] .category-card--layout--classic .category-card__info {
    padding-left: 26px;
    padding-right: 26px;
  }

  [dir=rtl] .category-card--layout--classic .category-card__info {
    padding-right: 26px;
    padding-left: 26px;
  }
}

.category-card--layout--overlay .category-card__body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 0;
  overflow: hidden;
  border-radius: 2.5px;
  padding: 32px 20px 40px;
}

.category-card--layout--overlay .category-card__body:before {
  position: absolute;
  display: block;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(26, 26, 26, 0.6);
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(20%, rgba(26, 26, 26, 0.4)), color-stop(80%, rgba(26, 26, 26, 0)));
  background-image: linear-gradient(to top, rgba(26, 26, 26, 0.4) 20%, rgba(26, 26, 26, 0) 80%);
  z-index: -1;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
}

.category-card--layout--overlay .category-card__overlay-image {
  display: block;
  position: absolute;
  z-index: -3;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

.category-card--layout--overlay .category-card__overlay-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.category-card--layout--overlay .category-card__overlay-image--blur {
  z-index: -2;
  opacity: 0;
  -webkit-transition: opacity .3s ease-out,
    -webkit-transform .3s ease-out;
  transition: opacity .3s ease-out,
    -webkit-transform .3s ease-out;
  transition: transform .3s ease-out,
    opacity .3s ease-out;
  transition: transform .3s ease-out,
    opacity .3s ease-out,
    -webkit-transform .3s ease-out;
}

.category-card--layout--overlay .category-card__overlay-image--blur img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.category-card--layout--overlay .category-card__content,
.category-card--layout--overlay .category-card__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.category-card--layout--overlay .category-card__content {
  color: #fff;
}

.category-card--layout--overlay .category-card__name,
.category-card--layout--overlay .category-card__children {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.category-card--layout--overlay .category-card__name a,
.category-card--layout--overlay .category-card__children a {
  color: inherit;
  -webkit-transition: color .12s;
  transition: color .12s;
}

.category-card--layout--overlay .category-card__name a:hover,
.category-card--layout--overlay .category-card__children a:hover {
  color: #ffdf40;
}

.category-card--layout--overlay .category-card__name {
  font-size: 24px;
  margin-bottom: 20px;
}

.category-card--layout--overlay .category-card__children {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  list-style: none;
  padding: 0;
  font-size: 16px;
  margin: 0 0 28px;
  opacity: 1;
}

.category-card--layout--overlay .category-card__children li {
  padding: 5px 0 6px;
}

.category-card--layout--overlay:hover .category-card__overlay-image {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.category-card--layout--overlay:hover .category-card__overlay-image--blur {
  opacity: 1;
}

.category-card--layout--overlay:hover .category-card__body:before {
  background-color: rgba(26, 26, 26, 0.7);
}

@media (max-width: 767.98px) {
  .category-card--layout--overlay .category-card__body {
    padding: 22px 28px 28px;
  }

  .category-card--layout--overlay .category-card__content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .category-card--layout--overlay .category-card__info {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .category-card--layout--overlay .category-card__name {
    margin-bottom: 10px;
    font-size: 22px;
  }

  .category-card--layout--overlay .category-card__children {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 24px;
  }

  [dir=ltr] .category-card--layout--overlay .category-card__children {
    text-align: left;
  }

  [dir=rtl] .category-card--layout--overlay .category-card__children {
    text-align: right;
  }

  .category-card--layout--overlay .category-card__children li {
    padding: 1px 0;
  }

  [dir=ltr] .category-card--layout--overlay .category-card__children li {
    margin-right: 16px;
  }

  [dir=rtl] .category-card--layout--overlay .category-card__children li {
    margin-left: 16px;
  }
}

/*
  // .checkout
  */
.checkout__totals {
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  border-spacing: 0;
  margin-bottom: 40px;
}

.checkout__totals th,
.checkout__totals td {
  padding: 0;
}

[dir=ltr] .checkout__totals th:last-child,
[dir=ltr] .checkout__totals td:last-child {
  padding-left: 20px;
  text-align: right;
}

[dir=rtl] .checkout__totals th:last-child,
[dir=rtl] .checkout__totals td:last-child {
  padding-right: 20px;
  text-align: left;
}

.checkout__totals-header {
  font-size: 13px;
  text-transform: uppercase;
}

.checkout__totals-header th {
  padding-bottom: 4px;
  border-bottom: 1px solid #ebebeb;
}

.checkout__totals-products td {
  padding: 4px 0;
}

.checkout__totals-products tr:first-child td {
  padding-top: 16px;
}

.checkout__totals-products tr:last-child td {
  padding-bottom: 16px;
}

.checkout__totals-subtotals th {
  font-weight: 500;
}

.checkout__totals-subtotals td,
.checkout__totals-subtotals th {
  padding: 4px 0;
}

.checkout__totals-subtotals tr:first-child td,
.checkout__totals-subtotals tr:first-child th {
  padding-top: 16px;
  border-top: 1px solid #ebebeb;
}

.checkout__totals-subtotals tr:last-child td,
.checkout__totals-subtotals tr:last-child th {
  padding-bottom: 16px;
}

.checkout__totals-footer {
  font-size: 24px;
}

.checkout__totals-footer th {
  font-weight: 500;
}

.checkout__totals-footer tr:first-child td,
.checkout__totals-footer tr:first-child th {
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.checkout__payment-methods {
  margin-bottom: 20px;
}

.checkout__agree {
  margin-bottom: 40px;
}

.checkout__agree a:hover {
  text-decoration: underline;
}

/*
  // .compare
  */
.compare__options-list {
  padding: 16px 20px;
  border-bottom: 1px solid #ebebeb;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.compare__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

[dir=ltr] .compare__option+.compare__option {
  margin-left: 16px;
}

[dir=rtl] .compare__option+.compare__option {
  margin-right: 16px;
}

[dir=ltr] .compare__option-label {
  margin-right: 8px;
}

[dir=rtl] .compare__option-label {
  margin-left: 8px;
}

/*
  // .compare-table
  */
.compare-table {
  width: 100%;
  font-size: 15px;
  border-spacing: 0;
}

.compare-table tr:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.compare-table__column--header,
.compare-table__column--product {
  padding: 14px 16px;
}

[dir=ltr] .compare-table__column--product,
[dir=ltr] .compare-table__column--fake {
  border-left: 1px solid #ebebeb;
}

[dir=rtl] .compare-table__column--product,
[dir=rtl] .compare-table__column--fake {
  border-right: 1px solid #ebebeb;
}

.compare-table__column--header {
  width: 15%;
  background: #f7f7f7;
  font-weight: 500;
  min-width: 140px;
}

[dir=ltr] .compare-table__column--header {
  text-align: right;
}

[dir=rtl] .compare-table__column--header {
  text-align: left;
}

.compare-table__column--product {
  width: 17%;
  text-align: center;
  vertical-align: top;
  min-width: 180px;
}

.compare-table__column--fake {
  padding: 0;
}

.compare-table__column--product:nth-child(6)+.compare-table__column--fake {
  display: none;
}

.compare-table__product {
  display: block;
  line-height: 20px;
  color: inherit;
  -webkit-transition: color .12s;
  transition: color .12s;
}

.compare-table__product:hover {
  color: #cb1818;
}

.compare-table__product-image {
  width: 150px;
  margin: 0 auto;
}

.compare-table__product-name {
  color: inherit;
}

.compare-table__rating-stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 1px;
}

.compare-table__rating-title {
  font-size: 13px;
  color: #6c757d;
  margin-top: 5px;
}

/*
  // .order-header
  */
.order-header {
  padding: 1.625rem 2rem 1.375rem;
}

.order-header:after {
  display: block;
  content: '';
  clear: both;
}

.order-header__actions {
  margin-top: -1px;
}

[dir=ltr] .order-header__actions {
  float: right;
}

[dir=rtl] .order-header__actions {
  float: left;
}

.order-header__title {
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 4px;
  margin-bottom: 0;
}

[dir=ltr] .order-header__title {
  padding-right: 3.5rem;
  float: left;
}

[dir=rtl] .order-header__title {
  padding-left: 3.5rem;
  float: right;
}

.order-header__subtitle {
  color: #6c757d;
  font-size: 14px;
  line-height: 1.375;
  clear: both;
  padding-top: 12px;
}

.order-header__subtitle mark {
  padding: 0;
  color: #262626;
  background: transparent;
  font-weight: 500;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .order-header {
    padding: 1.375rem 1.5rem 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .order-header {
    padding: 1.125rem 1.375rem 1rem;
  }
}

@media (max-width: 440px) {
  .order-header__actions {
    margin-bottom: .875rem;
  }

  [dir=ltr] .order-header__actions {
    float: none;
  }

  [dir=rtl] .order-header__actions {
    float: none;
  }
}

/*
  // .order-list
  */
.order-list a {
  color: inherit;
}

.order-list a:hover {
  color: inherit;
  text-decoration: underline;
}

.order-list table {
  width: 100%;
  font-size: 15px;
}

.order-list__header th,
.order-list__header td {
  text-transform: uppercase;
  color: #6c757d;
  font-size: 13px;
  font-weight: 500;
}

.order-list__footer {
  font-size: 20px;
}

.order-list__footer th {
  font-weight: 500;
}

.order-list__column-image {
  width: 1px;
}

.order-list__column-image .image {
  width: 40px;
}

.order-list__column-product {
  line-height: 1.25;
}

.order-list__column-quantity {
  text-align: center;
}

[dir=ltr] .order-list__column-total {
  text-align: right;
}

[dir=rtl] .order-list__column-total {
  text-align: left;
}

.order-list__options {
  color: #6c757d;
  font-size: 13px;
  margin-top: 2px;
}

.order-list__options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.order-list__options-item:not(:last-child) {
  position: relative;
}

[dir=ltr] .order-list__options-item:not(:last-child) {
  padding-right: 19px;
}

[dir=rtl] .order-list__options-item:not(:last-child) {
  padding-left: 19px;
}

.order-list__options-item:not(:last-child):after {
  position: absolute;
  content: '';
  display: block;
  background: #ccc;
  width: 1px;
  height: 10px;
  top: calc(50% - 5px);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

[dir=ltr] .order-list__options-item:not(:last-child):after {
  right: 9px;
}

[dir=rtl] .order-list__options-item:not(:last-child):after {
  left: 9px;
}

@media (min-width: 576px) {

  .order-list th,
  .order-list td {
    padding: 4px 12px;
  }

  [dir=ltr] .order-list th:first-child,
  [dir=ltr] .order-list td:first-child {
    padding-left: 1.5rem;
  }

  [dir=rtl] .order-list th:first-child,
  [dir=rtl] .order-list td:first-child {
    padding-right: 1.5rem;
  }

  [dir=ltr] .order-list th:last-child,
  [dir=ltr] .order-list td:last-child {
    padding-right: 1.5rem;
  }

  [dir=rtl] .order-list th:last-child,
  [dir=rtl] .order-list td:last-child {
    padding-left: 1.5rem;
  }

  .order-list__header th,
  .order-list__header td {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 12px;
  }

  .order-list__products th,
  .order-list__products td {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .order-list__products tr:first-child th,
  .order-list__products tr:first-child td {
    padding-top: 14px;
  }

  .order-list__products tr:last-child th,
  .order-list__products tr:last-child td {
    padding-bottom: 14px;
  }

  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 4px;
  }

  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 4px;
  }

  .order-list__subtotals th,
  .order-list__subtotals td {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .order-list__subtotals tr:first-child th,
  .order-list__subtotals tr:first-child td {
    padding-top: 12px;
    border-top: 1px solid #ebebeb;
  }

  .order-list__subtotals tr:last-child th,
  .order-list__subtotals tr:last-child td {
    padding-bottom: 12px;
  }

  .order-list__footer th,
  .order-list__footer td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #ebebeb;
  }
}

@media (max-width: 575.98px) {

  .order-list table,
  .order-list tr,
  .order-list th,
  .order-list td {
    display: block;
    padding: 0;
  }

  .order-list__header,
  .order-list__products,
  .order-list__subtotals,
  .order-list__footer {
    display: block;
  }

  .order-list__header tr,
  .order-list__products tr,
  .order-list__subtotals tr,
  .order-list__footer tr {
    padding: 0 1rem;
  }

  .order-list__header tr,
  .order-list__subtotals tr,
  .order-list__footer tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .order-list__header .order-list__column-label,
  .order-list__subtotals .order-list__column-label,
  .order-list__footer .order-list__column-label {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .order-list__header .order-list__column-quantity,
  .order-list__subtotals .order-list__column-quantity,
  .order-list__footer .order-list__column-quantity {
    display: none;
  }

  .order-list__header tr {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }

  .order-list__products tr {
    padding-top: .75rem;
    padding-bottom: .75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .order-list__products tr:not(:first-child) {
    border-top: 1px solid #ebebeb;
  }

  .order-list__products .order-list__column-image {
    width: 40px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .order-list__products .order-list__column-product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(100% - 40px);
  }

  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 1rem;
  }

  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 1rem;
  }

  .order-list__products .order-list__column-quantity:before {
    content: attr(data-title) " ";
  }

  .order-list__products .order-list__column-total {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .order-list__products .order-list__column-quantity,
  .order-list__products .order-list__column-total {
    margin-top: .625rem;
    margin-bottom: -.125rem;
  }

  .order-list__subtotals {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }

  .order-list__subtotals th,
  .order-list__subtotals td {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .order-list__footer {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }
}

/*
  // .order-success
  */
.order-success__body {
  max-width: 690px;
  margin: 0 auto;
}

.order-success__header {
  padding: 60px 0 52px;
  text-align: center;
}

.order-success__icon {
  fill: #e52727;
  margin: 0 auto;
  display: block;
}

.order-success__title {
  margin-top: 24px;
  margin-bottom: 2px;
}

.order-success__subtitle {
  font-size: 19px;
  font-weight: 500;
}

.order-success__actions {
  margin-top: 20px;
}

.order-success__meta {
  padding: 16px 0;
  margin-bottom: 20px;
  text-align: center;
}

.order-success__meta-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.order-success__meta-item {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  line-height: 1.25;
  position: relative;
  padding: 2px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.order-success__meta-item:nth-child(2) {
  padding-right: 31px;
  padding-left: 31px;
}

.order-success__meta-item:not(:last-child):before {
  position: absolute;
  display: block;
  content: '';
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  width: 0;
  height: 100%;
  top: 0;
}

[dir=ltr] .order-success__meta-item:not(:last-child):before {
  right: -1px;
}

[dir=rtl] .order-success__meta-item:not(:last-child):before {
  left: -1px;
}

.order-success__meta-title {
  display: block;
  font-size: 13px;
  color: #6c757d;
  margin-bottom: 4px;
}

.order-success__meta-value {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.order-success__addresses {
  margin-left: -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.order-success__address {
  margin-left: 20px;
  margin-top: 20px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 767.98px) {
  .order-success__header {
    padding: 44px 0 40px;
  }

  .order-success__meta-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .order-success__meta-item {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .order-success__meta-item:nth-child(2n):before {
    display: none;
  }

  .order-success__meta-item:nth-child(n + 3) {
    margin-top: 16px;
  }

  .order-success__addresses {
    display: block;
  }
}

/*
  // .payment-methods
  */
.payment-methods__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payment-methods__item {
  border-radius: 2px;
  padding-bottom: 2px;
  border: 1px solid #ebebeb;
  -webkit-transition: background .12s,
    border-color .12s;
  transition: background .12s,
    border-color .12s;
}

.payment-methods__item+.payment-methods__item {
  margin-top: 6px;
}

.payment-methods__item:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}

.payment-methods__item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 18px;
  margin: 0;
  padding: 10px 12px 8px;
  cursor: pointer;
}

[dir=ltr] .payment-methods__item-radio {
  margin-right: 8px;
}

[dir=rtl] .payment-methods__item-radio {
  margin-left: 8px;
}

.payment-methods__item-details {
  font-size: 14px;
  line-height: 22px;
  padding: 0 12px 8px;
}

.payment-methods__item-container {
  overflow: hidden;
  opacity: 0;
  height: 0;
  -webkit-transition: height .3s,
    opacity .3s;
  transition: height .3s,
    opacity .3s;
}

.payment-methods__item--active,
.payment-methods__item--active:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}

.payment-methods__item--active .payment-methods__item-container {
  opacity: 1;
  height: auto;
}

/*
  // .product
  */
.product__header h1 {
  /* font-size: 26px;
    font-weight: 700;
    line-height: 32px; */
  margin: 0;
}

.product__tabs {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product__subtitle {
  padding-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product__rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #6c757d;
  font-size: 14px;
  line-height: 1;
}

[dir=ltr] .product__rating-stars {
  margin-right: 8px;
}

[dir=rtl] .product__rating-stars {
  margin-left: 8px;
}

.product__rating-label a {
  color: inherit;
}

[dir=ltr] .product__fit {
  margin-left: 12px;
}

[dir=rtl] .product__fit {
  margin-right: 12px;
}

.product__info-card {
  position: relative;
  /* min-height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__info-body {
  padding: 28px 28px 24px;
}

.product__prices-stock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 22px;
}

.product__meta {
  margin: -3px 0;
}

.product__meta table {
  width: 100%;
  font-size: 14px;
}

.product__meta th,
.product__meta td {
  padding: 3px 0;
}

.product__meta th {
  font-weight: 500;
}

[dir=ltr] .product__meta th {
  padding-right: 12px;
}

[dir=rtl] .product__meta th {
  padding-left: 12px;
}

.product__meta tr+tr>* {
  border-top: 1px solid #ebebeb;
}

.product__meta a {
  color: #cb1818;
}

.product__meta a:hover {
  text-decoration: underline;
}

.product__shop-features {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  margin-top: 30px;
}

.product__badge {
  top: -7px;
  position: absolute;
}

[dir=ltr] .product__badge {
  left: 22px;
}

[dir=rtl] .product__badge {
  right: 22px;
}

.product__prices {
  padding: 8px 0 7px;
  line-height: 1;
}

.product__price {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -.04em;
  color: #262626;
}

.product__price--new {
  color: #262626;
}

.product__price--old {
  font-size: 16px;
  text-decoration: line-through;
  font-weight: 400;
  color: #999;
  margin-bottom: 5px;
}

.product__form {
  background: #f9f9f9;
  margin-bottom: 24px;
  margin-top: -4px;
  padding: 16px 28px 18px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.product__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  white-space: nowrap;
  padding: 0 28px 24px;
}

.product__actions-item--quantity {
  width: 100px;
}

[dir=ltr] .product__actions-item--quantity {
  margin-right: 8px;
}

[dir=rtl] .product__actions-item--quantity {
  margin-left: 8px;
}

.product__actions-item--addtocart {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product__actions-item--wishlist,
.product__actions-item--compare {
  margin-top: 14px;
  margin-bottom: -8px;
  width: 50%;
  font-size: 13px;
  line-height: 16px;
  background: transparent;
  color: #262626;
  fill: #d9d9d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  padding-top: 7px;
  padding-bottom: 5px;
  border-radius: 2px;
  -webkit-transition: background .15s,
    fill .15s;
  transition: background .15s,
    fill .15s;
}

[dir=ltr] .product__actions-item--wishlist,
[dir=ltr] .product__actions-item--compare {
  padding-left: 9px;
  padding-right: 10px;
}

[dir=rtl] .product__actions-item--wishlist,
[dir=rtl] .product__actions-item--compare {
  padding-right: 9px;
  padding-left: 10px;
}

.product__actions-item--wishlist svg,
.product__actions-item--compare svg {
  position: relative;
  top: -1px;
}

[dir=ltr] .product__actions-item--wishlist svg,
[dir=ltr] .product__actions-item--compare svg {
  margin-right: 8px;
}

[dir=rtl] .product__actions-item--wishlist svg,
[dir=rtl] .product__actions-item--compare svg {
  margin-left: 8px;
}

.product__actions-item--wishlist:focus,
.product__actions-item--compare:focus {
  outline: none;
}

.product__actions-item--wishlist:hover,
.product__actions-item--compare:hover {
  background: #f5f5f5;
  fill: #bfbfbf;
}

.product__actions-item--wishlist:active,
.product__actions-item--compare:active {
  background: #f0f0f0;
  fill: #b3b3b3;
}

.product__actions-divider {
  width: 100%;
}

.product__tags-and-share-links {
  border-top: 1px solid #ebebeb;
  padding: 24px 28px;
}

.product__tags+.product__share-links {
  margin-top: 18px;
}

.product--layout--sidebar {
  --product-gutter: 25px;
  --product-gallery-image-max-width: 400px;
}

.product--layout--sidebar .product__body {
  display: grid;
  grid-template-columns: calc(100% - 370px) 370px;
  grid-template-rows: auto auto 1fr;
}

.product--layout--sidebar .product__card--one {
  grid-column: 1;
  grid-row: 1 / 3;
}

.product--layout--sidebar .product__header {
  grid-column: 1;
  grid-row: 1;
}

.product--layout--sidebar .product__gallery {
  grid-column: 1;
  grid-row: 2;
}

.product--layout--sidebar .product__tabs {
  grid-column: 1;
  grid-row: 3;
}

.product--layout--sidebar .product__info {
  grid-column: 2;
  grid-row: 1 / 4;
}

.product--layout--sidebar .product__card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product--layout--sidebar .product__card--two {
  display: none;
}

.product--layout--sidebar .product__gallery {
  padding: 0 40px 40px;
}

.product--layout--sidebar .product__header {
  padding: 36px 40px 16px;
}

[dir=ltr] .product--layout--sidebar .product__info {
  margin-left: var(--product-gutter);
}

[dir=rtl] .product--layout--sidebar .product__info {
  margin-right: var(--product-gutter);
}

.product--layout--sidebar .product__info-card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product--layout--sidebar .product__shop-features,
.product--layout--sidebar .product__tabs {
  margin-top: var(--product-gutter);
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  [dir=ltr] .product--layout--sidebar .product__title {
    padding-right: 80px;
  }

  [dir=rtl] .product--layout--sidebar .product__title {
    padding-left: 80px;
  }

  .product--layout--sidebar .product__actions-item--wishlist,
  .product--layout--sidebar .product__actions-item--compare {
    position: absolute;
    top: 19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }

  [dir=ltr] .product--layout--sidebar .product__actions-item--wishlist,
  [dir=ltr] .product--layout--sidebar .product__actions-item--compare {
    right: 16px;
  }

  [dir=rtl] .product--layout--sidebar .product__actions-item--wishlist,
  [dir=rtl] .product--layout--sidebar .product__actions-item--compare {
    left: 16px;
  }

  .product--layout--sidebar .product__actions-item--wishlist span,
  .product--layout--sidebar .product__actions-item--compare span {
    display: none;
  }

  .product--layout--sidebar .product__actions-item--wishlist svg,
  .product--layout--sidebar .product__actions-item--compare svg {
    top: 0;
    display: block;
    margin: 0;
  }

  [dir=ltr] .product--layout--sidebar .product__actions-item--compare {
    right: 52px;
  }

  [dir=rtl] .product--layout--sidebar .product__actions-item--compare {
    left: 52px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {

  [dir=ltr] .product--layout--sidebar .product__info-body,
  [dir=ltr] .product--layout--sidebar .product__form,
  [dir=ltr] .product--layout--sidebar .product__actions,
  [dir=ltr] .product--layout--sidebar .product__tags-and-share-links {
    padding-left: 20px;
    padding-right: 20px;
  }

  [dir=rtl] .product--layout--sidebar .product__info-body,
  [dir=rtl] .product--layout--sidebar .product__form,
  [dir=rtl] .product--layout--sidebar .product__actions,
  [dir=rtl] .product--layout--sidebar .product__tags-and-share-links {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {

  .product--layout--sidebar .product__meta table,
  .product--layout--sidebar .product__meta th,
  .product--layout--sidebar .product__meta td {
    display: block;
  }

  .product--layout--sidebar .product__meta tbody {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .product--layout--sidebar .product__meta tr,
  .product--layout--sidebar .product__meta th {
    width: calc(50% - 10px);
  }

  .product--layout--sidebar .product__meta tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  [dir=ltr] .product--layout--sidebar .product__meta tr:nth-child(2n) {
    margin-left: 20px;
  }

  [dir=rtl] .product--layout--sidebar .product__meta tr:nth-child(2n) {
    margin-right: 20px;
  }
}

@media (max-width: 1199.98px) {
  .product--layout--sidebar .product__info-card {
    padding-top: 24px;
    padding-bottom: 28px;
  }

  [dir=ltr] .product--layout--sidebar .product__info-card {
    padding-left: 24px;
    padding-right: 24px;
  }

  [dir=rtl] .product--layout--sidebar .product__info-card {
    padding-right: 24px;
    padding-left: 24px;
  }

  .product--layout--sidebar .product__info-body {
    padding-top: 0;
    padding-bottom: 16px;
  }

  [dir=ltr] .product--layout--sidebar .product__info-body {
    padding-left: 0;
    padding-right: 0;
  }

  [dir=rtl] .product--layout--sidebar .product__info-body {
    padding-right: 0;
    padding-left: 0;
  }

  .product--layout--sidebar .product__prices-stock {
    padding-top: 0;
    padding-bottom: 10px;
  }

  [dir=ltr] .product--layout--sidebar .product__prices-stock {
    padding-left: 0;
    padding-right: 0;
  }

  [dir=rtl] .product--layout--sidebar .product__prices-stock {
    padding-right: 0;
    padding-left: 0;
  }

  .product--layout--sidebar .product__price {
    font-size: 26px;
  }

  .product--layout--sidebar .product__price--old {
    font-size: 16px;
  }

  .product--layout--sidebar .product__meta {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 8px 0;
  }

  .product--layout--sidebar .product__meta th,
  .product--layout--sidebar .product__meta td {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .product--layout--sidebar .product__meta tr+tr>* {
    border: none;
  }

  .product--layout--sidebar .product__form {
    background: transparent;
    padding: 0;
    border: none;
    margin-bottom: 16px;
  }

  .product--layout--sidebar .product__actions {
    padding: 0;
  }

  .product--layout--sidebar .product__tags-and-share-links {
    border: none;
    padding: 20px 0 0;
  }
}

@media (max-width: 767.98px) {
  .product--layout--sidebar .product__actions {
    margin-bottom: -6px;
  }

  .product--layout--sidebar .product__actions-divider {
    height: 10px;
  }

  .product--layout--sidebar .product__actions-item--wishlist,
  .product--layout--sidebar .product__actions-item--compare {
    width: auto;
    margin-top: 2px;
    margin-bottom: -2px;
  }
}

@media (max-width: 575.98px) {
  .product--layout--sidebar .product__subtitle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product--layout--sidebar .product__fit {
    margin-bottom: 11px;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  [dir=ltr] .product--layout--sidebar .product__fit {
    margin-left: -4px;
  }

  [dir=rtl] .product--layout--sidebar .product__fit {
    margin-right: -4px;
  }
}

@media (max-width: 1399.98px) {
  .product--layout--sidebar {
    --product-gutter: 20px;
    --product-gallery-image-max-width: 360px;
  }

  .product--layout--sidebar .product__body {
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
    grid-template-columns: calc(100% - 332px) 332px;
  }

  .product--layout--sidebar .product__info {
    grid-column: 2;
    grid-row: 1 / 3;
  }

  .product--layout--sidebar .product__gallery {
    padding-top: 0;
    padding-bottom: 28px;
  }

  [dir=ltr] .product--layout--sidebar .product__gallery {
    padding-left: 28px;
    padding-right: 28px;
  }

  [dir=rtl] .product--layout--sidebar .product__gallery {
    padding-right: 28px;
    padding-left: 28px;
  }

  .product--layout--sidebar .product__header {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  [dir=ltr] .product--layout--sidebar .product__header {
    padding-left: 28px;
    padding-right: 28px;
  }

  [dir=rtl] .product--layout--sidebar .product__header {
    padding-right: 28px;
    padding-left: 28px;
  }

  .product--layout--sidebar .product__shop-features {
    display: none;
  }

  .product--layout--sidebar .product__tabs {
    grid-column: 1 / 3;
  }
}

@media (max-width: 1199.98px) {
  .product--layout--sidebar {
    --product-gutter: 16px;
  }

  .product--layout--sidebar .product__body {
    grid-template-columns: 100%;
  }

  .product--layout--sidebar .product__info {
    grid-column: 1;
    grid-row: 3;
  }

  .product--layout--sidebar .product__tabs {
    grid-column: 1;
    grid-row: 4;
  }

  .product--layout--sidebar .product__card--one {
    display: none;
  }

  .product--layout--sidebar .product__header {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-bottom: 24px;
  }

  .product--layout--sidebar .product__gallery {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    width: 100%;
    margin-top: var(--product-gutter);
    padding-top: 24px;
    padding-bottom: 28px;
  }

  [dir=ltr] .product--layout--sidebar .product__gallery {
    padding-left: 24px;
    padding-right: 24px;
  }

  [dir=rtl] .product--layout--sidebar .product__gallery {
    padding-right: 24px;
    padding-left: 24px;
  }

  .product--layout--sidebar .product__info {
    margin-top: var(--product-gutter);
  }

  [dir=ltr] .product--layout--sidebar .product__info {
    margin-left: 0;
  }

  [dir=rtl] .product--layout--sidebar .product__info {
    margin-right: 0;
  }

  .product--layout--sidebar .product__info-card {
    position: static;
  }

  .product--layout--sidebar .product__actions-item--addtocart .btn {
    width: auto;
  }
}

@media (max-width: 767.98px) {
  .product--layout--sidebar .product__header {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-top: 24px;
    padding-bottom: 22px;
  }

  [dir=ltr] .product--layout--sidebar .product__header {
    padding-left: 24px;
    padding-right: 24px;
  }

  [dir=rtl] .product--layout--sidebar .product__header {
    padding-right: 24px;
    padding-left: 24px;
  }

  .product--layout--sidebar .product__title {
    font-size: 22px;
    line-height: 28px;
  }
}

.product--layout--full {
  --product-gutter: 25px;
}

.product--layout--full .product__body {
  display: grid;
  grid-template-columns: auto 1fr 370px;
  grid-template-rows: -webkit-max-content auto auto;
  grid-template-rows: max-content auto auto 1fr;
}

.product--layout--full .product__card--one {
  grid-column: 1 / 3;
  grid-row: 1 / 4;
}

.product--layout--full .product__gallery {
  grid-column: 1;
  grid-row: 1 / 4;
}

.product--layout--full .product__header {
  grid-column: 2;
  grid-row: 1;
}

.product--layout--full .product__main {
  grid-column: 2;
  grid-row: 2;
}

.product--layout--full .product__info {
  grid-column: 3;
  grid-row: 1 / 5;
}

.product--layout--full .product__tabs {
  grid-column: 1 / 3;
  grid-row: 4;
}

.product--layout--full .product__card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product--layout--full .product__card--two {
  display: none;
}

.product--layout--full .product__gallery {
  padding-top: 28px;
  padding-bottom: 30px;
  width: 440px;
}

[dir=ltr] .product--layout--full .product__gallery {
  padding-left: 40px;
  padding-right: 0;
}

[dir=rtl] .product--layout--full .product__gallery {
  padding-right: 40px;
  padding-left: 0;
}

.product--layout--full .product__header {
  padding-top: 28px;
  padding-bottom: 16px;
}

[dir=ltr] .product--layout--full .product__header {
  padding-left: 36px;
  padding-right: 40px;
}

[dir=rtl] .product--layout--full .product__header {
  padding-right: 36px;
  padding-left: 40px;
}

.product--layout--full .product__main {
  padding-top: 0;
  padding-bottom: 48px;
}

[dir=ltr] .product--layout--full .product__main {
  padding-left: 36px;
  padding-right: 40px;
}

[dir=rtl] .product--layout--full .product__main {
  padding-right: 36px;
  padding-left: 40px;
}

[dir=ltr] .product--layout--full .product__info {
  margin-left: var(--product-gutter);
}

[dir=rtl] .product--layout--full .product__info {
  margin-right: var(--product-gutter);
}

.product--layout--full .product__shop-features,
.product--layout--full .product__tabs {
  margin-top: var(--product-gutter);
}

.product--layout--full .product__subtitle {
  padding-top: 15px;
}

.product--layout--full .product__gallery-intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product--layout--full .product__excerpt {
  font-size: 13px;
}

.product--layout--full .product__features {
  margin: 20px 0 0;
  font-size: 14px;
  border-radius: 3px;
}

.product--layout--full .product__features ul {
  list-style: none;
  padding: 0;
  color: #6c757d;
  margin: -6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product--layout--full .product__features li {
  padding-top: 3px;
  padding-bottom: 2px;
  position: relative;
  margin: 0 6px;
  width: calc(100% / 1 - 12px);
}

[dir=ltr] .product--layout--full .product__features li {
  padding-left: 11px;
  padding-right: 0;
}

[dir=rtl] .product--layout--full .product__features li {
  padding-right: 11px;
  padding-left: 0;
}

.product--layout--full .product__features li:before {
  position: absolute;
  display: block;
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: currentColor;
  top: 12px;
  opacity: .85;
}

[dir=ltr] .product--layout--full .product__features li:before {
  left: 0;
}

[dir=rtl] .product--layout--full .product__features li:before {
  right: 0;
}

.product--layout--full .product__features-title {
  font-weight: 500;
  padding-bottom: 12px;
}

.product--layout--full .product__features-link {
  margin: 20px -2px 0;
  padding: 20px 2px 0;
  font-size: 14px;
  border-top: 1px solid #ebebeb;
}

.product--layout--full .product__features-link a:hover {
  text-decoration: underline;
}

@media (min-width: 1200px) {
  .product--layout--full .product__subtitle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product--layout--full .product__fit {
    margin-bottom: 11px;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  [dir=ltr] .product--layout--full .product__fit {
    margin-left: -4px;
  }

  [dir=rtl] .product--layout--full .product__fit {
    margin-right: -4px;
  }
}

@media (min-width: 768px) {
  .product--layout--full .product__info-card {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .product--layout--full .product__gallery {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  [dir=ltr] .product--layout--full .product__actions-item--addtocart .btn {
    padding-left: 16px;
    padding-right: 16px;
  }

  [dir=rtl] .product--layout--full .product__actions-item--addtocart .btn {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (max-width: 575.98px) {
  .product--layout--full .product__subtitle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .product--layout--full .product__fit {
    margin-bottom: 11px;
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  [dir=ltr] .product--layout--full .product__fit {
    margin-left: -4px;
  }

  [dir=rtl] .product--layout--full .product__fit {
    margin-right: -4px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  [dir=ltr] .product--layout--full .product__title {
    padding-right: 80px;
  }

  [dir=rtl] .product--layout--full .product__title {
    padding-left: 80px;
  }

  .product--layout--full .product__actions-item--wishlist,
  .product--layout--full .product__actions-item--compare {
    position: absolute;
    top: 19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }

  [dir=ltr] .product--layout--full .product__actions-item--wishlist,
  [dir=ltr] .product--layout--full .product__actions-item--compare {
    right: 16px;
  }

  [dir=rtl] .product--layout--full .product__actions-item--wishlist,
  [dir=rtl] .product--layout--full .product__actions-item--compare {
    left: 16px;
  }

  .product--layout--full .product__actions-item--wishlist span,
  .product--layout--full .product__actions-item--compare span {
    display: none;
  }

  .product--layout--full .product__actions-item--wishlist svg,
  .product--layout--full .product__actions-item--compare svg {
    top: 0;
    display: block;
    margin: 0;
  }

  [dir=ltr] .product--layout--full .product__actions-item--compare {
    right: 52px;
  }

  [dir=rtl] .product--layout--full .product__actions-item--compare {
    left: 52px;
  }
}

@media (max-width: 1399.98px) {
  .product--layout--full {
    --product-gutter: 24px;
  }

  .product--layout--full .product__body {
    grid-template-columns: auto 1fr 336px;
  }

  .product--layout--full .product__gallery {
    padding-top: 24px;
    padding-bottom: 36px;
    width: 100%;
  }

  [dir=ltr] .product--layout--full .product__gallery {
    padding-left: 28px;
    padding-right: 0;
  }

  [dir=rtl] .product--layout--full .product__gallery {
    padding-right: 28px;
    padding-left: 0;
  }

  .product--layout--full .product__header {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  [dir=ltr] .product--layout--full .product__header {
    padding-left: 28px;
    padding-right: 28px;
  }

  [dir=rtl] .product--layout--full .product__header {
    padding-right: 28px;
    padding-left: 28px;
  }

  .product--layout--full .product__main {
    padding-top: 0;
    padding-bottom: 36px;
  }

  [dir=ltr] .product--layout--full .product__main {
    padding-left: 28px;
    padding-right: 28px;
  }

  [dir=rtl] .product--layout--full .product__main {
    padding-right: 28px;
    padding-left: 28px;
  }

  [dir=ltr] .product--layout--full .product__info-body,
  [dir=ltr] .product--layout--full .product__form,
  [dir=ltr] .product--layout--full .product__actions,
  [dir=ltr] .product--layout--full .product__tags-and-share-links {
    padding-left: 20px;
    padding-right: 20px;
  }

  [dir=rtl] .product--layout--full .product__info-body,
  [dir=rtl] .product--layout--full .product__form,
  [dir=rtl] .product--layout--full .product__actions,
  [dir=rtl] .product--layout--full .product__tags-and-share-links {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 1199.98px) {
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr 336px;
    grid-template-rows: -webkit-max-content auto auto 1fr;
    grid-template-rows: max-content auto auto 1fr;
  }

  .product--layout--full .product__card {
    grid-column: 1 / 3;
    grid-row: 1 / 4;
  }

  .product--layout--full .product__gallery {
    grid-column: 1 / 3;
    grid-row: 3;
  }

  .product--layout--full .product__header {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .product--layout--full .product__main {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  .product--layout--full .product__info {
    grid-column: 3;
    grid-row: 1 / 4;
  }

  .product--layout--full .product__tabs {
    grid-column: 1 / 4;
    grid-row: 4;
  }

  .product--layout--full .product__features {
    display: none;
  }

  .product--layout--full .product__main {
    padding-bottom: 16px;
  }

  .product--layout--full .product__excerpt {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 14px;
  }

  .product--layout--full .product__gallery {
    --product-gallery-image-max-width: 380px;
    padding-top: 0;
    width: 100%;
  }

  [dir=ltr] .product--layout--full .product__gallery {
    padding-right: 28px;
  }

  [dir=rtl] .product--layout--full .product__gallery {
    padding-left: 28px;
  }

  .product--layout--full .product__header {
    padding-bottom: 10px;
  }

  .product--layout--full .product__info {
    /* display: -ms-inline-grid; */
    /* display: inline-grid; */
    padding-bottom: 75%;
  }

  .product--layout--full .product__shop-features {
    display: none;
  }
}

@media screen and (max-width:768px) {
  .product--layout--full .product__info {
    padding-bottom: 10px;
  }
}

@media (max-width: 991.98px) {
  .product--layout--full {
    --product-gutter: 16px;
  }

  .product--layout--full .product__body {
    grid-template-columns: auto 1fr;
    grid-template-rows: -webkit-max-content auto auto 1fr;
    grid-template-rows: max-content auto auto 1fr;
    overflow: hidden;
  }

  .product--layout--full .product__card {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  .product--layout--full .product__header {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .product--layout--full .product__main {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  .product--layout--full .product__gallery {
    grid-column: 1;
    grid-row: 3;
  }

  .product--layout--full .product__info {
    grid-column: 2;
    grid-row: 3;
  }

  .product--layout--full .product__gallery {
    margin-top: var(--product-gutter);
    width: 385px;
    padding-top: 24px;
    padding-bottom: 28px;
  }

  [dir=ltr] .product--layout--full .product__gallery {
    padding-left: 24px;
    padding-right: 24px;
  }

  [dir=rtl] .product--layout--full .product__gallery {
    padding-right: 24px;
    padding-left: 24px;
  }

  [dir=ltr] .product--layout--full .product__info {
    margin-left: 15px;
  }

  [dir=rtl] .product--layout--full .product__info {
    margin-right: var(--product-gutter);
  }

  .product--layout--full .product__excerpt {
    border: none;
    padding-bottom: 8px;
  }

  .product--layout--full .product__info-card {
    position: static;
    margin-top: var(--product-gutter);
    padding-top: 24px;
    padding-bottom: 28px;
  }

  [dir=ltr] .product--layout--full .product__info-card {
    padding-left: 15px;
    padding-right: 15px;
  }

  [dir=rtl] .product--layout--full .product__info-card {
    padding-right: 24px;
    padding-left: 24px;
  }

  .product--layout--full .product__info-body {
    padding-top: 0;
    padding-bottom: 16px;
  }

  [dir=ltr] .product--layout--full .product__info-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  [dir=rtl] .product--layout--full .product__info-body {
    padding-right: 0px;
    padding-left: 0px;
  }

  .product--layout--full .product__prices-stock {
    padding-top: 0;
    padding-bottom: 10px;
  }

  [dir=ltr] .product--layout--full .product__prices-stock {
    padding-left: 0px;
    padding-right: 0px;
  }

  [dir=rtl] .product--layout--full .product__prices-stock {
    padding-right: 0px;
    padding-left: 0px;
  }

  .product--layout--full .product__price {
    font-size: 26px;
  }

  .product--layout--full .product__price--old {
    font-size: 16px;
  }

  .product--layout--full .product__meta {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 8px 0;
  }

  .product--layout--full .product__meta th,
  .product--layout--full .product__meta td {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .product--layout--full .product__meta tr+tr>* {
    border: none;
  }

  .product--layout--full .product__form {
    background: transparent;
    border: none;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }

  [dir=ltr] .product--layout--full .product__form {
    padding-left: 0;
    padding-right: 0;
  }

  [dir=rtl] .product--layout--full .product__form {
    padding-right: 0;
    padding-left: 0;
  }

  .product--layout--full .product__actions {
    padding-top: 0;
    padding-bottom: 0;
  }

  [dir=ltr] .product--layout--full .product__actions {
    padding-left: 0;
    padding-right: 0;
  }

  [dir=rtl] .product--layout--full .product__actions {
    padding-right: 0;
    padding-left: 0;
  }

  .product--layout--full .product__tags-and-share-links {
    border: none;
    padding-top: 20px;
    padding-bottom: 0;
  }

  [dir=ltr] .product--layout--full .product__tags-and-share-links {
    padding-left: 0;
    padding-right: 0;
  }

  [dir=rtl] .product--layout--full .product__tags-and-share-links {
    padding-right: 0;
    padding-left: 0;
  }

  .product--layout--full .product-gallery[data-layout="product-full"] .product-gallery__zoom {
    top: 0px;
  }

  [dir=ltr] .product--layout--full .product-gallery[data-layout="product-full"] .product-gallery__zoom {
    right: 0px;
  }

  [dir=rtl] .product--layout--full .product-gallery[data-layout="product-full"] .product-gallery__zoom {
    left: -15px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

  .product--layout--full .product__meta table,
  .product--layout--full .product__meta th,
  .product--layout--full .product__meta td {
    display: block;
  }

  .product--layout--full .product__meta tbody {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .product--layout--full .product__meta tr,
  .product--layout--full .product__meta th {
    width: calc(50% - 10px);
  }

  .product--layout--full .product__meta tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  [dir=ltr] .product--layout--full .product__meta tr:nth-child(2n) {
    margin-left: 20px;
  }

  [dir=rtl] .product--layout--full .product__meta tr:nth-child(2n) {
    margin-right: 20px;
  }
}

@media only screen and (max-width:767px) {
  .product--layout--full .product__info {
    padding-bottom: 0;
  }
}

@media (max-width: 768px) {
  .product--layout--full .product-gallery[data-layout="product-full"] .product-gallery__zoom {
    top: 0px;
  }

  [dir=ltr] .product--layout--full .product-gallery[data-layout="product-full"] .product-gallery__zoom {
    right: 4px;
  }

  .product--layout--full .product__body {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }

  .product--layout--full .product__header {
    grid-column: 1;
    grid-row: 1;
  }

  .product--layout--full .product__gallery {
    grid-column: 1;
    grid-row: 2;
  }

  .product--layout--full .product__card--two {
    grid-column: 1;
    grid-row: 3 / 5;
  }

  .product--layout--full .product__main {
    grid-column: 1;
    grid-row: 3;
  }

  .product--layout--full .product__info {
    grid-column: 1;
    grid-row: 4;
  }

  .product--layout--full .product__tabs {
    grid-column: 1;
    grid-row: 5;
  }

  .product--layout--full .product__card--one {
    display: none;
  }

  .product--layout--full .product__card--two {
    display: block;
  }

  .product--layout--full .product__header {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-top: 24px;
    padding-bottom: 22px;
  }

  [dir=ltr] .product--layout--full .product__header {
    padding-left: 24px;
    padding-right: 24px;
  }

  [dir=rtl] .product--layout--full .product__header {
    padding-right: 24px;
    padding-left: 24px;
  }

  .product--layout--full .product__title {
    font-size: 22px;
    line-height: 28px;
  }

  .product--layout--full .product__subtitle {
    padding-top: 8px;
  }

  .product--layout--full .product__body {
    width: 100%;
  }

  .product--layout--full .product__gallery {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    --product-gallery-image-max-width: 360px;
    width: 100%;
  }

  .product--layout--full .product__main,
  .product--layout--full .product__card--two {
    margin-top: var(--product-gutter);
  }

  .product--layout--full .product__main {
    padding-top: 24px;
    padding-bottom: 18px;
  }

  [dir=ltr] .product--layout--full .product__main {
    padding-left: 24px;
    padding-right: 24px;
  }

  [dir=rtl] .product--layout--full .product__main {
    padding-right: 24px;
    padding-left: 24px;
  }

  .product--layout--full .product__excerpt {
    padding-bottom: 0;
  }

  .product--layout--full .product__info {
    width: 100%;
  }

  [dir=ltr] .product--layout--full .product__info {
    margin-left: 0;
    display: none;
  }

  [dir=rtl] .product--layout--full .product__info {
    margin-right: 0;
  }

  .product--layout--full .product__info-card {
    margin-top: 0;
    padding-top: 0;
  }

  .product--layout--full .product__actions {
    margin-bottom: -6px;
  }

  .product--layout--full .product__actions-item--addtocart {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  .product--layout--full .product__actions-divider {
    height: 10px;
  }

  .product--layout--full .product__actions-item--wishlist,
  .product--layout--full .product__actions-item--compare {
    width: auto;
    margin-top: 2px;
    margin-bottom: -2px;
  }
}

/*
  // .product-card
  */
.product-card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product-card .product-card__action {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0;
  border: none;
  -webkit-transition: color .08s,
    background .08s;
  transition: color .08s,
    background .08s;
  background-color: #fff;
  color: #ccc;
}

.product-card .product-card__action:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}

.product-card .product-card__action:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}

.product-card .product-card__action svg {
  fill: currentColor;
  display: block;
}

.product-card .product-card__action:focus {
  outline: none;
}

.product-card .product-card__action:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

[dir=ltr] .product-card .product-card__action:last-child {
  border-bottom-left-radius: 2px;
}

[dir=rtl] .product-card .product-card__action:last-child {
  border-bottom-right-radius: 2px;
}

.product-card .product-card__action--loading,
.product-card .product-card__action--loading:hover {
  cursor: default;
  background: transparent;
  color: transparent;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.product-card .product-card__action--loading:before {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  -webkit-animation-name: loader-animation;
  animation-name: loader-animation;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: '';
}

.product-card .product-card__image {
  display: block;
}

.product-card .product-card__rating {
  color: #6c757d;
  font-size: .8125rem;
}

.product-card .product-card__features {
  color: #6c757d;
  font-size: 13px;
  line-height: 21px;
}

.product-card .product-card__features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-card .product-card__features li {
  position: relative;
}

[dir=ltr] .product-card .product-card__features li {
  padding-left: 12px;
}

[dir=rtl] .product-card .product-card__features li {
  padding-right: 12px;
}

.product-card .product-card__features li:before {
  display: block;
  position: absolute;
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: currentColor;
  top: 9px;
  opacity: .7;
}

[dir=ltr] .product-card .product-card__features li:before {
  left: 3px;
}

[dir=rtl] .product-card .product-card__features li:before {
  right: 3px;
}

.product-card .product-card__name a {
  color: inherit;
}

.product-card .product-card__name a:hover {
  text-decoration: none;
}

.product-card .product-card__meta {
  font-size: 12px;
  line-height: 1;
  /* color: #999; */
}

.product-card .product-card__prices {
  font-weight: 500;
  letter-spacing: -.04em;
  line-height: 1;
  color: #262626;
}

.product-card .product-card__price--new {
  color: #262626;
}

.product-card .product-card__price--old {
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}

.product-card .product-card__addtocart-full {
  background: #e52727;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  -webkit-transition: background-color .15s,
    color .15s;
  transition: background-color .15s,
    color .15s;
}

.product-card .product-card__addtocart-full:focus {
  outline: none;
}

.product-card .product-card__addtocart-full:hover {
  background: #333;
  color: #fff;
}

.product-card .product-card__addtocart-full:active {
  background: #4d4d4d;
  color: #fff;
}

.product-card .product-card__wishlist,
.product-card .product-card__compare {
  border: none;
  background: transparent;
  color: #262626;
  fill: #d9d9d9;
  border-radius: 2px;
  -webkit-transition: background .15s,
    fill .15s;
  transition: background .15s,
    fill .15s;
}

.product-card .product-card__wishlist:focus,
.product-card .product-card__compare:focus {
  outline: none;
}

.product-card .product-card__wishlist:hover,
.product-card .product-card__compare:hover {
  background: #f5f5f5;
  fill: #bfbfbf;
}

.product-card .product-card__wishlist:active,
.product-card .product-card__compare:active {
  background: #f0f0f0;
  fill: #b3b3b3;
}

.product-card .product-card__addtocart-icon {
  position: relative;
  border: none;
  padding: 8px;
  border-radius: 2px;
  background: transparent;
  color: #ccc;
  z-index: 0;
  -webkit-transition: color .2s;
  transition: color .2s;
}

.product-card .product-card__addtocart-icon svg {
  display: block;
  fill: currentColor;
}

.product-card .product-card__addtocart-icon:before {
  top: -2px;
  left: -2px;
  display: block;
  content: '';
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: transparent;
  z-index: -1;
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: background .2s,
    -webkit-transform .2s;
  transition: background .2s,
    -webkit-transform .2s;
  transition: transform .2s,
    background .2s;
  transition: transform .2s,
    background .2s,
    -webkit-transform .2s;
}

.product-card .product-card__addtocart-icon:focus {
  outline: none;
}

.product-card:hover .product-card__addtocart-icon,
.product-card:active .product-card__addtocart-icon {
  color: #fff;
}

.product-card:hover .product-card__addtocart-icon:before,
.product-card:active .product-card__addtocart-icon:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  background: #e52727;
}

.product-card:hover .product-card__addtocart-icon:hover,
.product-card:active .product-card__addtocart-icon:hover {
  color: #fff;
}

.product-card:hover .product-card__addtocart-icon:hover:before,
.product-card:active .product-card__addtocart-icon:hover:before {
  background: #333;
}

.product-card:hover .product-card__addtocart-icon:active,
.product-card:active .product-card__addtocart-icon:active {
  color: #fff;
}

.product-card:hover .product-card__addtocart-icon:active:before,
.product-card:active .product-card__addtocart-icon:active:before {
  -webkit-transition-duration: .05s;
  transition-duration: .05s;
  background: #4d4d4d;
}

.product-card--layout--grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.product-card--layout--grid .product-card__addtocart-full,
.product-card--layout--grid .product-card__wishlist,
.product-card--layout--grid .product-card__compare {
  display: none;
}

.product-card--layout--grid:after {
  display: block;
  position: static;
  content: '';
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-card--layout--grid .product-card__badges {
  position: absolute;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[dir=ltr] .product-card--layout--grid .product-card__badges {
  left: 16px;
}

[dir=rtl] .product-card--layout--grid .product-card__badges {
  right: 16px;
}

.product-card--layout--grid .product-card__badges>*+* {
  margin-top: 3px;
}

.product-card--layout--grid .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

[dir=ltr] .product-card--layout--grid .product-card__actions-list {
  right: 0;
}

[dir=rtl] .product-card--layout--grid .product-card__actions-list {
  left: 0;
}

.product-card--layout--grid .product-card__image {
  display: block;
  position: relative;
}

.product-card--layout--grid .product-card__fit {
  position: absolute;
  bottom: 8px;
}

.product-card--layout--grid .product-card__fit .status-badge__text {
  display: none;
}

.product-card--layout--grid .product-card__fit .status-badge__tooltip {
  display: block;
}

[dir=ltr] .product-card--layout--grid .product-card__fit {
  left: calc(16px - 3px);
}

[dir=rtl] .product-card--layout--grid .product-card__fit {
  right: calc(16px - 3px);
}

.product-card--layout--grid .product-card__meta {
  padding: 6px 16px;
}

.product-card--layout--grid .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}

.product-card--layout--grid .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-card--layout--grid .product-card__rating-stars,
.product-card--layout--grid .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .product-card--layout--grid .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .product-card--layout--grid .product-card__rating-stars {
  margin-left: 7px;
}

.product-card--layout--grid .product-card__rating-label {
  line-height: 1;
}

.product-card--layout--grid .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}

.product-card--layout--grid .product-card__footer {
  padding: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.product-card--layout--grid .product-card__prices {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-card--layout--grid .product-card__price--old {
  font-size: 14px;
}

[dir=ltr] .product-card--layout--grid .product-card__price--old {
  margin-left: 6px;
}

[dir=rtl] .product-card--layout--grid .product-card__price--old {
  margin-right: 6px;
}

.product-card--layout--grid .product-card__addtocart-icon {
  margin: -3px;
}

@media (hover: hover) {

  .product-card--layout--grid .product-card__action--wishlist,
  .product-card--layout--grid .product-card__action--compare {
    -webkit-transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    opacity: 0;
    will-change: transform;
  }

  [dir=ltr] .product-card--layout--grid .product-card__action--wishlist,
  [dir=ltr] .product-card--layout--grid .product-card__action--compare {
    -webkit-transform: translateX(100%) translateX(6px);
    transform: translateX(100%) translateX(6px);
  }

  [dir=rtl] .product-card--layout--grid .product-card__action--wishlist,
  [dir=rtl] .product-card--layout--grid .product-card__action--compare {
    -webkit-transform: translateX(-100%) translateX(6px);
    transform: translateX(-100%) translateX(6px);
  }

  .product-card--layout--grid:hover .product-card__action--wishlist,
  .product-card--layout--grid:hover .product-card__action--compare,
  .product-card--layout--grid:active .product-card__action--wishlist,
  .product-card--layout--grid:active .product-card__action--compare {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.product-card--layout--horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-card--layout--horizontal .product-card__action--wishlist,
.product-card--layout--horizontal .product-card__action--compare,
.product-card--layout--horizontal .product-card__features,
.product-card--layout--horizontal .product-card__addtocart-icon,
.product-card--layout--horizontal .product-card__addtocart-full,
.product-card--layout--horizontal .product-card__wishlist,
.product-card--layout--horizontal .product-card__compare,
.product-card--layout--horizontal .product-card__meta,
.product-card--layout--horizontal .product-card__fit {
  display: none;
}

.product-card--layout--horizontal .product-card__image {
  padding: 8px 10px;
  width: 112px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.product-card--layout--horizontal .product-card__badges {
  position: absolute;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[dir=ltr] .product-card--layout--horizontal .product-card__badges {
  left: 10px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__badges {
  right: 10px;
}

.product-card--layout--horizontal .product-card__badges>*+* {
  margin-top: 3px;
}

.product-card--layout--horizontal .tag-badge {
  height: 16px;
  padding: 3px 11px 0;
}

.product-card--layout--horizontal .tag-badge:before {
  left: 3px;
  right: 3px;
}

[dir=ltr] .product-card--layout--horizontal .tag-badge:before {
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

[dir=rtl] .product-card--layout--horizontal .tag-badge:before {
  -webkit-transform: skewX(20deg);
  transform: skewX(20deg);
}

.product-card--layout--horizontal .product-card__actions-list {
  position: absolute;
}

[dir=ltr] .product-card--layout--horizontal .product-card__actions-list {
  right: 0;
}

[dir=rtl] .product-card--layout--horizontal .product-card__actions-list {
  left: 0;
}

.product-card--layout--horizontal .product-card__info {
  padding-top: 12px;
  padding-bottom: 40px;
}

[dir=ltr] .product-card--layout--horizontal .product-card__info {
  padding-right: 10px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__info {
  padding-left: 10px;
}

.product-card--layout--horizontal .product-card__name {
  font-size: 15px;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}

[dir=ltr] .product-card--layout--horizontal .product-card__name {
  padding-right: 26px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__name {
  padding-left: 26px;
}

.product-card--layout--horizontal .product-card__rating {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-card--layout--horizontal .product-card__rating-stars,
.product-card--layout--horizontal .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .product-card--layout--horizontal .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__rating-stars {
  margin-left: 7px;
}

.product-card--layout--horizontal .product-card__rating-label {
  line-height: 1;
}

.product-card--layout--horizontal .product-card__prices {
  position: absolute;
  bottom: 14px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[dir=ltr] .product-card--layout--horizontal .product-card__prices {
  left: 112px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__prices {
  right: 112px;
}

.product-card--layout--horizontal .product-card__price--old {
  font-size: 14px;
}

[dir=ltr] .product-card--layout--horizontal .product-card__price--old {
  margin-left: 6px;
}

[dir=rtl] .product-card--layout--horizontal .product-card__price--old {
  margin-right: 6px;
}

/*
  // .product-form
  */
.product-form__row+.product-form__row {
  margin-top: 12px;
}

.product-form__title {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #6c7177;
  margin-bottom: 4px;
}

/*
  // .product-gallery
  */
.product-gallery__featured {
  position: relative;
}

.product-gallery__featured .owl-carousel a {
  display: block;
}

.product-gallery__featured .image {
  max-width: 420px;
  margin: 0 auto;
}

.product-gallery__zoom {
  display: var(--product-gallery-zoom-display, block);
  top: 0;
  position: absolute;
  padding: 9px;
  margin: 0;
  border: none;
  border-radius: 21px;
  fill: currentColor;
  z-index: 1;
  -webkit-transition: background .15s,
    color .15s;
  transition: background .15s,
    color .15s;
  background-color: #fff;
  color: #ccc;
}

.product-gallery__zoom:hover {
  background-color: #f2f2f2;
  color: #999;
}

.product-gallery__zoom:active {
  background-color: #ebebeb;
  color: #808080;
}

[dir=ltr] .product-gallery__zoom {
  right: -21px;
}

[dir=rtl] .product-gallery__zoom {
  left: -21px;
}

.product-gallery__zoom:focus {
  outline: none;
}

.product-gallery__zoom svg {
  display: block;
}

.product-gallery__thumbnails .owl-stage {
  margin: 0 auto;
}

.product-gallery__thumbnails-item {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 1.5px;
  padding: 2px;
  cursor: pointer;
}

.product-gallery__thumbnails-item:before {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 0 2px #f2f2f2 inset;
  box-shadow: 0 0 0 2px #f2f2f2 inset;
  -webkit-transition: -webkit-box-shadow .12s;
  transition: -webkit-box-shadow .12s;
  transition: box-shadow .12s;
  transition: box-shadow .12s, -webkit-box-shadow .12s;
}

[dir=ltr] .product-gallery__thumbnails-item:before {
  left: 0;
}

[dir=rtl] .product-gallery__thumbnails-item:before {
  right: 0;
}

.product-gallery__thumbnails-item:hover:before {
  -webkit-box-shadow: 0 0 0 2px #e0e0e0 inset;
  box-shadow: 0 0 0 2px #e0e0e0 inset;
}

.product-gallery__thumbnails-item--active {
  cursor: default;
}

.product-gallery__thumbnails-item--active:before,
.product-gallery__thumbnails-item--active:hover:before {
  -webkit-box-shadow: 0 0 0 2px #e52727 inset;
  box-shadow: 0 0 0 2px #e52727 inset;
}

.product-gallery--layout--quickview .product-gallery__featured .image {
  width: 320px;
  max-width: 100%;
}

.product-gallery--layout--quickview .product-gallery__zoom {
  display: none;
}

.product-gallery--layout--product-sidebar .product-gallery__featured .image {
  max-width: var(--product-gallery-image-max-width, 100%);
}

.product-gallery--layout--product-full .product-gallery__featured .image {
  max-width: var(--product-gallery-image-max-width, 100%);
}

.product-gallery--layout--product-full .product-gallery__zoom {
  top: 0;
}

[dir=ltr] .product-gallery--layout--product-full .product-gallery__zoom {
  right: 0;
}

[dir=rtl] .product-gallery--layout--product-full .product-gallery__zoom {
  left: 0;
}

/*
  // .product-tabs
  */
.product-tabs__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  /* flex-wrap: wrap; */
  margin-top: 20px;
  overflow-x: auto;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #645a5b;
}

@media only screen and (max-width:991.98px) {
  .product-tabs-phn {
    flex-direction: column;
  }

}

.product-tabs__item {
  position: relative;
}

.product-tabs__item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 14px 18px 13px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #645a5b;

  -webkit-transition: background .12s,
    -webkit-box-shadow .12s;
  transition: background .12s,
    -webkit-box-shadow .12s;
  transition: background .12s,
    box-shadow .12s;
  transition: background .12s,
    box-shadow .12s,
    -webkit-box-shadow .12s;
}

@media only screen and (max-width:991.68px) {
  .product-tabs__item a {
    font-size: 13px;
    padding: 14px 14px 13px
  }

}

.product-tabs__item a:hover {
  /* background: #f7f7f7; */
  /* -webkit-box-shadow: 0 -1px #e5e5e5 inset;
            box-shadow: 0 -1px #e5e5e5 inset; */
}

.product-tabs__item a:active {
  /* background: #f2f2f2; */
  /* -webkit-box-shadow: 0 -1px #e0e0e0 inset;
            box-shadow: 0 -1px #e0e0e0 inset; */
}

[dir=ltr] .product-tabs__item:first-child {
  /* margin-left: auto; */
}

[dir=rtl] .product-tabs__item:first-child {
  margin-right: auto;
}

[dir=ltr] .product-tabs__item:last-child {
  margin-right: auto;
}

[dir=rtl] .product-tabs__item:last-child {
  margin-left: auto;
}

.product-tabs__item-counter {
  color: #e52727;
  font-size: 14px;
  top: -4px;
  position: relative;
}

[dir=ltr] .product-tabs__item-counter {
  margin-left: 3px;
  margin-right: -3px;
}

[dir=rtl] .product-tabs__item-counter {
  margin-right: 3px;
  margin-left: -3px;
}

.product-tabs__item--active a,
.product-tabs__item--active a:hover,
.product-tabs__item--active a:active {
  background: transparent;
  -webkit-box-shadow: 0 -2px #e52727 inset;
  box-shadow: 0 -2px #e52727 inset;
}

.product-tabs__pane {
  display: block;
  overflow: hidden;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}

.product-tabs__pane--active {
  padding: 20px 48px 42px 48px;
  overflow: visible;
  height: auto;
  opacity: 1;
  max-width: calc(100% - .001px);
}

.product-tabs--layout--full .product-tabs__pane--active {
  padding: 20px 48px 42px 48px
}

@media (max-width: 991.98px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 40px 44px 44px;
  }
}

@media (max-width: 767.98px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 24px 28px 28px;
    display: none;
  }
}

@media (max-width: 419px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 20px 24px 24px;
  }
}

@media (min-width: 1400px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 36px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 40px;
  }
}

@media (max-width: 1199.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 36px;
  }
}

@media (max-width: 767.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 28px;
  }
}

@media (max-width: 419px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 24px;
  }
}

/*
  // .products-list
  */
.products-list__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.products-list__content+.products-list__content {
  margin-top: 10px;
}

.products-list__item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.products-list__item .product-card {
  width: 100%;
}

.products-list[data-layout="grid"] .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.products-list[data-layout="grid"] .product-card .product-card__addtocart-full,
.products-list[data-layout="grid"] .product-card .product-card__wishlist,
.products-list[data-layout="grid"] .product-card .product-card__compare {
  display: none;
}

.products-list[data-layout="grid"] .product-card:after {
  display: block;
  position: static;
  content: '';
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.products-list[data-layout="grid"] .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

[dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__badges {
  left: 16px;
}

[dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__badges {
  right: 16px;
}

.products-list[data-layout="grid"] .product-card .product-card__badges>*+* {
  margin-top: 3px;
}

.products-list[data-layout="grid"] .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

[dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__actions-list {
  right: 0;
}

[dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__actions-list {
  left: 0;
}

.products-list[data-layout="grid"] .product-card .product-card__image {
  display: block;
  position: relative;
}

.products-list[data-layout="grid"] .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}

.products-list[data-layout="grid"] .product-card .product-card__fit .status-badge__text {
  display: none;
}

.products-list[data-layout="grid"] .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}

[dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__fit {
  left: calc(16px - 3px);
}

[dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__fit {
  right: calc(16px - 3px);
}

.products-list[data-layout="grid"] .product-card .product-card__meta {
  padding: 6px 16px;
}

.products-list[data-layout="grid"] .product-card .product-card__name {
  padding: 0 16px;
  line-height: 1.1875;
}

.products-list[data-layout="grid"] .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.products-list[data-layout="grid"] .product-card .product-card__rating-stars,
.products-list[data-layout="grid"] .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__rating-stars {
  margin-left: 7px;
}

.products-list[data-layout="grid"] .product-card .product-card__rating-label {
  line-height: 1;
}

.products-list[data-layout="grid"] .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}

.products-list[data-layout="grid"] .product-card .product-card__footer {
  padding: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.products-list[data-layout="grid"] .product-card .product-card__prices {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.products-list[data-layout="grid"] .product-card .product-card__price--old {
  font-size: 14px;
}

[dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__price--old {
  margin-left: 6px;
}

[dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__price--old {
  margin-right: 6px;
}

.products-list[data-layout="grid"] .product-card .product-card__addtocart-icon {
  margin: -3px;
}

@media (hover: hover) {

  .products-list[data-layout="grid"] .product-card .product-card__action--wishlist,
  .products-list[data-layout="grid"] .product-card .product-card__action--compare {
    -webkit-transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s, -webkit-transform 0.2s;
    opacity: 0;
    will-change: transform;
  }

  [dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__action--wishlist,
  [dir=ltr] .products-list[data-layout="grid"] .product-card .product-card__action--compare {
    -webkit-transform: translateX(100%) translateX(6px);
    transform: translateX(100%) translateX(6px);
  }

  [dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__action--wishlist,
  [dir=rtl] .products-list[data-layout="grid"] .product-card .product-card__action--compare {
    -webkit-transform: translateX(-100%) translateX(6px);
    transform: translateX(-100%) translateX(6px);
  }

  .products-list[data-layout="grid"] .product-card:hover .product-card__action--wishlist,
  .products-list[data-layout="grid"] .product-card:hover .product-card__action--compare,
  .products-list[data-layout="grid"] .product-card:active .product-card__action--wishlist,
  .products-list[data-layout="grid"] .product-card:active .product-card__action--compare {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.products-list--grid--3[data-layout="grid"] .products-list__content {
  margin: -10px;
}

.products-list--grid--3[data-layout="grid"] .products-list__item {
  margin: 10px;
  width: calc((100% - 60px) / 3);
}

.products-list--grid--3[data-layout="grid"] .products-list__head {
  display: none;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--3[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--3[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}

@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--3[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--3[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}

@media (max-width: 419px) {
  .products-list--grid--3[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--3[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list--grid--4[data-layout="grid"] .products-list__content {
  margin: -10px;
}

.products-list--grid--4[data-layout="grid"] .products-list__item {
  margin: 10px;
  width: calc((100% - 80px) / 4);
}

.products-list--grid--4[data-layout="grid"] .products-list__head {
  display: none;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .products-list--grid--4[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--4[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}

@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--4[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--4[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}

@media (max-width: 419px) {
  .products-list--grid--4[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--4[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list--grid--5[data-layout="grid"] .products-list__content {
  margin: -10px;
}

.products-list--grid--5[data-layout="grid"] .products-list__item {
  margin: 10px;
  width: calc((100% - 100px) / 5);
}

.products-list--grid--5[data-layout="grid"] .products-list__head {
  display: none;
}

.products-list--grid--6[data-layout="grid"] .products-list__content {
  margin: -8px;
}

.products-list--grid--6[data-layout="grid"] .products-list__item {
  margin: 8px;
  width: calc((100% - 96px) / 6);
}

.products-list--grid--6[data-layout="grid"] .products-list__head {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .products-list--grid--6[data-layout="grid"] .products-list__content {
    margin: -10px;
  }

  .products-list--grid--6[data-layout="grid"] .products-list__item {
    margin: 10px;
    width: calc((100% - 100px) / 5);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--6[data-layout="grid"] .products-list__content {
    margin: -10px;
  }

  .products-list--grid--6[data-layout="grid"] .products-list__item {
    margin: 10px;
    width: calc((100% - 80px) / 4);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .products-list--grid--6[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--6[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 48px) / 3);
  }
}

@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--6[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--6[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 32px) / 2);
  }
}

@media (max-width: 419px) {
  .products-list--grid--6[data-layout="grid"] .products-list__content {
    margin: -8px;
  }

  .products-list--grid--6[data-layout="grid"] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }
}

.products-list[data-with-features="true"] .product-card .product-card__features {
  display: block;
}

.products-list[data-layout="list"] .products-list__content {
  margin: -10px;
}

.products-list[data-layout="list"] .products-list__item {
  margin: 10px;
  width: calc((100% - 20px) / 1);
}

.products-list[data-layout="list"] .products-list__head {
  display: none;
}

.products-list[data-layout="list"] .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.products-list[data-layout="list"] .product-card .product-card__addtocart-icon,
.products-list[data-layout="list"] .product-card .product-card__action--wishlist,
.products-list[data-layout="list"] .product-card .product-card__action--compare {
  display: none;
}

.products-list[data-layout="list"] .product-card .product-card__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.products-list[data-layout="list"] .product-card .product-card__badges {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.products-list[data-layout="list"] .product-card .product-card__badges>*+* {
  margin-top: 3px;
}

.products-list[data-layout="list"] .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__actions-list {
  right: 0;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__actions-list {
  left: 0;
}

.products-list[data-layout="list"] .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.products-list[data-layout="list"] .product-card .product-card__rating-stars,
.products-list[data-layout="list"] .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}

[dir=ltr] .products-list[data-layout="list"] .product-card .product-card__rating-stars {
  margin-right: 7px;
}

[dir=rtl] .products-list[data-layout="list"] .product-card .product-card__rating-stars {
  margin-left: 7px;
}

.products-list[data-layout="list"] .product-card .product-card__rating-label {
  line-height: 1;
}

.products-list[data-layout="list"] .product-card .product-card__fit .status-badge__text {
  display: block;
}

.products-list[data-layout="list"] .product-card .product-card__fit .status-badge__tooltip {
  display: none;
}

@media (min-width: 768px) {
  .products-list[data-layout="list"] .product-card .product-card__image {
    padding: 16px 24px;
    width: 260px;
  }

  .products-list[data-layout="list"] .product-card .product-card__badges {
    top: 16px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__badges {
    left: 16px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__badges {
    right: 16px;
  }

  .products-list[data-layout="list"] .product-card .product-card__fit {
    position: absolute;
    bottom: 24px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__fit {
    left: 258px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__fit {
    right: 258px;
  }

  .products-list[data-layout="list"] .product-card .product-card__info {
    padding-top: 20px;
    padding-bottom: 57px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__info {
    padding-left: 0;
    padding-right: 20px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__info {
    padding-right: 0;
    padding-left: 20px;
  }

  .products-list[data-layout="list"] .product-card .product-card__meta {
    position: absolute;
    bottom: 0;
    padding: 0;
    height: 38px;
    line-height: 36px;
    border-top: 1px solid #ebebeb;
    text-align: center;
    width: 180px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__meta {
    right: 20px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__meta {
    left: 20px;
  }

  .products-list[data-layout="list"] .product-card .product-card__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  .products-list[data-layout="list"] .product-card .product-card__features {
    font-size: 14px;
    padding-top: 10px;
  }

  .products-list[data-layout="list"] .product-card .product-card__features li+li {
    margin-top: 2px;
  }

  .products-list[data-layout="list"] .product-card .product-card__footer {
    width: 220px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 22px 49px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__footer {
    border-left: 1px solid #ebebeb;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__footer {
    border-right: 1px solid #ebebeb;
  }

  .products-list[data-layout="list"] .product-card .product-card__prices {
    padding: 28px 0;
    font-size: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .products-list[data-layout="list"] .product-card .product-card__price--old {
    font-size: 15px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__price--old {
    margin-left: 6px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__price--old {
    margin-right: 6px;
  }

  .products-list[data-layout="list"] .product-card .product-card__addtocart-full {
    display: block;
    height: 36px;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 10px;
  }

  .products-list[data-layout="list"] .product-card .product-card__wishlist,
  .products-list[data-layout="list"] .product-card .product-card__compare {
    font-size: 13px;
    line-height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 7px 10px 5px;
  }

  .products-list[data-layout="list"] .product-card .product-card__wishlist svg,
  .products-list[data-layout="list"] .product-card .product-card__compare svg {
    position: relative;
    top: -1px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__wishlist svg,
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__compare svg {
    margin-right: 8px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__wishlist svg,
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__compare svg {
    margin-left: 8px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__wishlist svg {
    margin-left: -3px;
    margin-right: 7px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__wishlist svg {
    margin-right: -3px;
    margin-left: 7px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__compare svg {
    margin-left: -2px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__compare svg {
    margin-right: -2px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .products-list[data-layout="list"] .product-card .product-card__image {
    width: 192px;
    padding-left: 16px;
    padding-right: 16px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__fit {
    left: 190px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__fit {
    right: 190px;
  }

  .products-list[data-layout="list"] .product-card .product-card__footer {
    width: 180px;
    padding: 0 16px 49px;
  }

  .products-list[data-layout="list"] .product-card .product-card__prices {
    padding: 24px 0;
  }

  .products-list[data-layout="list"] .product-card .product-card__meta {
    position: absolute;
    bottom: 0;
    width: 148px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__meta {
    right: 16px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__meta {
    left: 16px;
  }

  .products-list[data-layout="list"] .product-card .product-card__wishlist,
  .products-list[data-layout="list"] .product-card .product-card__compare {
    margin-top: 1px;
  }
}

@media (max-width: 767.98px) {
  .products-list[data-layout="list"] .product-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .products-list[data-layout="list"] .product-card .product-card__image {
    padding: 20px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }

  .products-list[data-layout="list"] .product-card .product-card__image .image {
    width: 180px;
    max-width: 100%;
  }

  .products-list[data-layout="list"] .product-card .product-card__fit {
    position: absolute;
    bottom: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__fit {
    left: 15px;
    right: 15px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__fit {
    right: 15px;
    left: 15px;
  }

  .products-list[data-layout="list"] .product-card .product-card__badges {
    top: 16px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__badges {
    left: 16px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__badges {
    right: 16px;
  }

  .products-list[data-layout="list"] .product-card .product-card__info {
    padding: 0 20px 20px;
  }

  .products-list[data-layout="list"] .product-card .product-card__features {
    padding-top: 12px;
  }

  .products-list[data-layout="list"] .product-card .product-card__footer {
    border-top: 1px solid #ebebeb;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 58px;
  }

  .products-list[data-layout="list"] .product-card .product-card__prices {
    padding-top: 20px;
    padding-bottom: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .products-list[data-layout="list"] .product-card .product-card__price--old {
    font-size: 15px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__price--old {
    margin-left: 6px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__price--old {
    margin-right: 6px;
  }

  .products-list[data-layout="list"] .product-card .product-card__addtocart-full {
    height: 30px;
    padding: 0 16px;
    font-size: 15px;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__addtocart-full {
    margin-right: 8px;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__addtocart-full {
    margin-left: 8px;
  }

  .products-list[data-layout="list"] .product-card .product-card__wishlist,
  .products-list[data-layout="list"] .product-card .product-card__compare {
    height: 30px;
    width: 32px;
    padding: 0;
  }

  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__wishlist svg,
  [dir=ltr] .products-list[data-layout="list"] .product-card .product-card__compare svg {
    margin-left: 0;
    margin-right: 0;
  }

  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__wishlist svg,
  [dir=rtl] .products-list[data-layout="list"] .product-card .product-card__compare svg {
    margin-right: 0;
    margin-left: 0;
  }

  .products-list[data-layout="list"] .product-card .product-card__wishlist span,
  .products-list[data-layout="list"] .product-card .product-card__compare span {
    display: none;
  }

  .products-list[data-layout="list"] .product-card .product-card__meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    height: 38px;
    line-height: 36px;
    border-top: 1px solid #ebebeb;
  }
}

.products-list[data-layout="table"] .product-card {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.products-list[data-layout="table"] .products-list__item {
  width: 100%;
}

.products-list[data-layout="table"] .products-list__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.products-list[data-layout="table"] .products-list__head:after {
  display: block;
  content: '';
  width: 36px;
  margin: 0 16px;
}

.products-list[data-layout="table"] .products-list__column--image {
  width: 90px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  padding: 0 16px;
}

.products-list[data-layout="table"] .products-list__column--meta {
  width: 154px;
  text-align: left;
  padding-left: 8px;
}

[dir=ltr] .products-list[data-layout="table"] .products-list__column--meta {
  margin-left: 1px;
}

[dir=rtl] .products-list[data-layout="table"] .products-list__column--meta {
  margin-right: 1px;
}

.products-list[data-layout="table"] .products-list__column--product {
  padding: 0 18px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  /* flex-grow: 1; */
}

[dir=ltr] .products-list[data-layout="table"] .products-list__column--product {
  margin-left: 1px;
}

[dir=rtl] .products-list[data-layout="table"] .products-list__column--product {
  margin-right: 1px;
}

.products-list[data-layout="table"] .products-list__column--rating {
  width: 140px;
  text-align: right;
  padding: 0 16px;
}

[dir=ltr] .products-list[data-layout="table"] .products-list__column--rating {
  margin-left: 1px;
}

[dir=rtl] .products-list[data-layout="table"] .products-list__column--rating {
  margin-right: 1px;
}

.products-list[data-layout="table"] .products-list__column--price {
  width: 136px;
  padding: 0 16px;
  margin: 0 1px;
}

[dir=ltr] .products-list[data-layout="table"] .products-list__column--price {
  text-align: right;
}

[dir=rtl] .products-list[data-layout="table"] .products-list__column--price {
  text-align: left;
}

.products-list[data-layout="table"] .product-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.products-list[data-layout="table"] .product-card .product-card__fit,
.products-list[data-layout="table"] .product-card .product-card__features,
.products-list[data-layout="table"] .product-card .product-card__wishlist,
.products-list[data-layout="table"] .product-card .product-card__compare,
.products-list[data-layout="table"] .product-card .product-card__action,
.products-list[data-layout="table"] .product-card .product-card__addtocart-full {
  display: none;
}

.products-list[data-layout="table"] .product-card .product-card__badges {
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
}

.products-list[data-layout="table"] .product-card .product-card__badges>* {
  display: inline-block;
  vertical-align: middle;
}

[dir=ltr] .products-list[data-layout="table"] .product-card .product-card__badges {
  left: 0;
  margin-right: 2px;
}

[dir=rtl] .products-list[data-layout="table"] .product-card .product-card__badges {
  right: 0;
  margin-left: 2px;
}

.products-list[data-layout="table"] .product-card .tag-badge {
  padding: 3px 5px;
  height: 15px;
  font-size: 10px;
}

.products-list[data-layout="table"] .product-card .tag-badge:before {
  -webkit-transform: none;
  transform: none;
  border-radius: 1.5px;
}

[dir=ltr] .products-list[data-layout="table"] .product-card .tag-badge:before {
  left: 0;
  right: 0;
}

[dir=rtl] .products-list[data-layout="table"] .product-card .tag-badge:before {
  right: 0;
  left: 0;
}

@media (min-width: 576px) {
  [dir=ltr] .products-list[data-layout="table"] .product-card {
    padding-left: 16px;
    padding-right: 0;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card {
    padding-right: 16px;
    padding-left: 0;
  }

  .products-list[data-layout="table"] .product-card .product-card__image {
    width: 90px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__image {
    margin-right: 16px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__image {
    margin-left: 16px;
  }

  .products-list[data-layout="table"] .product-card .product-card__info {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    margin: 16px 0;
  }

  .products-list[data-layout="table"] .product-card .product-card__meta {
    font-size: 13px;
  }

  .products-list[data-layout="table"] .product-card .product-card__meta-title {
    display: none;
  }

  .products-list[data-layout="table"] .product-card .product-card__name {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    /* flex-grow: 1; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating {
    /* padding: 0 16px; */
    padding-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 4px;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating-label {
    margin-top: 3px;
  }

  .products-list[data-layout="table"] .product-card .product-card__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 16px 0;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__footer {
    /* border-left: 1px solid #ebebeb; */
    padding-left: 16px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__footer {
    border-right: 1px solid #ebebeb;
    padding-right: 16px;
  }

  .products-list[data-layout="table"] .product-card .product-card__prices {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 120px;
    font-size: 15px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__prices {
    border-right: 1px solid #ebebeb;
    padding-right: 16px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__prices {
    border-left: 1px solid #ebebeb;
    padding-left: 16px;
  }

  .products-list[data-layout="table"] .product-card .product-card__price--old {
    font-size: 14px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__price--old {
    margin-left: 6px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__price--old {
    margin-right: 6px;
  }

  .products-list[data-layout="table"] .product-card .product-card__price--old {
    margin-top: 4px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__price--old {
    margin-left: 0;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__price--old {
    margin-right: 0;
  }

  .products-list[data-layout="table"] .product-card .product-card__addtocart-icon {
    margin: 0 16px;
  }

  .products-list[data-layout="table"] .product-card .product-card__actions-list {
    padding: 0;
    display: none;
  }
}

@media (min-width: 1200px) {
  .products-list[data-layout="table"] .product-card .product-card__name {
    padding: 0 18px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__name {
    border-left: 1px solid #ebebeb;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__name {
    border-right: 1px solid #ebebeb;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating {
    width: 140px;
    padding-top: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__rating {
    border-left: 1px solid #ebebeb;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__rating {
    border-right: 1px solid #ebebeb;
  }

  .products-list[data-layout="table"] .product-card .product-card__meta {
    width: 140px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /* justify-content: center; */
    padding-left: 15px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__meta {
    border-left: 1px solid #ebebeb;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__meta {
    border-right: 1px solid #ebebeb;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .products-list[data-layout="table"] .product-card .product-card__image {
    width: 60px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__image {
    margin-right: 16px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__image {
    margin-left: 16px;
  }
}

@media (min-width: 576px) and (max-width: 1199.98px) {
  .products-list[data-layout="table"] .product-card .product-card__info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 16px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__info {
    border-left: 1px solid #ebebeb;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__info {
    border-right: 1px solid #ebebeb;
  }

  .products-list[data-layout="table"] .product-card .product-card__name {
    padding: 0;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating {
    margin: -3px 0 -2px 0;
    padding: 5px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating-stars,
  .products-list[data-layout="table"] .product-card .product-card__rating-label {
    margin: 3px 0 2px 0;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__rating-stars {
    margin-right: 7px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__rating-stars {
    margin-left: 7px;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating-label {
    line-height: 1;
  }

  .products-list[data-layout="table"] .product-card .product-card__meta {
    padding: 0 0 5px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .products-list[data-layout="table"] .product-card .product-card__image {
    width: 60px;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__image {
    margin-right: 16px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__image {
    margin-left: 16px;
  }

  .products-list[data-layout="table"] .product-card .product-card__prices {
    width: 90px;
  }
}

@media (max-width: 575.98px) {
  .products-list[data-layout="table"] .product-card {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 0;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card {
    padding-left: 0;
    padding-right: 0;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card {
    padding-right: 0;
    padding-left: 0;
  }

  .products-list[data-layout="table"] .product-card .product-card__image {
    padding: 4px;
    margin: 0 8px;
    width: 60px;
  }

  .products-list[data-layout="table"] .product-card .product-card__info {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 100%;
    margin: 0;
    padding: 14px;
    border-top: 1px solid #ebebeb;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__info {
    border-left: none;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__info {
    border-right: none;
  }

  .products-list[data-layout="table"] .product-card .product-card__name {
    line-height: 20px;
    margin-top: 6px;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating {
    margin: -3px 0 -2px 0;
    padding-top: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating-stars,
  .products-list[data-layout="table"] .product-card .product-card__rating-label {
    margin: 3px 0 2px 0;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__rating-stars {
    margin-right: 7px;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__rating-stars {
    margin-left: 7px;
  }

  .products-list[data-layout="table"] .product-card .product-card__rating-label {
    line-height: 1;
  }

  .products-list[data-layout="table"] .product-card .product-card__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .products-list[data-layout="table"] .product-card .product-card__prices {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 12px 14px;
    height: 100%;
  }

  [dir=ltr] .products-list[data-layout="table"] .product-card .product-card__prices {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
  }

  [dir=rtl] .products-list[data-layout="table"] .product-card .product-card__prices {
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
  }

  .products-list[data-layout="table"] .product-card .product-card__price--old {
    font-size: 14px;
    margin-top: 4px;
  }

  .products-list[data-layout="table"] .product-card .product-card__addtocart-icon {
    margin: 10px 18px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-item-align: center;
    align-self: center;
  }
}

@media (max-width: 1399.98px) {
  .products-list[data-layout="table"] .products-list__column--image {
    width: 86px;
  }
}

@media (max-width: 1199.98px) {
  .products-list[data-layout="table"] .products-list__column--image {
    width: 90px;
  }

  .products-list[data-layout="table"] .products-list__column--meta,
  .products-list[data-layout="table"] .products-list__column--rating {
    display: none;
  }
}

@media (min-width: 576px) {
  .products-list[data-layout="table"] {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .products-list[data-layout="table"] .products-list__item+.products-list__item {
    border-top: 1px solid #ebebeb;
  }
}

@media (max-width: 575.98px) {
  .products-list[data-layout="table"] .products-list__content {
    margin: -8px;
  }

  .products-list[data-layout="table"] .products-list__item {
    margin: 8px;
    width: calc((100% - 16px) / 1);
  }

  .products-list[data-layout="table"] .products-list__head {
    display: none;
  }
}

/*
  // .products-view
  */
.products-view__options {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  margin-bottom: 20px;
}

.products-view__pagination {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0;
  padding: .75rem;
}

.products-view__pagination-legend {
  font-size: .9375rem;
  padding: 0 .375rem;
}

@media (max-width: 767.98px) {
  .products-view__pagination {
    padding-top: 1rem;
    align-items: baseline;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .products-view__pagination-legend {
    padding-top: 9px;
  }
}

/*
  // .quickview
  */
.quickview {
  max-width: 800px;
}

.quickview__body {
  padding: 32px 32px 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.quickview__close {
  z-index: 2;
  position: absolute;
  padding: 16px;
  border: none;
  top: 0;
  border-radius: 0 2.5px 0 2.5px;
  background: transparent;
  fill: currentColor;
  -webkit-transition: background .2s,
    color .2s;
  transition: background .2s,
    color .2s;
  background-color: #fff;
  color: #ccc;
}

.quickview__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}

.quickview__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}

[dir=ltr] .quickview__close {
  right: 0;
}

[dir=rtl] .quickview__close {
  left: 0;
}

.quickview__close svg {
  display: block;
}

.quickview__close:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.quickview__close:focus {
  outline: none;
}

.quickview__gallery {
  width: 320px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .quickview__gallery {
  margin-right: 28px;
}

[dir=rtl] .quickview__gallery {
  margin-left: 28px;
}

.quickview__product {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.quickview__product-name {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-top: -2px;
}

.quickview__product-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
}

.quickview__product-rating-title {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}

[dir=ltr] .quickview__product-rating-title {
  margin-left: 8px;
}

[dir=rtl] .quickview__product-rating-title {
  margin-right: 8px;
}

.quickview__product-description {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
}

.quickview__product-meta {
  margin: 12px 0 0;
}

.quickview__product-meta table {
  display: block;
  margin: -3px;
}

.quickview__product-meta tbody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.quickview__product-meta tr,
.quickview__product-meta th,
.quickview__product-meta td {
  display: block;
}

.quickview__product-meta tr {
  background: #f2f2f2;
  border-radius: 1.5px;
  margin: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

[dir=ltr] .quickview__product-meta tr {
  padding-left: 7px;
  padding-right: 14px;
}

[dir=rtl] .quickview__product-meta tr {
  padding-right: 7px;
  padding-left: 14px;
}

.quickview__product-meta th {
  font-size: 11px;
  font-weight: 400;
  color: #808080;
  line-height: 1;
}

.quickview__product-meta td {
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
}

.quickview__product-meta a {
  color: inherit;
}

.quickview__product-prices-stock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 12px 0 16px;
}

.quickview__product-price {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -.04em;
}

.quickview__product-stock {
  margin-bottom: 2px;
}

[dir=ltr] .quickview__product-stock {
  margin-left: 12px;
}

[dir=rtl] .quickview__product-stock {
  margin-right: 12px;
}

.quickview__product-form {
  margin-bottom: 16px;
}

.quickview__product-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -4px;
}

.quickview__product-actions-item {
  margin: 4px;
}

.quickview__product-actions-item--quantity {
  width: 100px;
}

.quickview__see-details {
  border-radius: 0 0 2.5px 2.5px;
  border-top: 1px solid #ebebeb;
  display: block;
  text-align: center;
  color: #6c757d;
  font-size: 15px;
  height: 52px;
  line-height: 50px;
  -webkit-transition: background .15s,
    border-color .15s;
  transition: background .15s,
    border-color .15s;
}

.quickview__see-details:hover {
  color: #6c757d;
  background: #f7f7f7;
}

.quickview__see-details:active {
  background: #f0f0f0;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

@media (max-width: 991.98px) {
  .quickview {
    max-width: 520px;
  }

  .quickview__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .quickview__gallery {
    width: 100%;
    margin-bottom: 28px;
  }

  [dir=ltr] .quickview__gallery {
    margin-right: 0;
  }

  [dir=rtl] .quickview__gallery {
    margin-left: 0;
  }
}

@media (max-width: 575.98px) {
  .quickview {
    max-width: 100%;
  }
}

@media (max-width: 474px) {
  .quickview__body {
    padding: 20px 20px 24px;
  }
}

/*
  // .review
  */
.review__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.review__content {
  width: 100%;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 12px;
}

.review__avatar {
  overflow: hidden;
  border-radius: 1.5px;
}

[dir=ltr] .review__avatar {
  margin-right: 12px;
}

[dir=rtl] .review__avatar {
  margin-left: 12px;
}

.review__meta {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

[dir=ltr] .review__meta {
  margin-right: 20px;
}

[dir=rtl] .review__meta {
  margin-left: 20px;
}

.review__author {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  margin-top: 2px;
}

.review__date {
  font-size: 13px;
  color: #999;
}

@media (max-width: 474px) {
  .review__rating {
    width: 100%;
    margin-top: 12px;
    margin-bottom: -3px;
  }
}

/*
  // .reviews-list
  */
.reviews-list__content {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reviews-list__item+.reviews-list__item {
  margin-top: 18px;
  padding-top: 24px;
  border-top: 1px solid #ebebeb;
}

.reviews-list__pagination {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #ebebeb;
}

/*
  // .reviews-view
  */
.reviews-view__form {
  margin-top: 48px;
}

.reviews-view__header {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 500;
}

/*
  // .spec
  */
.spec {
  font-size: 14px;
  line-height: 18px;
}

.spec__header {
  margin-bottom: 50px;
}

.spec__section+.spec__section {
  margin-top: 40px;
}

.spec__section-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: -.03em;
  line-height: 20px;
  margin: 0 0 12px;
}

.spec__row {
  padding: 8px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}

.spec__row:first-of-type {
  border-top: 1px solid #ebebeb;
}

.spec__name {
  width: 230px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #6c757d;
}

[dir=ltr] .spec__name {
  padding-right: 30px;
}

[dir=rtl] .spec__name {
  padding-left: 30px;
}

.spec__disclaimer {
  margin-top: 40px;
  font-size: 13px;
  line-height: 20px;
  color: #6c757d;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .spec__name {
    width: 170px;
  }
}

@media (max-width: 575.98px) {
  .spec__header {
    margin-bottom: 30px;
  }

  .spec__section+.spec__section {
    margin-top: 24px;
  }

  .spec__row {
    display: block;
    padding: 8px 0;
  }

  .spec__name {
    width: auto;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 15px;
  }

  .spec__disclaimer {
    margin-top: 28px;
  }
}

/*
  // .vehicle-picker-modal
  */
.vehicle-picker-modal__panel {
  padding: 32px 32px 36px;
  display: none;
}

.vehicle-picker-modal__panel--active {
  display: block;
}

.vehicle-picker-modal__close {
  z-index: 2;
  position: absolute;
  padding: 16px;
  border: none;
  top: 0;
  border-radius: 0 2.5px 0 2.5px;
  background: transparent;
  fill: currentColor;
  -webkit-transition: background .2s,
    color .2s;
  transition: background .2s,
    color .2s;
  background-color: #fff;
  color: #ccc;
}

.vehicle-picker-modal__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}

.vehicle-picker-modal__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}

[dir=ltr] .vehicle-picker-modal__close {
  right: 0;
}

[dir=rtl] .vehicle-picker-modal__close {
  left: 0;
}

.vehicle-picker-modal__close svg {
  display: block;
}

.vehicle-picker-modal__close:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.vehicle-picker-modal__close:focus {
  outline: none;
}

.vehicle-picker-modal__text {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 10px;
  margin-top: -10px;
}

.vehicle-picker-modal__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 40px;
}

[dir=ltr] .vehicle-picker-modal__actions>*:not(:last-child) {
  margin-right: 12px;
}

[dir=rtl] .vehicle-picker-modal__actions>*:not(:last-child) {
  margin-left: 12px;
}

@media (max-width: 479px) {
  .vehicle-picker-modal__panel {
    padding: 24px 24px 28px;
  }

  .vehicle-picker-modal__actions {
    margin-top: 32px;
  }
}

@media (max-width: 399px) {
  .vehicle-picker-modal__panel {
    padding: 20px 20px 24px;
  }

  .vehicle-picker-modal__actions {
    margin-top: 28px;
  }
}

/*
  // .wishlist
  */
.wishlist__table {
  width: 100%;
  border-spacing: 0;
}

.wishlist__column--head {
  font-size: 13px;
  text-transform: uppercase;
}

.wishlist__product-name {
  line-height: 20px;
}

.wishlist__product-name a {
  color: inherit;
  -webkit-transition: color .15s;
  transition: color .15s;
}

.wishlist__product-name a:hover {
  color: #cb1818;
}

.wishlist__product-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
}

.wishlist__product-rating-title {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}

[dir=ltr] .wishlist__product-rating-title {
  margin-left: 8px;
}

[dir=rtl] .wishlist__product-rating-title {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .wishlist {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .wishlist__column {
    padding: 14px 16px;
  }

  [dir=ltr] .wishlist__column:first-child {
    padding-left: 28px;
  }

  [dir=rtl] .wishlist__column:first-child {
    padding-right: 28px;
  }

  [dir=ltr] .wishlist__column:last-child {
    padding-right: 28px;
  }

  [dir=rtl] .wishlist__column:last-child {
    padding-left: 28px;
  }

  .wishlist__column--image {
    text-align: center;
    width: 1px;
  }

  .wishlist__column--image .image {
    width: 80px;
  }

  .wishlist__column--body {
    border-top: 1px solid #ebebeb;
  }

  .wishlist__column--stock {
    text-align: center;
    width: 1px;
    white-space: nowrap;
  }

  .wishlist__column--price {
    white-space: nowrap;
    width: 140px;
  }

  [dir=ltr] .wishlist__column--price {
    text-align: right;
  }

  [dir=rtl] .wishlist__column--price {
    text-align: left;
  }

  .wishlist__column--button {
    white-space: nowrap;
    width: 200px;
  }

  [dir=ltr] .wishlist__column--button {
    text-align: right;
  }

  [dir=rtl] .wishlist__column--button {
    text-align: left;
  }

  .wishlist__column--remove {
    width: 1px;
    white-space: nowrap;
  }

  [dir=ltr] .wishlist__column--remove {
    padding-left: 0;
  }

  [dir=rtl] .wishlist__column--remove {
    padding-right: 0;
  }

  [dir=ltr] .wishlist__remove {
    margin-right: -12px;
  }

  [dir=rtl] .wishlist__remove {
    margin-left: -12px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .wishlist__column--button {
    width: 1px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  [dir=ltr] .wishlist__column:first-child {
    padding-left: 16px;
  }

  [dir=rtl] .wishlist__column:first-child {
    padding-right: 16px;
  }

  [dir=ltr] .wishlist__column:last-child {
    padding-right: 16px;
  }

  [dir=rtl] .wishlist__column:last-child {
    padding-left: 16px;
  }

  [dir=ltr] .wishlist__column--product,
  [dir=ltr] .wishlist__column--stock,
  [dir=ltr] .wishlist__column--price,
  [dir=ltr] .wishlist__column--button {
    padding-left: 0;
  }

  [dir=rtl] .wishlist__column--product,
  [dir=rtl] .wishlist__column--stock,
  [dir=rtl] .wishlist__column--price,
  [dir=rtl] .wishlist__column--button {
    padding-right: 0;
  }

  .wishlist__column--price {
    width: 1px;
  }

  .wishlist__remove {
    margin-top: 0;
    margin-bottom: 0;
  }

  [dir=ltr] .wishlist__remove {
    margin-left: -8px;
    margin-right: -8px;
  }

  [dir=rtl] .wishlist__remove {
    margin-right: -8px;
    margin-left: -8px;
  }
}

@media (max-width: 767.98px) {
  .wishlist__table {
    display: block;
  }

  .wishlist__head {
    display: none;
  }

  .wishlist__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -8px;
  }

  .wishlist__row,
  .wishlist__column {
    display: block;
  }

  .wishlist__column {
    padding: 0;
  }

  .wishlist__row {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    position: relative;
    margin: 8px;
    width: calc(50% - 16px);
    text-align: center;
    padding: 16px;
  }

  .wishlist__product-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wishlist__column--stock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wishlist__column--image {
    padding-bottom: 12px;
  }

  .wishlist__column--image .image {
    max-width: 220px;
    margin: 0 auto;
  }

  .wishlist__column--stock {
    margin-top: 16px;
    display: none;
  }

  .wishlist__column--price {
    margin-top: 8px;
    font-weight: 500;
  }

  .wishlist__column--button {
    padding: 20px 0 12px;
  }

  .wishlist__remove {
    position: absolute;
    top: 0;
  }

  [dir=ltr] .wishlist__remove {
    right: 0;
  }

  [dir=rtl] .wishlist__remove {
    left: 0;
  }
}

@media (max-width: 474px) {
  .wishlist__row {
    width: calc(100% - 16px);
  }
}

/* ----------------------------------------
  // Blog
  // ---------------------------------------- */
/*
  // .blog-view
  */
.blog-view__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-view__item-posts {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.blog-view__item-sidebar {
  width: 332px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .blog-view__item+.blog-view__item {
  margin-left: 48px;
}

[dir=rtl] .blog-view__item+.blog-view__item {
  margin-right: 48px;
}

.blog-view--layout--classic .blog-view__item-posts {
  max-width: 730px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-view__item-sidebar {
    width: 290px;
  }

  [dir=ltr] .blog-view__item+.blog-view__item {
    margin-left: 36px;
  }

  [dir=rtl] .blog-view__item+.blog-view__item {
    margin-right: 36px;
  }
}

@media (max-width: 991.98px) {
  .blog-view__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .blog-view__item-sidebar {
    width: 100%;
    margin-top: 0px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  [dir=ltr] .blog-view__item+.blog-view__item {
    margin-left: 0;
  }

  [dir=rtl] .blog-view__item+.blog-view__item {
    margin-right: 0;
  }
}

/*
  // .comment
  */
.comment__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.comment__content {
  width: 100%;
  background: #f5f5f5;
  padding: 12px 17px;
  border-radius: 5px;
  margin-top: 12px;
  font-size: 15px;
  position: relative;
}

.comment__content:before {
  position: absolute;
  content: '';
  display: block;
  left: 14px;
  top: -10px;
  border: 5px solid transparent;
  border-bottom-color: #f5f5f5;
  pointer-events: none;
}

.comment__avatar {
  overflow: hidden;
  border-radius: 50%;
  width: 38px;
}

[dir=ltr] .comment__avatar {
  margin-right: 14px;
}

[dir=rtl] .comment__avatar {
  margin-left: 14px;
}

.comment__meta {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

[dir=ltr] .comment__meta {
  margin-right: 12px;
}

[dir=rtl] .comment__meta {
  margin-left: 12px;
}

.comment__author {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  margin-top: 3px;
}

.comment__date {
  font-size: 13px;
  color: #999;
  margin-top: -1px;
}

@media (max-width: 419px) {
  .comment__content {
    font-size: 14px;
  }

  .comment__avatar {
    display: none;
  }
}

/*
  // .comments-list
  */
.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comments-list__item+.comments-list__item {
  margin-top: 28px;
}

.comments-list__children {
  margin-top: 14px;
  padding-top: 18px;
  margin-bottom: -12px;
  padding-bottom: 15px;
}

[dir=ltr] .comments-list__children {
  border-left: 1px solid #ebebeb;
  padding-left: 31px;
  margin-left: 18px;
}

[dir=rtl] .comments-list__children {
  border-right: 1px solid #ebebeb;
  padding-right: 31px;
  margin-right: 18px;
}

.comments-list__item:last-child>.comments-list__children {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .comments-list__item+.comments-list__item {
    margin-top: 24px;
  }

  .comments-list__children {
    padding-top: 14px;
    margin-bottom: -8px;
  }

  .comments-list__item:last-child>.comments-list__children {
    padding-bottom: 0;
  }
}

@media (max-width: 419px) {
  .comments-list__item+.comments-list__item {
    margin-top: 20px;
  }

  .comments-list__children {
    padding-top: 12px;
  }

  [dir=ltr] .comments-list__children {
    padding-left: 20px;
    margin-left: 12px;
  }

  [dir=rtl] .comments-list__children {
    padding-right: 20px;
    margin-right: 12px;
  }
}

/*
  // .comments-view
  */
.comments-view__pagination {
  margin-top: 36px;
}

@media (max-width: 991.98px) {
  .comments-view__pagination {
    margin-top: 32px;
  }
}

@media (max-width: 575.98px) {
  .comments-view__pagination {
    margin-top: 24px;
  }
}

/*
  // .post
  */
.post__body {
  padding: 48px;
  overflow: hidden;
}

.post__pagination {
  border-top: 1px solid #ebebeb;
  margin: -28px 48px 0;
  padding-top: 20px;
  padding-bottom: 48px;
}

.post__pagination-title {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.post__pagination-list {
  font-size: 15px;
}

.post__pagination-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  margin: -3px;
}

.post__pagination-list li {
  margin: 3px;
}

.post__pagination-link {
  padding: 2px 8px;
  display: block;
  border-radius: 1.5px;
  -webkit-transition: background .2s;
  transition: background .2s;
  background-color: #f0f0f0;
  color: #262626;
}

.post__pagination-link:hover {
  background-color: #e5e5e5;
  color: #262626;
}

.post__pagination-link:active {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  background-color: #e0e0e0;
  color: #262626;
}

.post__pagination-link--current {
  cursor: default;
}

.post__pagination-link--current,
.post__pagination-link--current:hover,
.post__pagination-link--current:active {
  background-color: #e52727;
  color: #fff;
}

.post__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0 48px 48px;
  margin-top: -24px;
}

[dir=ltr] .post__tags {
  margin-right: 32px;
}

[dir=rtl] .post__tags {
  margin-left: 32px;
}

.post__share-links {
  padding-bottom: 1px;
}

.post__author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -24px 48px 0;
  padding: 24px 0 48px;
  border-top: 1px solid #ebebeb;
}

.post__author img {
  display: block;
}

.post__author-avatar {
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 2px;
}

[dir=ltr] .post__author-avatar {
  margin-right: 20px;
}

[dir=rtl] .post__author-avatar {
  margin-left: 20px;
}

.post__author-name {
  font-weight: 500;
}

.post__author-about {
  font-size: 15px;
  margin-top: 4px;
}

@media (max-width: 767.98px) {
  .post__body {
    padding: 32px;
  }

  .post__pagination {
    margin: 0 32px 0;
  }

  .post__pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .post__footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 32px 24px;
  }

  [dir=ltr] .post__tags {
    margin-right: 0;
  }

  [dir=rtl] .post__tags {
    margin-left: 0;
  }

  .post__tags .tags__list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .post__share-links {
    margin-top: 20px;
    padding-bottom: 0;
  }

  .post__author {
    margin: 0 32px 0;
    padding: 32px 0 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post__author-avatar {
    border-radius: 50%;
  }

  [dir=ltr] .post__author-avatar {
    margin-right: 0;
  }

  [dir=rtl] .post__author-avatar {
    margin-left: 0;
  }

  .post__author-info {
    text-align: center;
    margin-top: 16px;
  }

  .post__author-about {
    margin-top: 6px;
  }
}

@media (max-width: 575.98px) {
  .post__body {
    padding: 24px;
  }

  .post__pagination {
    margin: 0 24px 0;
  }

  .post__author {
    margin: 0 24px 0;
    padding: 24px 0 24px;
  }
}

/*
  // .post-card
  */
.post-card--layout--grid {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.post-card--layout--grid .post-card__image img {
  max-width: 100%;
  height: auto;
}

.post-card--layout--grid .post-card__content {
  position: relative;
}

.post-card--layout--grid .post-card__title h2 {
  margin: 0;
}

.post-card--layout--grid .post-card__title a {
  color: inherit;
}

.post-card--layout--grid .post-card__title a:hover {
  text-decoration: underline;
}

.post-card--layout--grid .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}

[dir=ltr] .post-card--layout--grid .post-card__category {
  left: 0;
}

[dir=rtl] .post-card--layout--grid .post-card__category {
  right: 0;
}

.post-card--layout--grid .post-card__category a {
  color: #cb1818;
  display: block;
}

.post-card--layout--grid .post-card__category a:hover {
  text-decoration: underline;
}

.post-card--layout--grid .post-card__category:before,
.post-card--layout--grid .post-card__category:after {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}

.post-card--layout--grid .post-card__category:before {
  width: 100%;
}

[dir=ltr] .post-card--layout--grid .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

[dir=rtl] .post-card--layout--grid .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.post-card--layout--grid .post-card__category:after {
  width: 50px;
}

[dir=ltr] .post-card--layout--grid .post-card__category:after {
  left: 0;
}

[dir=rtl] .post-card--layout--grid .post-card__category:after {
  right: 0;
}

.post-card--layout--grid .post-card__date {
  font-size: 14px;
  color: #999;
}

.post-card--layout--grid .post-card__date a {
  color: inherit;
}

.post-card--layout--grid .post-card__date a:hover {
  color: #cb1818;
}

.post-card--layout--grid .post-card__date:before {
  display: block;
  content: '';
  height: 1px;
  background: currentColor;
  opacity: .6;
}

.post-card--layout--grid .post-card__excerpt {
  overflow: hidden;
}

@media (min-width: 768px) {
  .post-card--layout--grid .post-card__content {
    padding: 1.875rem 2.1875rem 2.1875rem;
  }

  .post-card--layout--grid .post-card__title h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
  }

  .post-card--layout--grid .post-card__category {
    height: 26px;
    padding: 1.125rem 2.1875rem 0;
  }

  .post-card--layout--grid .post-card__date {
    margin-top: 11px;
    margin-bottom: 15px;
  }

  .post-card--layout--grid .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  [dir=ltr] .post-card--layout--grid .post-card__date:before {
    margin-right: 4px;
  }

  [dir=rtl] .post-card--layout--grid .post-card__date:before {
    margin-left: 4px;
  }

  .post-card--layout--grid .post-card__more {
    margin-top: 18px;
  }
}

@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--grid .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }

  .post-card--layout--grid .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .post-card--layout--grid .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }

  .post-card--layout--grid .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .post-card--layout--grid .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  [dir=ltr] .post-card--layout--grid .post-card__date:before {
    margin-right: 4px;
  }

  [dir=rtl] .post-card--layout--grid .post-card__date:before {
    margin-left: 4px;
  }

  .post-card--layout--grid .post-card__more {
    margin-top: 18px;
  }
}

@media (max-width: 474px) {
  .post-card--layout--grid .post-card__category {
    font-size: 13px;
  }

  .post-card--layout--grid .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--grid .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .post-card--layout--grid .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }

  .post-card--layout--grid .post-card__excerpt .typography {
    line-height: 24px;
  }

  .post-card--layout--grid .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }

  .post-card--layout--grid .post-card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }

  .post-card--layout--grid .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-top: 12px;
  }

  .post-card--layout--grid .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }

  .post-card--layout--grid .post-card__more {
    display: none;
  }
}

@media (min-width: 768px) {
  .post-card--layout--list {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .post-card--layout--list .post-card__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .post-card--layout--list .post-card__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .post-card--layout--list .post-card__content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .post-card--layout--list .post-card__title h2 {
    margin-bottom: 0;
  }

  .post-card--layout--list .post-card__title a {
    color: inherit;
  }

  .post-card--layout--list .post-card__title a:hover {
    text-decoration: underline;
  }

  .post-card--layout--list .post-card__category {
    font-size: 14px;
    font-weight: 500;
  }

  .post-card--layout--list .post-card__category a {
    color: #cb1818;
  }

  .post-card--layout--list .post-card__category a:hover {
    text-decoration: underline;
  }

  .post-card--layout--list .post-card__excerpt {
    overflow: hidden;
    margin-bottom: auto;
  }

  .post-card--layout--list .post-card__date {
    font-size: 14px;
    color: #999;
  }

  .post-card--layout--list .post-card__date a {
    color: inherit;
  }

  .post-card--layout--list .post-card__date a:hover {
    color: #cb1818;
  }

  .post-card--layout--list .post-card__date:before {
    content: '';
    height: 1px;
    background: currentColor;
    opacity: .6;
  }
}

@media (min-width: 1400px) {
  .post-card--layout--list .post-card__image {
    width: 400px;
    min-height: 300px;
  }

  .post-card--layout--list .post-card__content {
    padding: 30px 32px 32px;
  }

  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__title h2 {
    font-size: 22px;
    line-height: 30px;
  }

  .post-card--layout--list .post-card__date {
    position: absolute;
    bottom: 36px;
  }

  [dir=ltr] .post-card--layout--list .post-card__date {
    right: 32px;
  }

  [dir=rtl] .post-card--layout--list .post-card__date {
    left: 32px;
  }

  .post-card--layout--list .post-card__date:before {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  [dir=ltr] .post-card--layout--list .post-card__date:before {
    margin-right: 4px;
  }

  [dir=rtl] .post-card--layout--list .post-card__date:before {
    margin-left: 4px;
  }

  .post-card--layout--list .post-card__category {
    margin-bottom: 10px;
    margin-top: -4px;
  }

  .post-card--layout--list .post-card__excerpt {
    max-height: 104px;
    margin-bottom: 48px;
  }

  .post-card--layout--list .post-card__more {
    position: absolute;
    bottom: 32px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .post-card--layout--list .post-card__image {
    width: 320px;
    min-height: 260px;
  }

  .post-card--layout--list .post-card__content {
    padding: 26px 28px 24px;
  }

  .post-card--layout--list .post-card__category {
    margin-top: -2px;
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .post-card--layout--list .post-card__excerpt {
    max-height: 78px;
  }

  .post-card--layout--list .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 36px;
    margin-top: 14px;
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__more {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-card--layout--list .post-card__image {
    width: 280px;
    min-height: 220px;
  }

  .post-card--layout--list .post-card__content {
    padding: 20px 26px 22px;
  }

  .post-card--layout--list .post-card__category {
    margin-bottom: 6px;
  }

  .post-card--layout--list .post-card__title {
    margin-bottom: 8px;
  }

  .post-card--layout--list .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }

  .post-card--layout--list .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }

  .post-card--layout--list .post-card__excerpt .typography {
    line-height: 22px;
  }

  .post-card--layout--list .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .post-card--layout--list .post-card__more {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .post-card--layout--list .post-card__image {
    width: 320px;
    min-height: 260px;
  }

  .post-card--layout--list .post-card__content {
    padding: 26px 28px 24px;
  }

  .post-card--layout--list .post-card__category {
    margin-top: -2px;
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .post-card--layout--list .post-card__excerpt {
    max-height: 78px;
  }

  .post-card--layout--list .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 36px;
    margin-top: 14px;
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__more {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .post-card--layout--list {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  .post-card--layout--list .post-card__image img {
    max-width: 100%;
    height: auto;
  }

  .post-card--layout--list .post-card__content {
    position: relative;
  }

  .post-card--layout--list .post-card__title h2 {
    margin: 0;
  }

  .post-card--layout--list .post-card__title a {
    color: inherit;
  }

  .post-card--layout--list .post-card__title a:hover {
    text-decoration: underline;
  }

  .post-card--layout--list .post-card__category {
    position: absolute;
    bottom: calc(100% - 1px);
    font-weight: 500;
    z-index: 0;
    font-size: 14px;
  }

  [dir=ltr] .post-card--layout--list .post-card__category {
    left: 0;
  }

  [dir=rtl] .post-card--layout--list .post-card__category {
    right: 0;
  }

  .post-card--layout--list .post-card__category a {
    color: #cb1818;
    display: block;
  }

  .post-card--layout--list .post-card__category a:hover {
    text-decoration: underline;
  }

  .post-card--layout--list .post-card__category:before,
  .post-card--layout--list .post-card__category:after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;
  }

  .post-card--layout--list .post-card__category:before {
    width: 100%;
  }

  [dir=ltr] .post-card--layout--list .post-card__category:before {
    left: 0;
    border-top-right-radius: 2px;
    -webkit-transform: skewX(30deg);
    transform: skewX(30deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  [dir=rtl] .post-card--layout--list .post-card__category:before {
    right: 0;
    border-top-left-radius: 2px;
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
    -webkit-transform-origin: right top;
    transform-origin: right top;
  }

  .post-card--layout--list .post-card__category:after {
    width: 50px;
  }

  [dir=ltr] .post-card--layout--list .post-card__category:after {
    left: 0;
  }

  [dir=rtl] .post-card--layout--list .post-card__category:after {
    right: 0;
  }

  .post-card--layout--list .post-card__date {
    font-size: 14px;
    color: #999;
  }

  .post-card--layout--list .post-card__date a {
    color: inherit;
  }

  .post-card--layout--list .post-card__date a:hover {
    color: #cb1818;
  }

  .post-card--layout--list .post-card__date:before {
    display: block;
    content: '';
    height: 1px;
    background: currentColor;
    opacity: .6;
  }

  .post-card--layout--list .post-card__excerpt {
    overflow: hidden;
  }
}

@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--list .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }

  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .post-card--layout--list .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }

  .post-card--layout--list .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .post-card--layout--list .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  [dir=ltr] .post-card--layout--list .post-card__date:before {
    margin-right: 4px;
  }

  [dir=rtl] .post-card--layout--list .post-card__date:before {
    margin-left: 4px;
  }

  .post-card--layout--list .post-card__more {
    margin-top: 18px;
  }
}

@media (max-width: 474px) {
  .post-card--layout--list .post-card__category {
    font-size: 13px;
  }

  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--list .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .post-card--layout--list .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }

  .post-card--layout--list .post-card__excerpt .typography {
    line-height: 24px;
  }

  .post-card--layout--list .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }

  .post-card--layout--list .post-card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }

  .post-card--layout--list .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-top: 12px;
  }

  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }

  .post-card--layout--list .post-card__more {
    display: none;
  }
}

.post-card--layout--grid-sm {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.post-card--layout--grid-sm .post-card__image img {
  max-width: 100%;
  height: auto;
}

.post-card--layout--grid-sm .post-card__content {
  position: relative;
}

.post-card--layout--grid-sm .post-card__title h2 {
  margin: 0;
}

.post-card--layout--grid-sm .post-card__title a {
  color: inherit;
}

.post-card--layout--grid-sm .post-card__title a:hover {
  text-decoration: underline;
}

.post-card--layout--grid-sm .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}

[dir=ltr] .post-card--layout--grid-sm .post-card__category {
  left: 0;
}

[dir=rtl] .post-card--layout--grid-sm .post-card__category {
  right: 0;
}

.post-card--layout--grid-sm .post-card__category a {
  color: #cb1818;
  display: block;
}

.post-card--layout--grid-sm .post-card__category a:hover {
  text-decoration: underline;
}

.post-card--layout--grid-sm .post-card__category:before,
.post-card--layout--grid-sm .post-card__category:after {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}

.post-card--layout--grid-sm .post-card__category:before {
  width: 100%;
}

[dir=ltr] .post-card--layout--grid-sm .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

[dir=rtl] .post-card--layout--grid-sm .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.post-card--layout--grid-sm .post-card__category:after {
  width: 50px;
}

[dir=ltr] .post-card--layout--grid-sm .post-card__category:after {
  left: 0;
}

[dir=rtl] .post-card--layout--grid-sm .post-card__category:after {
  right: 0;
}

.post-card--layout--grid-sm .post-card__date {
  font-size: 14px;
  color: #999;
}

.post-card--layout--grid-sm .post-card__date a {
  color: inherit;
}

.post-card--layout--grid-sm .post-card__date a:hover {
  color: #cb1818;
}

.post-card--layout--grid-sm .post-card__date:before {
  display: block;
  content: '';
  height: 1px;
  background: currentColor;
  opacity: .6;
}

.post-card--layout--grid-sm .post-card__excerpt {
  overflow: hidden;
}

@media (min-width: 1400px) {
  .post-card--layout--grid-sm .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }

  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .post-card--layout--grid-sm .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }

  .post-card--layout--grid-sm .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .post-card--layout--grid-sm .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  [dir=ltr] .post-card--layout--grid-sm .post-card__date:before {
    margin-right: 4px;
  }

  [dir=rtl] .post-card--layout--grid-sm .post-card__date:before {
    margin-left: 4px;
  }

  .post-card--layout--grid-sm .post-card__more {
    margin-top: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1399.98px) {
  .post-card--layout--grid-sm .post-card__category {
    font-size: 13px;
  }

  .post-card--layout--grid-sm .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .post-card--layout--grid-sm .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }

  .post-card--layout--grid-sm .post-card__excerpt .typography {
    line-height: 24px;
  }

  .post-card--layout--grid-sm .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }

  .post-card--layout--grid-sm .post-card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }

  .post-card--layout--grid-sm .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-top: 12px;
  }

  .post-card--layout--grid-sm .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }

  .post-card--layout--grid-sm .post-card__more {
    display: none;
  }
}

@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--grid-sm .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }

  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .post-card--layout--grid-sm .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }

  .post-card--layout--grid-sm .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .post-card--layout--grid-sm .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }

  [dir=ltr] .post-card--layout--grid-sm .post-card__date:before {
    margin-right: 4px;
  }

  [dir=rtl] .post-card--layout--grid-sm .post-card__date:before {
    margin-left: 4px;
  }

  .post-card--layout--grid-sm .post-card__more {
    margin-top: 18px;
  }
}

@media (max-width: 474px) {
  .post-card--layout--grid-sm .post-card__category {
    font-size: 13px;
  }

  .post-card--layout--grid-sm .post-card__title {
    margin-bottom: 10px;
  }

  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .post-card--layout--grid-sm .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }

  .post-card--layout--grid-sm .post-card__excerpt .typography {
    line-height: 24px;
  }

  .post-card--layout--grid-sm .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }

  .post-card--layout--grid-sm .post-card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }

  .post-card--layout--grid-sm .post-card__date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-top: 12px;
  }

  .post-card--layout--grid-sm .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }

  .post-card--layout--grid-sm .post-card__more {
    display: none;
  }
}

/*
  // .post-header
  */
.post-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}

.post-header__decor {
  position: absolute;
  bottom: -1px;
}

.post-header__decor .decor__start,
.post-header__decor .decor__end,
.post-header__decor .decor__center {
  background: #fafafa;
}

.post-header__decor .decor__start,
.post-header__decor .decor__end {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.post-header__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: .22;
}

.post-header__body {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 52px 30px;
}

.post-header__title {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
}

.post-header__categories {
  margin-bottom: 28px;
}

.post-header__categories-list {
  list-style: none;
  margin: -3px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.post-header__categories-item {
  margin: 3px;
}

.post-header__categories-link {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  background: #e52727;
  padding: 3px 9px;
  border-radius: 1.5px;
  font-weight: 500;
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 1dppx) {
  .post-header__categories-link {
    font-weight: 400;
  }
}

.post-header__categories-link,
.post-header__categories-link:hover {
  color: #fff;
}

.post-header__meta {
  font-size: 14px;
  margin-top: 24px;
  color: #999;
}

.post-header__meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.post-header__meta-item+.post-header__meta-item {
  position: relative;
}

[dir=ltr] .post-header__meta-item+.post-header__meta-item {
  margin-left: 24px;
}

[dir=rtl] .post-header__meta-item+.post-header__meta-item {
  margin-right: 24px;
}

.post-header__meta-item+.post-header__meta-item:before {
  display: block;
  position: absolute;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
  top: 9px;
}

[dir=ltr] .post-header__meta-item+.post-header__meta-item:before {
  left: -14px;
}

[dir=rtl] .post-header__meta-item+.post-header__meta-item:before {
  right: -14px;
}

.post-header__meta-link {
  color: inherit;
  -webkit-transition: color .1s;
  transition: color .1s;
}

.post-header__meta-link:hover {
  color: #cb1818;
}

.post-header--has-image {
  background: #262626;
  margin-bottom: -150px;
  padding-bottom: 150px;
}

.post-header--has-image .post-header__body {
  color: #fff;
  padding: 60px 30px;
}

.post-header--has-image .post-header__title,
.post-header--has-image .post-header__meta {
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.post-header--has-image .post-header__meta {
  color: inherit;
}

.post-header--has-image .post-header__meta-link:hover {
  color: #ffdf40;
}

@media (max-width: 1199.98px) {
  .post-header {
    padding-top: 0;
  }
}

@media (max-width: 575.98px) {
  .post-header__body {
    padding: 36px 30px;
  }

  .post-header__categories {
    margin-bottom: 20px;
  }

  .post-header__title {
    font-size: 28px;
  }

  .post-header__meta {
    margin-top: 16px;
  }

  .post-header--has-image .post-header__body {
    padding: 48px 30px;
  }
}

@media (max-width: 419px) {
  .post-header--has-image {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

/*
  // .post-navigation
  */
.post-navigation__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.post-navigation__item {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  color: inherit;
  -webkit-transition: background .12s;
  transition: background .12s;
}

.post-navigation__item:hover {
  background: #f2f2f2;
  color: inherit;
}

.post-navigation__item-image {
  overflow: hidden;
  border-radius: 2px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.post-navigation__item-title {
  font-size: 15px;
  line-height: 22px;
  margin-top: 3px;
}

.post-navigation__direction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 21px;
  color: #999;
}

.post-navigation__direction-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  fill: currentColor;
  padding-bottom: 1px;
  opacity: .8;
}

.post-navigation__direction-arrow svg {
  display: block;
}

[dir=ltr] .post-navigation__direction-arrow svg {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

[dir=rtl] .post-navigation__direction-arrow svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

[dir=ltr] .post-navigation__item--prev {
  text-align: left;
}

[dir=rtl] .post-navigation__item--prev {
  text-align: right;
}

[dir=ltr] .post-navigation__item--prev .post-navigation__item-image {
  margin-right: 18px;
}

[dir=rtl] .post-navigation__item--prev .post-navigation__item-image {
  margin-left: 18px;
}

.post-navigation__item--prev .post-navigation__direction {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

[dir=ltr] .post-navigation__item--prev .post-navigation__direction-arrow {
  margin-right: 8px;
}

[dir=rtl] .post-navigation__item--prev .post-navigation__direction-arrow {
  margin-left: 8px;
}

[dir=ltr] .post-navigation__item--next {
  text-align: right;
}

[dir=rtl] .post-navigation__item--next {
  text-align: left;
}

[dir=ltr] .post-navigation__item--next .post-navigation__item-image {
  margin-left: 18px;
}

[dir=rtl] .post-navigation__item--next .post-navigation__item-image {
  margin-right: 18px;
}

.post-navigation__item--next .post-navigation__direction {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

[dir=ltr] .post-navigation__item--next .post-navigation__direction-arrow {
  margin-left: 8px;
}

[dir=rtl] .post-navigation__item--next .post-navigation__direction-arrow {
  margin-right: 8px;
}

@media (max-width: 767.98px) {
  .post-navigation__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post-navigation__item {
    width: 100%;
  }

  .post-navigation__item--next {
    border-top: 1px solid #ebebeb;
  }
}

/*
  // .post-view
  */
.post-view__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-evenly;
}

.post-view__item-post {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 900px;
}

.post-view__item-sidebar {
  width: 332px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

[dir=ltr] .post-view__item+.post-view__item {
  margin-left: 48px;
}

[dir=rtl] .post-view__item+.post-view__item {
  margin-right: 48px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-view__item-sidebar {
    width: 290px;
  }

  [dir=ltr] .post-view__item+.post-view__item {
    margin-left: 36px;
  }

  [dir=rtl] .post-view__item+.post-view__item {
    margin-right: 36px;
  }
}

@media (max-width: 991.98px) {
  .post-view__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post-view__item-sidebar {
    width: 100%;
    margin-top: 20px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  [dir=ltr] .post-view__item+.post-view__item {
    margin-left: 0;
  }

  [dir=rtl] .post-view__item+.post-view__item {
    margin-right: 0;
  }
}

.post-view__card {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  position: relative;
}

.post-view__card+.post-view__card {
  margin-top: 24px;
}

.post-view__card-title {
  padding: 44px 0 8px;
  margin: 0 48px 40px;
  font-size: 28px;
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
}

.post-view__card-body {
  padding: 0 48px 48px;
}

@media (max-width: 767.98px) {
  .post-view__card-title {
    padding: 28px 0 8px;
    margin: 0 32px 28px;
  }

  .post-view__card-body {
    padding: 0 32px 32px;
  }
}

@media (max-width: 575.98px) {
  .post-view__card-title {
    padding: 22px 0 4px;
    margin: 0 24px 24px;
    font-size: 24px;
  }

  .post-view__card-body {
    padding: 0 24px 24px;
  }
}

@media (max-width: 419px) {
  .post-view__card {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/*
  // .posts-list
  */
.posts-list__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.posts-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.posts-list--layout--classic .posts-list__body {
  margin: -20px;
}

.posts-list--layout--classic .posts-list__item {
  margin: 20px;
  width: calc(100% - 40px);
}

.posts-list--layout--grid-2 .posts-list__body {
  margin: -14px;
}

.posts-list--layout--grid-2 .posts-list__item {
  margin: 14px;
  width: calc(50% - 28px);
}

@media (max-width: 1399.98px) {
  .posts-list--layout--grid-2 .posts-list__body {
    margin: -10px;
  }

  .posts-list--layout--grid-2 .posts-list__item {
    margin: 10px;
    width: calc(50% - 20px);
  }
}

@media (max-width: 767.98px) {
  .posts-list--layout--grid-2 .posts-list__body {
    margin: -16px;
  }

  .posts-list--layout--grid-2 .posts-list__item {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

.posts-list--layout--list .posts-list__body {
  margin: -14px;
}

.posts-list--layout--list .posts-list__item {
  margin: 14px;
  width: calc(100% - 28px);
}

/*
  // .posts-view
  */
.posts-view__pagination {
  margin-top: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* ----------------------------------------
  // Account
  // ---------------------------------------- */
/*
  // .account-nav
  */
.account-nav {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.account-nav__title {
  padding: 1.375rem 1.5rem;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.account-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 20px;
}

.account-nav__item a {
  cursor: pointer;
  display: block;
  color: #6c757d;
  padding: 7px 1.5rem;
}

.account-nav__item:hover a {
  background: #f2f2f2;
}

.account-nav__item--active a {
  color: inherit;
  font-weight: 500;
}

.account-nav__divider {
  height: 1px;
  background: #ebebeb;
  margin: 10px 0;
}

@media (min-width: 992px) {
  .account-nav__list {
    padding-bottom: 1.375rem;
  }

  [dir=ltr] .account-nav__item--active a {
    -webkit-box-shadow: 3px 0 #e52727 inset;
    box-shadow: 3px 0 #e52727 inset;
  }

  [dir=rtl] .account-nav__item--active a {
    -webkit-box-shadow: -3px 0 #e52727 inset;
    box-shadow: -3px 0 #e52727 inset;
  }
}

@media (max-width: 991.98px) {
  .account-nav {
    overflow-x: auto;
    max-width: 100%;
  }

  .account-nav__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    padding: 0 8px;
  }

  .account-nav__title {
    display: none;
  }

  .account-nav__item a {
    padding: 14px 20px;
  }

  .account-nav__item--active a {
    -webkit-box-shadow: 0 -3px #e52727 inset;
    box-shadow: 0 -3px #e52727 inset;
  }
}

/*
  // .address-card
  */
.address-card {
  position: relative;
}

.address-card__badge {
  position: absolute;
  top: 12px;
}

[dir=ltr] .address-card__badge {
  right: 12px;
}

[dir=rtl] .address-card__badge {
  left: 12px;
}

.address-card__body {
  padding: 1.5rem;
  font-size: 15px;
  line-height: 18px;
}

.address-card__name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1.125rem;
}

.address-card__row+.address-card__row {
  margin-top: .75rem;
}

.address-card__row-title {
  font-size: 13px;
  color: #6c757d;
}

.address-card__footer {
  margin-top: 1.625rem;
}

.address-card__footer a:hover {
  text-decoration: underline;
}

.address-card--featured .address-card__body {
  padding: 2rem;
}

.address-card--featured .address-card__name {
  font-weight: 700;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .address-card--featured .address-card__body {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .address-card--featured .address-card__body {
    padding: 1.375rem;
  }
}

/*
  // .addresses-list
  */
.addresses-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.addresses-list__item {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.addresses-list__item--new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.addresses-list__divider:last-child {
  display: none;
}

.addresses-list__plus {
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 30px;
  margin-bottom: 24px;
  color: #e0e0e0;
  border: 2px solid currentColor;
  -webkit-transition: color .2s;
  transition: color .2s;
}

.addresses-list__plus:before,
.addresses-list__plus:after {
  content: '';
  position: absolute;
  width: 32px;
  height: 4px;
  background: currentColor;
  border-radius: 1.5px;
  top: calc(50% - 2px);
}

[dir=ltr] .addresses-list__plus:before,
[dir=ltr] .addresses-list__plus:after {
  left: calc(50% - 16px);
}

[dir=rtl] .addresses-list__plus:before,
[dir=rtl] .addresses-list__plus:after {
  right: calc(50% - 16px);
}

.addresses-list__plus:after {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.addresses-list__item--new:hover .addresses-list__plus {
  color: #ccc;
}

@media (min-width: 768px) {
  .addresses-list__item {
    width: calc((100% - 32px - 1px) / 3);
  }

  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }

  .addresses-list__divider:nth-child(6n) {
    width: 100%;
    height: 16px;
  }
}

@media (min-width: 480px) and (max-width: 767.98px) {
  .addresses-list__item {
    width: calc((100% - 16px - 1px) / 2);
  }

  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }

  .addresses-list__divider:nth-child(4n) {
    width: 100%;
    height: 16px;
  }
}

@media (max-width: 479px) {
  .addresses-list__item {
    width: calc((100% - 0px - 1px) / 1);
  }

  .addresses-list__divider:nth-child(2n) {
    width: 16px;
  }

  .addresses-list__divider:nth-child(2n) {
    width: 100%;
    height: 16px;
  }
}

/*
  // .dashboard
  */
.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dashboard__orders {
  margin-top: 24px;
  width: 100%;
}

@media (min-width: 768px) {

  .dashboard__profile,
  .dashboard__address {
    width: calc(50% - 12px);
  }
}

@media (max-width: 767.98px) {

  .dashboard__profile,
  .dashboard__address {
    width: 100%;
  }

  .dashboard__address {
    margin-top: 24px;
  }
}

/*
  // .profile-card
  */
.profile-card__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.profile-card__avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.profile-card__avatar img {
  border-radius: 50%;
  max-width: 100%;
}

.profile-card__name {
  font-weight: 500;
  line-height: 20px;
}

.profile-card__email {
  font-size: 15px;
  margin-bottom: 24px;
}

#product-nav-div {
  display: flex;
}

.pagination-vl {
  height: 20px;
  border-left: solid 1px #dadada;
  margin: 0 25px;
  margin-top: 5px;
}

.product-go-to {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  align-items: center;
  margin: auto 0;
  letter-spacing: normal;
  color: #645a5b;
  margin-left: 10px;
}

.product-lower-input {
  width: 59px;
  height: 30px;
  border: solid 1px #dadada;
  background-color: var(--white);
  margin-left: 10px;
  padding-left: 5px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #606060;
}

.go-btn {
  width: fit-content;
  height: 30px;
  border: none;
  color: white;
  background-color: #ed2c2b;
  margin-left: 7px;
}

.wrapper-air-freshener-desc {
  font-size: 16px;
  text-align: start;
  
  p {
  padding: 0 !important;
  }

  h4 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  h2 {
    font-size: 16px;
  }
}

.read-more {
  color: #0069a7;
  cursor: pointer;
}