.contact-pop{
    display: flex;
    background-color: #f7f8fa ;
    justify-content: center;
}
.pop-up-main{
     width: 100%;
    height: 310px;
    border-radius: 4px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.02);
    border: solid 1px rgba(227, 227, 227, 0.57);
    background-color: #fff; 
    @media only screen and (max-width:480px) {
        width: 100%;
    }
}
.close-img{
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
}
.contact-flex{
    display: flex;
    flex-direction: column;
    margin-top: 2.5em;
     align-items: center;
}
.email--box{
    display: flex;
    width: 80%;
    height: 78px;
    margin-top: 1em;
    // justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 7px 0 rgba(146, 165, 165, 0.07);
    border: solid 1px #9fc2bb;
    background-color: #fff;
    @media only screen and (max-width:480px) {
        width: 90%;
    }
}
.emai--text{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    color: #000;
    margin-bottom: 6px;
}
.email--img{
    margin-left: 1.3em;
}
.support{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.02px;
    color: #0069a7;
    &:hover {
        opacity: 0.7;
    }
}
.email--div{
    margin-left: 1.5em;
}

.contact-us{
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 1px;
    text-align: center;
    color: #3d3839; 
    margin-bottom: 5px;
}
