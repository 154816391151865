@import "../assets/css/variable.scss";
// @import "../assets/vendor/bootstrap/css/bootstrap.css";
@import "../assets/css/style.css";
@import "../assets/css/style.header-spaceship-variant-one.css";
@import "../assets/css/style.mobile-header-variant-one.css";
@import "../assets/css/Dashboard.scss";

// @import "../assets/vendor/owl-carousel/assets/owl.carousel.min.css";
// @import "../assets/vendor/photoswipe/photoswipe.css";
// @import "../assets/vendor/photoswipe/default-skin/default-skin.css";

// @import "../assets/vendor/select2/css/select2.min.css";
// @import "../assets/vendor/fontawesome/css/all.min.css";

@font-face {
  font-family: Futura-PT;
  src: url('../assets/fonts/Futura\ Heavy\ font.ttf');
  font-display: swap;
}

.success {
  color: green;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.spinner-div{
  display: flex;
  justify-content: center;
  margin-bottom: 19px;
}
.loader-container {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000008a;
  height: 100vh;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 10000;
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(82, 80, 80);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0069a7 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.datepicker-container {
  position: relative;
  cursor: pointer;
  input {
    cursor: pointer;
  }
  img {
    position: absolute;
    right: 18px;
    top: 14px;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../assets/images/list-down-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 93%;
  background-position-y: 18px;
}

.suspenseLoading {
  position: fixed;
  left: 0;
  top: 0;
  height: 5px;
  width: 100%;
  background: #ed2c2b;
  animation: lineH 4s 5s infinite linear;
}


.commom-heading-container{
  background-image: url("../assets/images/page-title-bg.jpg");
  display: flex;
  align-items: center;
  height: 87px;
  justify-content: center;
  color: white;
  font-size: 24px;
  letter-spacing: 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  font-weight:600;
}

.common-breadcrumb{
  font-size: 12px;
  font-weight: 600;
  line-height: 2.17;
  color: #645a5b;
  margin-top: 15px;
  margin-bottom: 15px;
}

.error {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ed2c2b;
}

@media only screen and (max-width: 768px) {
  .commom-heading-container{
    margin: 0 15px;
    font-size: 18px;
    height: 55px;
  }
}

.carousel-hover-class{
  cursor: pointer;
  transition: width 1s, height 1s, background-color 1s, transform 0.25s;
   &:hover{
     transform: scale(1.2);
   }
}

.brand-heading{
  span{
    color: #EC2B2B;
  }
}

.blog-card {
  &:hover{
    box-shadow: 0 0 20px 0 #d9e3ee;
  }
}
.cursor-p{
  cursor: pointer;  
}

button{
  &:focus{
    outline: none!important;
  }
}

// .specsTable{

//   table{
//       thead{
//           th{
//               display:none
//           }
//       }
//       tbody{
//           tr:nth-of-type(odd){
//               background: #fbfbfb;
//           }
//       }
//   }

//   p{
//       margin-bottom: 0px;
//   }

//   .css-11mde6h-MuiPaper-root{
//       box-shadow: unset !important;
//   }

//   .css-1ex1afd-MuiTableCell-root{
//       font-family: 'Montserrat', sans-serif;
//       font-size: 14px;
//       font-weight: 500;
//   }

// }

// .competitor-table{
//   table{
//       thead{
//           th{
//               button{
//                   padding-left:0px;
//                   text-transform: capitalize;
//                   color: #645a5b;
//                   font-weight: 700;
//                   font-family: 'Montserrat', sans-serif;
//               }
//               border: 1px solid rgba(224, 224, 224, 1)
//           }
//       }
//       tbody{
//           tr{
//               &:nth-of-type(odd){
//                   background-color: rgba(0,0,0,.05);
//               }
//               td{
//                   border: 1px solid rgba(224, 224, 224, 1)
//               }
//           }
//       }
//       p{
//           margin-bottom: 0px;
//       }
//   }
//   .css-11mde6h-MuiPaper-root{
//       box-shadow: unset;
//   }
//   .css-1ex1afd-MuiTableCell-root{
//       font-family: 'Montserrat', sans-serif;
//       font-size: 14px;
//       font-weight: 500;
//   }
// }

// .application-table{
//   table{
//       thead{
//           th{
//               button{
//                   padding-left:0px;
//                   text-transform: capitalize;
//                   color: #645a5b;
//                   font-weight: 700;
//                   font-family: 'Montserrat', sans-serif;
//               }
//               border: 1px solid rgba(224, 224, 224, 1);
//               width: 25%;
//           }
//       }
//       tbody{
//           tr{
//               &:nth-of-type(odd){
//                   background-color: rgba(0,0,0,.05);
//               }
//               td{
//                   border: 1px solid rgba(224, 224, 224, 1)
//               }
//           }
//       }
//       p{
//           margin-bottom: 0px;
//       }
//   }
//   .css-11mde6h-MuiPaper-root{
//       box-shadow: unset;
//   }
//   .css-1ex1afd-MuiTableCell-root{
//       font-family: 'Montserrat', sans-serif;
//       font-size: 14px;
//       font-weight: 500;
//   }
// }

.quantity__input__container{
  position: relative;
width: 89px !important;
}
.products__minus{
  cursor: pointer;
  font-size: 11px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 5px;
}
.quantity__input{
  text-align: center;
  width: 100%;
  background: transparent;
  height: 30px;
  border: 1px solid #645a5b;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}
.products__plus{
  cursor: pointer;
  font-size: 11px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}
.dropdown-report{
  width: 100%;
}
.dropdowntoogle-report{
  display: flex;
  justify-content:space-between ;
  width: 100%;
  background: transparent;
  color: #3d3839;
  font-size: 14px;
  opacity: 1;
  border: 1px solid #645a5b
}
.empty-main-div{
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shorlist-item{
  font-size: 14px;
  line-height: 1.79;
  letter-spacing: 0.2px;
  color: #645a5b;
  margin: 4px 0 24px 0;
}
.empty-img{
  margin-bottom: 21px;
}
.new-products{
  span{
    color: #EC2B2B;
  }
}
.main-msg{
  font-size: 18px;
  font-weight: 600;
  line-height: 1.39;
  letter-spacing: 0.86px;
  text-align: center;
  color: #3d3839;
}
.empty-btn{
  padding: 14px 20px 13px 21px;
  background-color: #ed2c2b;
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: none;
}

h3.heading-title{
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0px;
  color: #636363;
  margin: 0px;
  .red{
    color: #EC2B2B;
  }
  &.application-search{
    margin-top: 65px;
    margin-bottom: 65px;
  }
  &.product-types,&.new-products{
    margin-top: 65px;
    margin-bottom: 45px;
  }
  &.resources-grow{
    margin-top: 60px;
  }
  @media only screen and (max-width:768px) {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    &.application-search{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &.product-types,&.new-products{
      margin-top: 20px;
      margin-bottom: 0px;
    }
    &.resources-grow{
      margin-top: 25px;
    }
  }
}

.mg-top-80{
  margin-top: 80px;
}
.mg-top-40{
  margin-top: 40px !important;
}
.mg-bottom-50{
  margin-bottom: 50px;
}
.p-0-mobile{
  padding: 0px;
}

.accordion-button{
  background-color: white;
  font-size: 14px;
 font-weight: bold;
 line-height: 2.85;
 color: #645a5b;
 border: none;
 width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-button-icon{
  font-size: 20px;
}
.accordion-body{
  padding: 0 15px;
}

.active-accordion-item{
  color: #0069a7!important;
}

input{
  &:focus{
    outline: none;
  }
}


// .css-1x51dt5-MuiInputBase-input-MuiInput-input{
//   border-bottom: 1px solid #636363!important;
//   &:focus{
//     outline: none!important;
//   }
// }


// .css-1a1fmpi-MuiInputBase-root-MuiInput-root{
  // width: 350px!important;
//   &:after{
//     border: none!important;
//   }
//   &::before{
//     border: none!important;
//     content: 'Search';
//   }
// }


// .css-1t29gy6-MuiToolbar-root{
//   padding: 0px!important;
// }

@media only screen and (max-width: 991.98px) {
  .competitor-table,.product-mob-table{
     p{
      padding: 20px;
    }
  }
  // .css-1x51dt5-MuiInputBase-input-MuiInput-input{
  //   &::placeholder{
  //     color: transparent!important;
  //   }
  // }
}

.css-ao8rxk{
  box-shadow: none!important;
}

.about-main-img{
  width: 100%;
  height: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.About-why-qls{
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 3px;
  text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
  color: var(--white);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

//Product Description Page

.main-image-container{
  height: 400px;
  width: 400px!important;
  margin: auto;
  @media only screen and (max-width:768px) {
    height: 100%;
  width: 100%!important;
  }
}
// .tss-1cdcmys-MUIDataTable-responsiveBase {
//   overflow-y: scroll;
//   height: 500px !important;
// }
// .tss-djbknv-MUIDataTablePagination-navContainer {
//   display: none !important;
// }
// .css-nfmglb-MuiTableCell-root {
//   display: none !important;
// }

.competitor-table-container{
  table{
    border-collapse: initial;
  }
}
.application-table-container{
  table{
    border-collapse: initial;
  }
  tbody{
    height: 500px!important;
    overflow-y: scroll;
  }
}