.main-footer{
    width:100%;
    height: 120px;
    background-color: #fff;
    align-items: center;
    margin-top: 10px;
    @media only screen and (max-width:480px) {
       height: 100px;
    }
}
.privacy-security{
    display: flex;
    justify-content:center;
    // margin-top: 1.5em;
    // align-items: center;
}
.privacy-div{
    width: 50%;
    text-align: center;
    margin: 1.5em 0px;
    @media only screen and (max-width:480px) {
        font-size: 13px;
        width: 100%;
    }
}
.privacy-span{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0069a7;
    margin:20px;
    @media only screen and (max-width:480px) {
        margin: 7px;
    }

}
.sonuu{
    display: flex;
    height: 100px;
    background-color: chartreuse;
    justify-content: center;
    align-items: center;
}
.copyright{
    display: flex;
    justify-content: center;    
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #645a5b;
}