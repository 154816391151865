.footer-top{
  background-color: #544a4b;
  // height: 117px;
  display: flex;
  align-items: center;
  // border-bottom: 2px solid #645a5b;

  @media only screen and (max-width:767px){
    height: auto;
    padding: 20px 0 22px;
    border: 0;
  }
}
.footer-subscribe-desktop{
  width: 100%;
  @media only screen and (max-width: 767px){
    display: none;
  }
}
.footer-subscribe-device{
  width: 100%;
  @media only screen and (min-width: 768px){
    display: none;
  }
  @media only screen and (max-width: 767px){
    .col-md-6{
      padding: 0;
      .subscribe-newsletter{
        font-size: 15px;
      }
      .special-offer{
        margin: 5px 0 15px 0;
        display: block;
      }
    }
    
  }
}
@media only screen and (max-width:767px){
  .subscribe-footer-space{
    margin-right:-15px !important;
    .col-12{
      padding-right: 0;
    }
  }
}
// .allproducts:hover{
//   cursor: pointer;
//   color: #9e9e9e;
// }


.upper-footer {
  background-color: #544a4b;
  height: 100px;
}

.containerr-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 26px;
}
@media only screen and (max-width: 767px) {
  .containerr-main {
    display: none;
  }
}
.footer-input {
  width: 68%;
  height: 44px;
  border-radius: 1px;
  background-color: #78686a;
  border: none;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
  padding-left: 10px;
  &::placeholder{
    color: #c5c5c5;
    opacity: 2;
  }
  &:hover{
    background-color: #645a5b;
  }
}
.footer--btn {
  width: 28%;
  height: 44px;
  border-radius: 1px;
  background-color: #ed2c2b;
  border: none;
  padding: 2px 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: white;
  margin-left: 10px;
}
.footer--btn:hover{
  background-color: #78686a;
}
.subscribe-newsletter {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.15px;
  color: white;
}
.special-offer {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #dac3c6;
}
.main--li {
  /* justify-content: space-around; */
  --bs-gutter-x: 0rem;
}
@media only screen and (min-width: 980px) {
  .main--li {
    --bs-gutter-x: 0rem;
  }
}
.footer--line {
  border-top: firebrick;
  border-radius: 4px;
}
.free--shipping {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.75px;
  color: #fff;
  margin-bottom: 5px;
}
.for--orders {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.main--footer {
  background-color: #544a4b;
  .footer-contactss,
  .footer-resource,
  .footer-account{
    position: relative;
    &:after{
      border-right: 1px solid #675d5f;
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      @media only screen and (max-width:767px){
        display: none;
      }
    }
    @media only screen and (max-width:767px){
      border-bottom: 2px solid #645a5b;
      padding-bottom: 10px;
    }
  }
  .footer-contactss{
    @media only screen and (max-width:767px){
      border-top: 2px solid #645a5b;
    }
    &:after{
      right: 8px;
      @media only screen and (max-width:990px){
        display: none;
      }
    }
  }
  .footer-resource{
    &:after{
      right: 0;
      @media only screen and (max-width:1025px){
        right: -3px;
      }
    }
  }
  .footer-account{
    @media only screen and (max-width:990px){
      padding-left: 12%;
    }
    @media only screen and (max-width:767px){
      padding-left: 0%;
    }
    &:after{
      right: 10px;
      @media only screen and (max-width:1025px){
        right: 3px;
      }
      @media only screen and (max-width:990px){
        right: 50px;
      }
    }
  }
  @media only screen and (max-width:767px){
    .main--li{
      margin-right: -15px !important;
    }
    .footer-social-device{
      display: none;
    }
  }
}
.footer-newsletter{
  @media only screen and (max-width:767px){
    text-align: left;
  }
}
.footer--address {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #dac3c6;
  margin-top: 20px;
}
.social-media-row {
  margin-top: 15px;
}
.twitter-img {
  margin: 0px 15px;
}
.instagram-img {
  margin-right: 15px;
}
.footer--address span {
  font-weight: 500;
}
/* // .order-history{
//     font-size: 13px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 2.50;
//     letter-spacing: normal;
//     color: #fff;
// } */
.all___products {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: var(--white);
}
.all__products {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: var(--white);
}
.product--categories-heading {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #d0cece;
  margin-top: 25px;
}
.allproducts {
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.62;
  letter-spacing: normal;
  color: white;
  text-decoration: none;
}
.facebook_img {
  margin-right: 20px;
}
.newsleter-msg {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* //  line-height: 1.92; */
  letter-spacing: normal;
  color: #dac3c6;
}
.productt--lii {
  display: flex;
  justify-content: flex-start;
  margin-top: 23px;
  /* // float: left; */
}
.footer-links-list {
  display: block;
  padding: 0px;
  margin-right: 50px;
  list-style-type: none;
  margin-bottom: 0;
}
@media only screen and (max-width: 1440px) and (min-width: 980px) {
  .footer-links-list {
    /* margin-right: 35px;  */
    margin-right: 11px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-links-list {
    margin-right: 19px;
  }
}
@media only screen and (min-width:1440px) {
  .footer-links-list{margin-right: 0;}
  
}
@media only screen and (max-width: 980px) {
  .vl {
    display: none;
  }
}

#product__li {
  margin-left: 4em;
}
/* .footer-links-list  a {
  font-size: small;
  text-decoration: none;
  color:white;
} */

.download-ols-app {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #fff;
  margin-top: 30px;
}
.footer--input {
  border-radius: 1px;
  background-color: #78686a;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #c5c5c5;
}
.ios-android-btn {
  margin-top: 15px;
}
.androis-btn {
  margin-left: 8px;
}
.copy--right {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 20px 0;
}
.footer--bottom--row {
  display: flex;
  // justify-content: center;
  justify-content: space-between;
 
}
.lower-footer-social{
  display: flex;
  align-items: center;
}
.footer-bottom {
  background-color: #645a5b;
}
.upper-line {
  border: 1px solid #c4bbbc;
}
.footer-logo-row {
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
  margin-bottom: 30px;
  align-items: center;
  @media only screen and (max-width:767px){
    margin-top: 30px;
  }
}
@media only screen and (max-width: 880px) and (min-width: 480px) {
  // .footer-logo-row {
  //   flex-direction: column;
  //   align-items: center;
  // }
  .download-app-img {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-logo-row {
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .footer-main-logo {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: "780px") and (min-width: "480px") {
  .footer-logo-row {
    align-items: center;
  }
}
.footer__address {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: #dac3c6;
  margin-left: 40px;
  max-width: 200px;

  p {
    margin: 0;
  }
}
.break-img {
  display: none;
}
.footer-horizontal-line {
  display: none;
}
.footer-lower-input {
  display: none;
}
.upper-social-img {
  margin-top: 14px;
  @media only screen and (max-width: 767px){
    display: flex;
    justify-content: center;
  }
  img{
    margin: 0 10px;
  }
}
.footer-mobile-upper-view {
  display: none;
}
@media only screen and (max-width: 767px) {
  .footer__address {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
  }
  .upper-footer-img {
    margin: 0 auto;
  }
  .upper-footer {
    height: 190px;
  }
  .break-img {
    display: block;
  }
  .download-app-img {
    text-align: center;
    margin-top: 30px;
    .img1{
      margin-left: 0;
    }
  }
  .footer-lower-input {
    display: block;
    text-align: center;
  }
  #lower-input {
    margin-bottom: 30px;
    margin-top: 17px;
    text-align: end;
  }
  .footer-horizontal-line {
    display: block;
    border: 1px solid #c4bbbc;
    margin: 0 0px 0px 15px;
    width: 100%;
  }
  .product--categories-heading {
    margin-top: 17px;
    font-size: 15px;
    font-weight: 500;
  }
  .footer--btn {
    width: 30%;
  }
  .footer-mobile-upper-view {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .social--div {
    display: none;
  }
  .footer-input {
    width: 66%;
  }
  .special-offer {
    margin-left: 10px;
  }

  .lower-footer-social{
    display: none;
    justify-content: center;
    margin-bottom: 10px;
  }
  .footer--bottom--row{
    flex-direction: column;
  }
}
.footer_phone__number {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  color: var(--white);
  @media only screen and (max-width: 767px){
    font-size: 14px;
    line-height: 23px;
    // color: #dac3c6;
  }
}
.download--app {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #d0cece;
  margin-right: 10px;
  @media only screen and (max-width: 767px){
    margin: 0;
    display: block;
    font-size: 15px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 767px) {
  .ios--img-footer {
    margin-top: 19px;
  }
}
.ios--img-footer {
  margin-left: 10px;
}
.facebook__img {
  margin-right: 4px;
}
.vl {
  border-left: 1px solid #7a7172;
  opacity: 0.5;
  height: 200px;
  margin-top: -35px;
}
/* @media only screen and (max-width:780px) and (min-width:480px) {
    .footer-main-logo{justify-content: space-evenly;}
    
} */

.Plus-icon {
  font-weight: 700;
  font-size: 20px;
  float: right;
  margin-top: -2px;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
      display: none;
  }
}

@media only screen and (max-width: 767px) {
  .none {
    display: none;
  }
}
.oil-filter {
  margin-right: 25px;
  @media only screen and (min-width: 1200px) {
      margin-right: 40px;
  }
  @media only screen and (max-width:990px){
    margin-right: 23%;
  }
  @media only screen and (max-width:767px){
    margin-right: 11%;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 980px) {
  .resource-main-div {
    margin-left: -24px;
    margin-right: 13px;
  }
}
@media only screen and (min-width:1400px) {
  .footer--bottom--row{
    margin: 0 auto;
  }
  
}
// @media only screen and (max-width:520px) {
//   .lower-footer-social{
//     display: none;
//     justify-content: center;
//     margin-bottom: 10px;
//   }
//   .footer--bottom--row{
//     flex-direction: column;
//   }
// }
.footer-main-logo{
  cursor: pointer;
}
.hover-underline-animation {
  
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #dad5d5;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer--log{
  &:hover{
    color: #9e9e9e !important;
  }
}
.footer-social-icon{
  &:hover{
    opacity: 0.7;
  }
}
@media  only screen and (max-width: 767px) {
  .footer--desktop-media{
    display: none;
  }
}
@media  only screen and (min-width: 768px) {
  .footer--mobile-media{
    display: none;
  }
}

@media only screen and (max-width: 780px) and (min-width:760px){
  .footer-logo-row .none{
    width: 100%;
  }
  // .footer-main-logo{
  //   justify-content: space-between;
  // }
  .download-app-img{
    width: 100%;
    text-align: right;
  }
}