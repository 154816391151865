.account-menu {
  width: 280px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  color: #262626;
}
.account-menu__form {
  padding: 0 30px 32px;
}
.account-menu__form-title {
  text-align: center;
  padding: 32px 0 26px;
  font-weight: 500;
}
.account-menu__form-button {
  margin-top: 32px;
  text-align: center;
}
.account-menu__form-button .btn {
  padding-left: 28px;
  padding-right: 28px;
}
.account-menu__form-link {
  font-size: 14px;
  text-align: center;
}
.account-menu__form-link a {
  color: #6c757d;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
}
.account-menu__form-link a:hover {
  color: inherit;
}
.account-menu__form-forgot {
  position: relative;
}
.account-menu__form-forgot-link {
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-radius: 2px;
  font-size: 12px;
  background: fff;
  color: #6c757d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 7px;
  -webkit-transition: background 0.1s, color 0.1s;
  transition: background 0.1s, color 0.1s;
}
[dir="ltr"] .account-menu__form-forgot-link {
  right: 5px;
}
.account-menu__form-forgot-link:hover {
  background: #333;
  color: #fff;
}
.account-menu__form-forgot-link:active {
  background: #4d4d4d;
  color: #fff;
}
.account-menu__divider {
  height: 1px;
  background: #ebebeb;
}
.account-menu__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 20px;
  color: inherit;
}
.account-menu__user:hover {
  color: inherit;
  background: #f2f2f2;
}
.account-menu__user-avatar {
  width: 44px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
[dir="ltr"] .account-menu__user-avatar {
  margin-right: 14px;
}
.account-menu__user-avatar img {
  border-radius: 100%;
  max-width: 100%;
}
.account-menu__user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
}
.account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: #6c757d;
  margin-top: 1px;
}
.account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;
}
.account-menu__links a {
  display: block;
  color: inherit;
  font-size: 15px;
  padding: 5px 20px;
  font-weight: 500;
}
.account-menu__links a:hover {
  background: #f2f2f2;
}
.header {
  display: grid;
  grid-template-columns: calc(100% / 2 - 1350px / 2) 1fr auto 1fr calc(
      100% / 2 - 1350px / 2
    );
  grid-template-rows: 30px auto;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .header {
    grid-template-columns: calc(100% / 2 - 1110px / 2) 1fr auto 1fr calc(
        100% / 2 - 1110px / 2
      );
  }
}
.header__megamenu-area {
  grid-column: 2 / 5;
  grid-row: 1;
}
.header__topbar-start-bg {
  grid-column: 1 / 3;
  grid-row: 1;
}
.header__topbar-end-bg {
  grid-column: 4 / 6;
  grid-row: 1;
}
.header__topbar-start {
  grid-column: 2;
  grid-row: 1;
}
.header__topbar-end {
  grid-column: 4;
  grid-row: 1;
}
.header__navbar {
  grid-column: 2;
  grid-row: 2;
}
.header__logo {
  grid-column: 3;
  grid-row: 1 / 3;
}
.header__search {
  grid-column: 3 / 3;
  grid-row: 1 / 3;
}
.header__indicators {
  grid-column: 4;
  grid-row: 2;
}
.header {
  position: relative;
  z-index: 10;
}
@media (max-width: 1199.98px) {
  .header {
    display: none;
  }
}
.header__navbar {
  -ms-flex-item-align: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__topbar-start-bg,
.header__topbar-end-bg {
  height: 100%;
  overflow: hidden;
}
.header__topbar-start-bg:before,
.header__topbar-end-bg:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
}
.header__topbar-start,
.header__topbar-end {
  z-index: 1;
}
[dir="ltr"] .header__topbar-start {
  padding-right: 20px;
}
[dir="ltr"] .header__topbar-end {
  padding-left: 20px;
}
.header__navbar {
  padding: 10px 0;
}
.header__navbar-departments {
  z-index: 2;
}
.header__navbar-menu {
  z-index: 2;
}
.header__indicators {
  -ms-flex-item-align: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 0;
}
[dir="ltr"] .header__indicators .indicator + .indicator {
  margin-left: 4px;
}
[dir="ltr"] .header__topbar-start-bg:before {
  border-bottom-right-radius: 2.5px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
[dir="ltr"] .header__topbar-end-bg:before {
  border-bottom-left-radius: 2.5px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  -webkit-transform-origin: top left;
  transform-origin: top left;
}
.header__logo {
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.header__navbar {
  min-height: 80px;
}
.header__search {
  position: absolute;
  -ms-flex-item-align: end;
  align-self: flex-end;
  justify-self: center;
  bottom: -24px;
  width: 480px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .header__search {
    width: 440px;
  }
}
.header {
  color: #262626;
  background: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.header__topbar-start-bg:before {
  background: #e52727;
}
.header__topbar-end-bg:before {
  background: #333;
}
.header__logo {
  padding-bottom: 22px;
}
.indicator {
  position: relative;
}
.indicator__button {
  padding: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: 54px;
  min-width: 54px;
  color: inherit;
  border-radius: 2px;
  background: transparent;
  border: 0;
}
[dir="ltr"] .indicator__button {
  padding-right: 12px;
}
.indicator__button:focus {
  outline: 0;
}
.indicator__button:hover {
  color: inherit;
  text-decoration: none;
}
.indicator__icon {
  position: absolute;
  padding: 8px;
  display: block;
  width: 48px;
  height: 48px;
}
.indicator__icon svg {
  display: block;
}
[dir="ltr"] .indicator__icon svg {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.indicator__counter {
  position: absolute;
  top: 5px;
  font-size: 10px;
  line-height: 1;
  padding: 2px 3px 1px;
  border-radius: 6.5px;
  text-align: center;
  z-index: 0;
}
[dir="ltr"] .indicator__counter {
  right: 4px;
}
.indicator__counter:before {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
}
[dir="ltr"] .indicator__counter:before {
  left: 0;
  right: 0;
  -webkit-transform: skewX(-11deg);
  transform: skewX(-11deg);
}
.indicator__title,
.indicator__value {
  display: block;
}
[dir="ltr"] .indicator__title,
[dir="ltr"] .indicator__value {
  margin-left: 50px;
}
.indicator__title {
  padding-top: 3px;
  margin-bottom: -3px;
  font-size: 13px;
}
.indicator__value {
  font-size: 18px;
  font-weight: 500;
}
.indicator__content {
  top: 100%;
  position: absolute;
  visibility: hidden;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform: rotateX(60deg);
  transform: rotateX(60deg);
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, visibility 0 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, opacity 0.2s, visibility 0 0.2s;
  transition: transform 0.2s, opacity 0.2s, visibility 0 0.2s,
    -webkit-transform 0.2s;
}
[dir="ltr"] .indicator__content {
  right: 0;
}
.indicator--trigger--click.indicator--open .indicator__content,
.indicator--trigger--hover:hover .indicator__content {
  z-index: 1;
  -webkit-transition-delay: 0, 0, 0;
  transition-delay: 0, 0, 0;
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.indicator--mobile .indicator__button {
  padding: 0 0 2px;
  height: 50px;
  width: 44px;
  min-width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0;
}
.indicator--mobile .indicator__icon {
  padding: 0;
  position: relative;
  height: auto;
  width: auto;
}
.indicator--mobile .indicator__counter {
  top: -7px;
}
[dir="ltr"] .indicator--mobile .indicator__counter {
  right: -7px;
}
.indicator__icon {
  fill: #262626;
}
.indicator__title {
  color: #999;
}
.indicator__value {
  font-weight: 500;
}
.indicator:hover .indicator__button,
.indicator--open .indicator__button {
  background: #f2f2f2;
}
.indicator__counter {
  color: #fff;
  font-weight: 500;
}
@media (-webkit-max-device-pixel-ratio: 1), (max-resolution: 1dppx) {
  .indicator__counter {
    font-weight: 400;
  }
}
.indicator__counter:before {
  background-color: #e52727;
}
.logo__slogan {
  padding-top: 11px;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
}
.logo__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
}
.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.logo__image {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.logo__slogan {
  color: #999;
  font-weight: 500;
}
.logo__part-primary {
  fill: #e52727;
}
.logo__part-secondary {
  fill: #404040;
}
.menu {
  color: #262626;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 8px 0;
  width: 200px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}
.menu__submenu {
  position: absolute;
  top: -8px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
[dir="ltr"] .menu__submenu {
  left: 100%;
}
.menu__link {
  display: block;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}
[dir="ltr"] .menu__link {
  padding-left: 16px;
  padding-right: 28px;
}
.menu__link,
.menu__link:hover {
  color: inherit;
}
.menu__arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 5px);
}
[dir="ltr"] .menu__arrow {
  right: 14px;
}
.menu__arrow svg {
  display: block;
}
[dir="ltr"] .menu__arrow svg {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.menu__item:hover > .menu__link {
  background: #f2f2f2;
}
.menu__item:hover > .menu__submenu {
  opacity: 1;
  visibility: visible;
}
.menu__item--has-submenu {
  position: relative;
}
.search__dropdown {
  color: #262626;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  border-radius: 1.5px;
  opacity: 0;
  -webkit-transform: translateY(26px);
  transform: translateY(26px);
  visibility: hidden;
  -webkit-transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0 0.25s,
    -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0 0.25s,
    -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0 0.25s;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0 0.25s,
    -webkit-transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
[dir="ltr"] .search__dropdown {
  left: 0;
  right: 0;
}
.search__dropdown--open {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.search__button-icon svg {
  display: block;
}
.search__body {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  height: 44px;
}
.search__decor {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: -10px;
  height: 34px;
  width: calc(100% + 20px);
  z-index: -1;
}
[dir="ltr"] .search__decor {
  left: -10px;
}
.search__decor-start,
.search__decor-end {
  overflow: hidden;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 10px;
}
.search__decor-start:before {
  content: "";
  display: block;
  height: 24px;
}
[dir="ltr"] .search__decor-start:before {
  border-bottom-left-radius: 2px;
  margin-right: -20px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}
.search__decor-end:before {
  content: "";
  display: block;
  height: 24px;
}
[dir="ltr"] .search__decor-end:before {
  border-bottom-right-radius: 2px;
  margin-left: -20px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}
.search__box {
  position: absolute;
  top: calc((100% - 34px) / 2);
  height: calc(34px);
  -webkit-transition: color 0.2s, opacity 0.2s;
  transition: color 0.2s, opacity 0.2s;
  opacity: 0;
  color: #f0f0f0;
}
.search__box:before,
.search__box:after {
  position: absolute;
  content: "";
  display: block;
  background: currentColor;
  height: 100%;
  width: 80%;
  top: 0;
  border-radius: 3px;
}
[dir="ltr"] .search__box:before {
  left: 0;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}
[dir="ltr"] .search__box:after {
  right: 0;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}
.search__input {
  width: 0;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background: transparent;
  border: 0;
  padding: 0 12px;
  text-align: center;
  font-size: 15px;
  margin: 6px 0;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #262626;
}
.search__input::-webkit-input-placeholder {
  color: #999;
}
.search__input::-moz-placeholder {
  color: #999;
}
.search__input:-ms-input-placeholder {
  color: #999;
}
.search__input::-ms-input-placeholder {
  color: #999;
}
.search__input::placeholder {
  color: #999;
}
.search__input::-ms-clear {
  display: none;
}
.search__input::-webkit-input-placeholder {
  -webkit-transition: opacity 0.15s, color 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, color 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s,
    -webkit-transform 0.15s;
}
.search__input::-moz-placeholder {
  -moz-transition: opacity 0.15s, transform 0.15s, color 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s;
}
.search__input:-ms-input-placeholder {
  -ms-transition: opacity 0.15s, transform 0.15s, color 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s;
}
.search__input::-ms-input-placeholder {
  -ms-transition: opacity 0.15s, transform 0.15s, color 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s;
}
.search__input::placeholder {
  -webkit-transition: opacity 0.15s, color 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, color 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s;
  transition: opacity 0.15s, transform 0.15s, color 0.15s,
    -webkit-transform 0.15s;
}
.search__input:focus {
  outline: 0;
}
.search__input:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transform: translateY(-26px);
  transform: translateY(-26px);
}
.search__input:focus::-moz-placeholder {
  opacity: 0;
  transform: translateY(-26px);
}
.search__input:focus:-ms-input-placeholder {
  opacity: 0;
  transform: translateY(-26px);
}
.search__input:focus::-ms-input-placeholder {
  opacity: 0;
  transform: translateY(-26px);
}
.search__input:focus::placeholder {
  opacity: 0;
  -webkit-transform: translateY(-26px);
  transform: translateY(-26px);
}
.search__input:hover ~ .search__box {
  opacity: 1;
  color: #f0f0f0;
}
.search__input:focus ~ .search__box {
  opacity: 1;
  color: #f0f0f0;
}
.search__button {
  margin: 5px 7px;
  width: 72px;
  padding: 0;
  border: 0;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  fill: currentColor;
  z-index: 1;
  pointer-events: none;
  position: relative;
  color: rgba(0, 0, 0, 0.3);
}
.search__button:after {
  background: transparent;
}
.search__button:focus {
  outline: 0;
}
.search__button:before {
  position: absolute;
  display: block;
  content: "";
  width: 3px;
  top: 0;
  bottom: 0;
  background: #ebebeb;
  z-index: -1;
  border-radius: 1.5px;
}
[dir="ltr"] .search__button:before {
  left: -8px;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}
.search__button:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  z-index: -1;
  border-radius: 3px;
  -webkit-transition: background 0.12s;
  transition: background 0.12s;
}
[dir="ltr"] .search__button:after {
  left: 0;
  -webkit-transform: skewX(-30deg);
  transform: skewX(-30deg);
}
.search__button:hover:after,
.search__button--hover:after {
  background: #f0f0f0;
}
.search__button:active,
.search__button--hover:active {
  -webkit-transition-duration: 0;
  transition-duration: 0;
}
.search__button:active:after,
.search__button--hover:active:after {
  background: #e5e5e5;
}
.search__button-title {
  display: none;
}
.search__button--start {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
[dir="ltr"] .search__button--start:before {
  left: auto;
  right: -8px;
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}
[dir="ltr"] .search__button--start:after {
  -webkit-transform: skewX(30deg);
  transform: skewX(30deg);
}
.search__shadow {
  position: absolute;
  bottom: 100%;
  height: 10px;
  width: calc(100% - 20px);
  overflow: hidden;
}
[dir="ltr"] .search__shadow {
  left: 10px;
}
.search__shadow:before {
  display: block;
  content: "";
  width: 100%;
  height: 60px;
  position: relative;
  top: 100%;
  -webkit-box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.12), 0 -1px rgba(0, 0, 0, 0.02);
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.12), 0 -1px rgba(0, 0, 0, 0.02);
  border-radius: 120px / 10px;
}
.search__dropdown {
  top: calc(100% + 5px);
}
[dir="ltr"] .search__dropdown {
  left: 14px;
  right: 14px;
}
.search__decor-start:before {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.search__decor-end:before {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.search__box {
  left: 92px;
  right: 92px;
}
.search__car-selector-link a {
  font-size: 14px;
  color: #999;
}
.search__car-selector-link a:hover {
  text-decoration: underline;
}
@media (min-width: 1200px) {
  .about__body {
    grid-template-rows: 124px auto auto;
  }
}
@media (min-width: 1200px) {
  .block-header:not(.block-header--has-breadcrumb) .block-header__title {
    padding-top: 60px;
  }
}
@media (min-width: 1200px) {
  .post-header {
    padding-top: 24px;
  }
}
