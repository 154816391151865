.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    width: 100vw;
    height: 100vh;
  }

.new-arrival-card-skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  width: 27rem;
  height: 144px;
}
  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  